import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Breadcrumb,
  Button,
  TableBasic,
  Tag,
  Tooltip,
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import moneyIcon from "~/assets/Money.png";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import {
  getSimulateOperationsAPI,
  ISimulateOperation,
} from "~/services/api/operations";
import useOperation from "~/hooks/useOperation";
import { formatNumberToCurrencyWithoutSimbol } from "~/services/utils";
import useLoading from "~/hooks/useLoading";
import NewIcon from "~/assets/Addd.png";
import ViewIcon from "~/assets/View.png";

import { format, parseISO } from "date-fns";
import { PropsTag } from "prosperita-dumbo-react/dist/types/Components/Tag";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const ContainerCanViewOperations = styled.div`
  width: 100%;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const titlesColumn = [
  "Status",
  "Nome",
  "ID",
  "Data",
  "Tipo",
  "Valor solicitado",
  "",
];

const statusSimulationsLabel: { [key: string]: string } = {
  simulated: "Criada",
  confirmed: "Vinculada",
};

const statusSimulationsTagColor: { [key: string]: PropsTag["type"] } = {
  simulated: "gray",
  confirmed: "green",
};

const Simulations: FunctionComponent = () => {
  const navigate = useNavigate();
  const { setOperationsSimulated, operationsSimulated, setSimulateResult } =
    useOperation();

  const { setLoading } = useLoading();

  const [render, setRender] = useState<boolean>(false);

  const [currentDate, setCurrentDate] = useState(() =>
    new Date().toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  );

  const [currentTime, setCurrentTime] = useState(() =>
    new Date().toLocaleString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  );

  type IDataTableItem = {
    Status: JSX.Element;
    Nome: string;
    ID: string;
    Data: string;
    Tipo: string;
    "Valor Solicitado": string;
    "": React.ReactNode;
  };

  const canViewOperations: boolean = true;

  const dataTable: IDataTableItem[] = useMemo(() => {
    return operationsSimulated?.map(
      (simulatedOperation: ISimulateOperation) => {
        return {
          Status: (
            <div style={{ maxWidth: "120px" }}>
              <Tag
                type={statusSimulationsTagColor[simulatedOperation.status]}
                size="medium"
                position="center"
              >
                <div style={{ padding: "2px" }}>
                  {statusSimulationsLabel[simulatedOperation?.status]}
                </div>
              </Tag>
            </div>
          ),
          Nome: simulatedOperation.nickname,
          ID: simulatedOperation.uuid,
          Data: format(
            parseISO(simulatedOperation?.operation_date),
            "dd/MM/yyyy"
          ),
          Tipo: simulatedOperation.product.name,
          "Valor Solicitado": `R$ ${formatNumberToCurrencyWithoutSimbol(simulatedOperation?.requested_amount?.toFixed(2))}`,
          "": (
            <div
              style={{
                height: "20px",
                display: "flex",
              }}
            >
              <Tooltip align="top" size="large" label={"Visualizar detalhes"}>
                <Button
                  kind="ghost"
                  size="small"
                  icon={ViewIcon}
                  label=""
                  onClick={() =>
                    navigate(
                      `/app/credit/simulations/${simulatedOperation.product.product_type === "ccb" ? "ccb" : "commercial-note"}/details/${simulatedOperation.uuid}`
                    )
                  }
                />
              </Tooltip>
            </div>
          ),
        };
      }
    );
  }, [operationsSimulated, navigate]);

  const fetchOperations = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await getSimulateOperationsAPI();
      setOperationsSimulated(data.payload);
      setLoading(false);

      const currentDateTime = new Date();

      setCurrentDate(
        currentDateTime.toLocaleString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      );

      setCurrentTime(
        currentDateTime.toLocaleString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      );
    } catch (e) {
      console.error("Erro ao buscar simulações:", e);
      setLoading(false);
    } finally {
      setRender(true);
    }
  }, [setOperationsSimulated, setLoading]);

  useEffect(() => {
    localStorage.removeItem("@:involveds");
    localStorage.removeItem("@:simulate_operation");
    localStorage.removeItem("@:operation");
    localStorage.removeItem("@:documents");
  }, []);

  useEffect(() => {
    fetchOperations();
  }, [fetchOperations]);

  return (
    render && (
      <Container>
        <Breadcrumb
          crumbsOptions={[{ crumb: "" }, { crumb: "Simulações" }]}
          endHiddenIndex={5}
          startHiddenIndex={5}
          aria-label="Navegação de simulações"
        />

        <ContainerCanViewOperations>
          <>
            {canViewOperations && operationsSimulated?.length > 0 ? (
              <>
                <Margin mt={30} />
                <div>
                  <TableBasic
                    label="Simulações"
                    search
                    sizeSearch="large"
                    columnTitles={titlesColumn}
                    rowData={dataTable}
                    pageSizes={[10, 5, 20, 50, 100]}
                    width="1000px"
                    buttons={[
                      // {
                      //   kind: "ghost",
                      //   label: "",
                      //   position: "right",
                      //   icon: filterIcon,
                      //   width: "40px",
                      //   dimension: "36",
                      // },
                      {
                        kind: "ghost",
                        label: "",
                        position: "right",
                        icon: renewIcon,
                        size: "large",
                        width: "57px",
                        dimension: "55.5",
                        onClick: () => fetchOperations(),
                      },
                      {
                        kind: "primary",
                        label: "Nova Simulação",
                        position: "right",
                        icon: NewIcon,
                        size: "large",

                        onClick: () => {
                          setSimulateResult(null);
                          navigate("/app/credit/new-simulate");
                          localStorage.removeItem("@:simulate_operation");
                        },
                      },
                    ]}
                  />
                </div>
                <div id="lastUpdate">
                  <Margin ml={16} mt={25}>
                    <h5>
                      Atualizado em{" "}
                      <span style={{ fontWeight: "bold" }}>{currentDate}</span>,
                      às{" "}
                      <span style={{ fontWeight: "bold" }}>{currentTime}h</span>
                    </h5>
                  </Margin>
                </div>
              </>
            ) : (
              canViewOperations && (
                <div>
                  <Margin mt={70} />
                  <div>
                    <TagLup
                      src={lupaIcon}
                      aria-label="Ícone indicando que não há simulações"
                    />
                    <Text>Sem simulações</Text>
                    <Subtext>
                      Você não tem simulações para serem exibidas no momento
                    </Subtext>
                  </div>
                  <Margin mt={50} />
                  <div style={{ width: "320px" }}>
                    <Button
                      kind="primary"
                      size="medium"
                      label={"Nova simulação"}
                      aria-label="Criar nova simulaçao"
                      onClick={() => navigate("/app/credit/new-simulate")}
                      icon={moneyIcon}
                    />
                  </div>
                </div>
              )
            )}
          </>

          {!canViewOperations && (
            <div>
              <Margin mt={70} />
              <TagLup
                src={lockIcon}
                aria-label="Ícone indicando não há permissão para visualizar as simulações"
              />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para ver as simulações, <br /> contate o
                administrador do sistema para mais informações.
              </Subtext>
            </div>
          )}
        </ContainerCanViewOperations>
      </Container>
    )
  );
};

export default Simulations;
