import Margin from "~/components/Margin";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  TableBasic,
  Tooltip,
} from "prosperita-dumbo-react";
import MoreIcon from "~/assets/Addd.png";
import "prosperita-dumbo-react/dist/style.css";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import ViewIcon from "~/assets/View.png";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";

import { useCallback, useEffect, useMemo, useState } from "react";

import ArrowIcon from "~/assets/arrow-right-_1_.png";
import useLoading from "~/hooks/useLoading";

import Stepper from "~/components/Stepper";

// import MoreIcon from "~/assets/Addd.png";

import {
  getPartnerDocumentsAPI,
  getPartnersDetailsAPI,
  IPartner,
} from "~/services/api/partner";
import NewDocument from "./NewDocument";
import usePartner from "~/hooks/usePartner";
import { IDocumentDataPartner } from "~/types";
import { getLabelByValue } from "~/services/utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const AddDocuments = () => {
  const navigate = useNavigate();

  const { setAddDocument, setDocuments, documents } = usePartner();

  const { setLoading } = useLoading();

  const location = useLocation();

  const route = location.pathname;

  const { uuid } = useParams();

  const canDoSimulations = true;

  const steps = [
    {
      label: "Informações",
      isCompleted: true,
      isActive: false,
    },
    {
      label: "Dados bancários",
      isCompleted: true,
      isActive: false,
    },
    { label: "Documentos", isCompleted: false, isActive: true },
  ];

  const listDocuments = useMemo(() => {
    return [
      { label: "Contrato Social", value: "social_contract" },
      { label: "Comprovante de endereço", value: "proof_residence" },
      { label: "RG (Frente)", value: "front_document" },
      { label: "RG (Verso)", value: "back_document" },
      { label: "Imagem", value: "image" },
      { label: "Selfie", value: "selfie" },
      { label: "CPF", value: "cpf" },
      { label: "CNPJ", value: "cnpj" },
      { label: "CNH (Frente)", value: "cnh_front" },
      { label: "CNH (Verso)", value: "cnh_back" },
      { label: "Documento de Identidade (RG)", value: "identity_document" },
      { label: "Outro", value: "other" },
    ];
  }, []);

  const dataTableDocuments = useMemo(() => {
    return documents?.map((document: IDocumentDataPartner) => ({
      "Tipo de documento": getLabelByValue(document.type, listDocuments),
      "": (
        <div
          style={{
            position: "relative",
            top: "-8px",
            height: "20px",
            width: "50px",
          }}
        >
          {document.file_url || document.file ? (
            <Tooltip align="top" size="large" label={"Visualizar"}>
              <Button
                kind="ghost"
                size="small"
                icon={ViewIcon}
                label=""
                onClick={() =>
                  document.file_url
                    ? window.open(document.file_url, "_blank")
                    : document.file && window.open(document.file, "_blank")
                }
              />
            </Tooltip>
          ) : (
            <Button
              kind="ghost"
              size="small"
              icon={ViewIcon}
              disabled
              label=""
              onClick={() =>
                document.file_url
                  ? window.open(document.file_url, "_blank")
                  : document.file && window.open(document.file, "_blank")
              }
            />
          )}
        </div>
      ),
    }));
  }, [documents, listDocuments]);

  const getDocuments = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await getPartnerDocumentsAPI(uuid as string);

      setDocuments(data.payload);

      setLoading(false);
    } catch (e) {
      console.log(e);

      setLoading(false);
    }
  }, [setLoading, uuid, setDocuments]);

  const [partner, setPartner] = useState<IPartner>();

  const fetchPartner = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await getPartnersDetailsAPI(uuid as string);

      setPartner(data.payload);

      setLoading(false);
    } catch (e) {
      console.log(e);

      setLoading(false);
    }
  }, [setLoading, uuid]);

  useEffect(() => {
    fetchPartner();
    getDocuments();
  }, [getDocuments, fetchPartner]);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          {
            crumb: "Contatos",
            onClick: () => navigate("/app/contacts"),
          },
          { crumb: "Novo Contato" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div
        style={{
          position: "sticky",
          top: 113,
          marginTop: "30px",
          gap: "10px",
          width: "100%",
          zIndex: "4",
          padding: "10px",
          background: "#fff",
        }}
      >
        {" "}
        <h4>{partner?.name}</h4>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>{" "}
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              kind={"tertiary"}
              label="Voltar"
              size="large"
              onClick={() =>
                navigate(
                  `/app/contacts/new/${route.includes("person") ? "person" : "company"}/${uuid}/bank-data`
                )
              }
            />
            <Button
              kind="primary"
              icon={ArrowIcon}
              label={
                documents.length > 0 ? "Concluir" : "Continuar sem documento"
              }
              size="large"
              onClick={() => navigate("/app/contacts")}
            />
          </div>
        </div>
        <Stepper steps={steps} />
        <Margin mb={10} />
      </div>

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <h2
            style={{
              margin: "10px 0 0 45px ",
              color: "#000",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "40px",
            }}
          >
          Operações de crédito
          </h2> */}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          <>
            {canDoSimulations && documents.length > 0 ? (
              <>
                <div>
                  <Margin mt={30} />
                  <div>
                    <TableBasic
                      pageSizes={[10, 5, 20, 50, 100]}
                      search
                      columnTitles={["Tipo de documento", ""]}
                      rowData={dataTableDocuments || []}
                      width="1000px"
                      sizeSearch="large"
                      buttons={[
                        {
                          kind: "ghost",
                          label: "",
                          position: "right",
                          icon: renewIcon,
                          size: "large",
                          width: "57px",
                          dimension: "55.5",
                          // onClick: () => getInvolvedFromStorage(),
                        },
                        {
                          kind: "primary",
                          size: "large",
                          icon: MoreIcon,
                          label: "Adicionar documento",
                          position: "right",

                          onClick: () => setAddDocument(true),
                        },
                      ]}
                    />
                  </div>
                </div>
              </>
            ) : (
              canDoSimulations &&
              documents.length === 0 && (
                <div>
                  <Margin mt={70} />
                  <div>
                    <TagLup src={lupaIcon} />
                    <Text>Nenhum documento cadastrado</Text>
                  </div>
                  <Margin mt={50} />
                  <div style={{ width: "320px" }}>
                    <Button
                      kind="primary"
                      size="medium"
                      icon={MoreIcon}
                      label={"Adicionar documento"}
                      onClick={() => setAddDocument(true)}
                    />
                  </div>
                </div>
              )
            )}
          </>
        </>

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para visualizar as contas bancárias{" "}
                <br /> contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
      <div style={{ zIndex: "999" }}>
        <NewDocument />
      </div>
      <div style={{ zIndex: "999" }}>
        {/* <LateralModal
          open={addDocument}
          title="Adicionar documento"
          header
          toggleModal={() => {
            setAddDocument(!addDocument);
          }}
        >
          <Container>
            <div style={{ gridColumn: "span 12" }}>
              <InputSelect
                id="type"
                name="type"
                kind="default"
                size="large"
                label="Tipo de documento"
                placeholder="Selecione uma opção"
                onChange={(e) => handleSelect("type", e)}
                options={listDocuments}
              />
              <Margin mt={40} />
              <FileUploader
                maxlenghtindividual={1}
                size="large"
                accept=".png, .pdf, .jpeg, .jpg"
                onChange={handleFileChange}
                type="drag and top"
                headers={{
                  label: "Envio de documentos",
                  TextLabel:
                    "O tamanho máximo do arquivo é 500kb. Os tipos de arquivo suportados são .jpg, .png. e .pdf",
                }}
              >
                <div
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  Clique aqui para fazer o carregamento dos arquivos
                </div>
              </FileUploader>
            </div>
            <div style={{ height: "200px" }}></div>
            <Footer>
              {" "}
              <div style={{ gridColumn: "span 6" }}>
                <Button
                  kind="ghost"
                  size="large"
                  onClick={() => {
                    setAddDocument(false);
                  }}
                  label="Cancelar"
                  icon={CloseIcon}
                />
              </div>
              <div style={{ gridColumn: "span 6" }}>
                <Button
                  kind="primary"
                  size="large"
                  icon={SaveIcon}
                  onClick={() => createDocument()}
                  label="Adicionar documento"
                  disabled={false}
                />
              </div>
            </Footer>
          </Container>
        </LateralModal> */}
      </div>
    </Container>
  );
};

export default AddDocuments;
