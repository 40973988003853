import {
  Form,
  Heading,
  PasswordInput,
  Stack,
  Checkbox,
  InlineNotification,
} from "@carbon/react";
import BanvoxLogo from "~/assets/banvox.png";
import BgLogin from "~/assets/bgLogin.png";
import styled from "styled-components";
import Margin from "~/components/Margin";

import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getTokenAPI } from "~/services/api/auth";
import { useCallback, useState } from "react";
import AppStorage from "~/services/storage";
import MaskedTextInput from "~/components/InputMask";
import { onlyNumbers } from "~/services/utils";
import useLoading from "~/hooks/useLoading";
import { useCookies } from "react-cookie";
import { Button } from "prosperita-dumbo-react";
import ArrowIcon from "~/assets/arrow-right-_1_.png";

const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  .right-image {
    width: 100%;
    height: 100vh;
    background-image: url(${BgLogin});
    background-position: top;
    background-repeat: no-repeat;
    background-color: #fff;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 900px;
    background-color: #fff;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
  }

  .form-btn {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
  }

  .header {
    z-index: 1;
  }
`;

const HeadingContainer = styled.div`
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
`;

const RememberMeContainer = styled.div`
  margin-top: 1.5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e0e0e0;
`;

// const BottomLinkContainer = styled.div`
//   margin: 1rem 0;
//   padding-bottom: 2rem;
//   border-bottom: 1px solid #e0e0e0;
// `;

type Values = {
  username: string;
  password: string;
};

const initialValues: Values = {
  username: "",
  password: "",
};

const FormSchema = Yup.object().shape({
  username: Yup.string().required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatório"),
});

const Login = () => {
  const navigate = useNavigate();

  const [error, setError] = useState<string>();
  const { setLoading } = useLoading();
  const [rememberMe, setRememberMe] = useState<boolean | undefined>(false);
  console.log("teste");
  const [cookies, setCookie] = useCookies();

  const handleSend = async (values: Values) => {
    setLoading(true);
    if (rememberMe) {
      AppStorage.set("@app:username", values.username);
    } else {
      AppStorage.remove("@app:username");
    }

    try {
      const payload = {
        username: onlyNumbers(values.username),
        password: values.password,
      };

      const {
        data: { access, refresh },
      } = await getTokenAPI(payload);

      if (access && refresh) {
        setCookie(
          "token_cred_engine",
          { access, refresh },
          {
            path: "/",
            expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
            secure: window.location.hostname !== "localhost",
            sameSite:
              window.location.hostname === "localhost" ? "lax" : "strict",
            domain:
              window.location.hostname === "localhost"
                ? undefined
                : ".prosperita.io",
          }
        );

        AppStorage.setToken(access);
        AppStorage.setRefresh(refresh);

        setLoading(false);

        navigate("/app/credit/simulations");
      }
    } catch (error) {
      console.error("Error while trying to login:", error);
      setError(String(error));
      setLoading(false);
    }
  };

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: handleSend,
  });
  const handleChangeRememberMe = useCallback(() => {
    const newRememberMe = !rememberMe;
    setRememberMe(newRememberMe);
    AppStorage.set("@app:rememberMe", String(newRememberMe));
  }, [rememberMe]);

  return (
    <LoginContainer>
      <div className="form-container">
        <div style={{ marginLeft: "35px", width: "85%" }}>
          <Form onSubmit={handleSubmit}>
            <img
              style={{ height: "90px", position: "relative", left: "-10px" }}
              src={BanvoxLogo}
              alt=""
            />
            <Margin mb={-22} />
            <Heading style={{ fontSize: "30px" }}>
              Plataforma de Crédito
            </Heading>
            <Margin mb={50} />
            <HeadingContainer>
              <Heading>Efetuar login</Heading>
            </HeadingContainer>

            <Stack gap={5}>
              {error && (
                <InlineNotification
                  title="Erro!"
                  subtitle="Nenhuma conta encontrada com as credênciais fornecidas."
                  hideCloseButton
                  kind="error"
                  lowContrast
                />
              )}

              <p
                style={{ fontSize: "12px", color: "black", fontWeight: "300" }}
              >
                Digite seu CPF
              </p>
              <MaskedTextInput
                id="username"
                mask="999.999.999-99"
                onChange={handleChange("username")}
                placeholder="000.000.000-00"
                value={values.username}
              />

              <PasswordInput
                id="password"
                labelText="Senha"
                placeholder="Digite sua senha"
                type="password"
                onChange={handleChange("password")}
                value={values.password}
                helperText={errors.password}
                required
              />

              <div style={{ marginTop: "5px" }}>
                <Button
                  kind="primary"
                  label="Continuar"
                  size="medium"
                  icon={ArrowIcon}
                  onClick={() => handleSend(values)}
                />
              </div>
              <RememberMeContainer>
                <Checkbox
                  id="remember-me"
                  labelText="Lembrar de mim"
                  checked={rememberMe}
                  onChange={() => handleChangeRememberMe()}
                />
              </RememberMeContainer>
            </Stack>
          </Form>
        </div>
      </div>
      <div className="right-image"></div>
    </LoginContainer>
  );
};

export default Login;
