import { Button, Modal, TableBasic, Tooltip } from "prosperita-dumbo-react";
import styled from "styled-components";
import Margin from "~/components/Margin";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import { useNavigate } from "react-router-dom";
import useOperation from "~/hooks/useOperation";
import ArrowLeftIcon from "~/assets/arrow-lftt-_1_.png";
import { useCallback, useEffect, useState } from "react";
import { getPartnersAPI } from "~/services/api/partner";
import usePartner from "~/hooks/usePartner";
import useLoading from "~/hooks/useLoading";
import { DocumentData } from "~/types";
import TrashIcon from "~/assets/Trash-can.png";
import {
  IOperationInvolved,
  IOperationInvolvedDocumentsBody,
  postConfirmationOperationAPI,
  postOperationAPI,
  postOperationInvolvedDocumentsAPI,
  postOperationInvolvedsAPI,
  postSimulateOperationAPI,
} from "~/services/api/operations";
import MoreIcon from "~/assets/Addd.png";
import AppStorage from "~/services/storage";
import { toast } from "react-toastify";
import activityIcon from "~/assets/Activity.png";

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;
const canViewOperations = true;

const Documents = () => {
  const navigate = useNavigate();
  const {
    setTab,
    setAddDocument,
    setDocumentsRender,
    documentsRender,
    removeDocument,
    setRemoveDocument,
  } = useOperation();

  const { setPartners } = usePartner();
  const { setLoading } = useLoading();

  const [document, setDocument] = useState<{
    name: string;
    file: string;
    related_to_uuid: string;
    related_to_name?: string;
    type: string;
  }>({
    name: "",
    file: "",
    related_to_uuid: "",
    related_to_name: "",
    type: "",
  });

  const notifySuccess = () => {
    toast.success("Operação criada com sucesso!");
  };

  const notifyError = () => {
    toast.error("Não foi possível criar a operação!");
  };

  const titlesColumnDocuments = ["Relacionado a", "Tipo de documento", ""];

  const dataTableDocuments = documentsRender?.map(
    (document: {
      related_to_name: string;
      type_name: string;
      file: string;
      related_to_uuid: string;
      type: string;
    }) => {
      return {
        "Relacionado a": document.related_to_name,
        "Tipo de documento": document.type_name,
        "": (
          <div>
            <Tooltip align="top" size="medium" label="Remover">
              <img
                src={TrashIcon}
                loading="lazy"
                alt="Deletar"
                onClick={() => {
                  setDocument({
                    name: document?.type_name,
                    file: document.file,
                    related_to_uuid: document.related_to_uuid,
                    related_to_name: document.related_to_name,
                    type: document.type,
                  });
                  setRemoveDocument(true);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    }
  );

  const handleClose = useCallback(() => {
    setRemoveDocument(false);
    setDocument({ name: "", file: "", related_to_uuid: "", type: "" });
  }, []);

  const fetchPartners = useCallback(async () => {
    try {
      const { data } = await getPartnersAPI();
      setPartners(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setPartners]);

  const getDocumentsFromStorage = useCallback(() => {
    setLoading(true);
    const data = localStorage.getItem("@:documents");
    if (data) {
      setDocumentsRender(JSON.parse(data));
    } else {
      setDocumentsRender([]);
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [setDocumentsRender, setLoading]);

  // const convertToPercentage = (value: number): number => {
  //   if (value < 0 || value > 1) {
  //     throw new Error("O número deve estar entre 0 e 1.");
  //   }
  //   return value * 100;
  // };

  const sendNewOperation = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const recoveredSimulationUuid = AppStorage.get(
        "@:simulation_uuid"
      ) as string;
      const simulationUuid = recoveredSimulationUuid
        ? JSON.parse(recoveredSimulationUuid).simulation_uuid
        : "";
      let operationUuid = simulationUuid;

      const payloadSimulateOperation = AppStorage.get(
        "@:simulate_operation"
      ) as string;
      const payloadSimulateOperationParsed = payloadSimulateOperation
        ? JSON.parse(payloadSimulateOperation)
        : null;

      if (payloadSimulateOperationParsed.birthday_date_type === "") {
        delete payloadSimulateOperationParsed.birthday_date_type;
        delete payloadSimulateOperationParsed.birthday_date_day;
      }

      if (payloadSimulateOperationParsed.birthday_date_type === "payment") {
        delete payloadSimulateOperationParsed.birthday_date_day;
      }

      if (payloadSimulateOperationParsed?.birthday_date_type === "fixed") {
        payloadSimulateOperationParsed.birthday_date_day = Number(
          payloadSimulateOperationParsed.birthday_date_day
        );
      }

      const payloadOperation = AppStorage.get("@:operation") as string;
      const payloadOperationParsed = payloadOperation
        ? JSON.parse(payloadOperation)
        : {};

      if (!simulationUuid) {
        const { data } = await postSimulateOperationAPI(
          payloadSimulateOperationParsed
        );
        operationUuid = data.payload.uuid;
      }

      payloadOperationParsed.operation_uuid = operationUuid;

      const payloadAsset = {
        ...payloadOperationParsed,
      };

      if (payloadAsset.liquidation_type !== "billet") {
        delete payloadAsset.invoice_barcode;
      }

      const { data: dataOperation } = await postOperationAPI(payloadAsset);

      const assetUuid = dataOperation.payload.uuid;

      const involveds = AppStorage.get("@:involveds") as string;
      const involvedsParsed = involveds ? JSON.parse(involveds) : [];
      if (involvedsParsed && Array.isArray(involvedsParsed)) {
        const involvedPromises = involvedsParsed.map(
          async (involved: IOperationInvolved) => {
            const {
              auth_type,
              involved_partner_uuid,
              is_signer,
              relationship,
              send_type,
              sign_weight,
              related_to_uuid,
            } = involved;

            return await postOperationInvolvedsAPI({
              asset_uuid: dataOperation.payload.uuid,
              auth_type,
              involved_partner_uuid,
              is_signer: String(is_signer) === "false" ? false : true,
              relationship,
              send_type,
              sign_weight: sign_weight === "" ? 0 : Number(sign_weight),
              related_to_uuid,
            });
          }
        );
        await Promise.all(involvedPromises);
      }

      const documents = AppStorage.get("@:documents") as string;
      const documentsParsed = documents ? JSON.parse(documents) : [];
      if (documentsParsed && Array.isArray(documentsParsed)) {
        const documentsPromises = documentsParsed.map(
          async (document: IOperationInvolvedDocumentsBody) => {
            const { type, file, related_to_uuid } = document;

            const formData = new FormData();

            if (typeof file === "string") {
              const byteCharacters = atob(file.split(",")[1]);
              const byteArray = new Uint8Array(byteCharacters.length);

              for (let i = 0; i < byteCharacters.length; i++) {
                byteArray[i] = byteCharacters.charCodeAt(i);
              }
              const blob = new Blob([byteArray], {
                type: "application/octet-stream",
              });
              formData.append("file", blob, "filename.ext");
            } else {
              formData.append("file", file);
            }

            formData.append("type", type);
            formData.append("description", type);
            formData.append("related_to_uuid", related_to_uuid);
            formData.append("asset_uuid", assetUuid);

            return await postOperationInvolvedDocumentsAPI(formData);
          }
        );
        await Promise.all(documentsPromises);
      }

      await postConfirmationOperationAPI(assetUuid);

      navigate(`/app/credit/operations/details/${assetUuid}`);
      notifySuccess();

      localStorage.removeItem("@:simulation_uuid");
      setLoading(false);
    } catch (e) {
      setLoading(false);
      notifyError();
      console.error(e);
    }
  }, [navigate, setLoading]);

  const removeDocumentByFile = useCallback(
    (targetDocument: {
      file: string;
      related_to_uuid: string;
      type: string;
    }) => {
      try {
        const documentsData: DocumentData[] = JSON.parse(
          localStorage.getItem("@:documents") || "[]"
        );

        const updatedDocuments = documentsData.filter(
          (document) =>
            document.file !== targetDocument.file ||
            document.related_to_uuid !== targetDocument.related_to_uuid ||
            document.type !== targetDocument.type
        );

        localStorage.setItem("@:documents", JSON.stringify(updatedDocuments));

        handleClose();
        getDocumentsFromStorage();
      } catch (error) {
        console.error("Erro ao remover o documento:", error);
      }
    },
    [handleClose, getDocumentsFromStorage]
  );

  useEffect(() => {
    setTab(3);
    fetchPartners();
    getDocumentsFromStorage();
  }, [fetchPartners, getDocumentsFromStorage, setTab]);

  return (
    <div style={{ width: "100%" }}>
      <>
        {canViewOperations && documentsRender?.length > 0 ? (
          <>
            <>
              <Margin mt={30} />
              <div>
                <TableBasic
                  search
                  columnTitles={titlesColumnDocuments}
                  rowData={dataTableDocuments}
                  pageSizes={[5, 10, 20, 50, 100]}
                  sizeSearch="large"
                  width="1000px"
                  buttons={[
                    {
                      kind: "ghost",
                      label: "",
                      position: "right",
                      icon: renewIcon,
                      size: "large",
                      width: "57px",
                      dimension: "55.5",
                      onClick: () => getDocumentsFromStorage(),
                    },
                    {
                      kind: "primary",
                      label: "Adicionar documento",
                      position: "right",
                      size: "large",

                      onClick: () => setAddDocument(true),
                    },
                  ]}
                />
              </div>
            </>
          </>
        ) : (
          canViewOperations && (
            <div>
              <Margin mt={70} />
              <div>
                <TagLup src={lupaIcon} />
                <Text>Sem Documentos</Text>
                <Subtext>
                  Você não tem documentos para serem exibidas no momento
                </Subtext>
              </div>
              <Margin mt={50} />
              <div style={{ width: "320px" }}>
                <Button
                  kind="primary"
                  size="medium"
                  label={"Adicionar documento"}
                  onClick={() => setAddDocument(true)}
                  icon={MoreIcon}
                />
              </div>
            </div>
          )
        )}
      </>
      <Margin mt={100} />
      <div
        style={{
          gridColumn: "1 / -1",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <Button
            kind="ghost"
            size="large"
            label="Cancelar"
            onClick={() => navigate("/app/credit/operations/")}
          />
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            <Button
              kind="tertiary"
              size="large"
              label="Voltar"
              icon={ArrowLeftIcon}
              onClick={() => {
                navigate(
                  "/app/credit/operations/new/commercial-note/involveds/"
                );
                setTab(2);
              }}
            />
          </div>
          <div>
            <Button
              kind="primary"
              size="large"
              label="Finalizar"
              icon={activityIcon}
              onClick={() => {
                sendNewOperation();
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        height={"468px"}
        width="960px"
        background="#f4f4f4"
        label={<h3>Remover documento</h3>}
        radius
        onClose={() => {
          handleClose();
        }}
        open={removeDocument}
        buttons={[
          { kind: "ghost", label: "Cancelar", onClick: () => handleClose() },
          {
            kind: "primary",
            label: "Sim, remover",
            onClick: () =>
              removeDocumentByFile({
                file: document.file,
                related_to_uuid: document.related_to_uuid,
                type: document.type,
              }),
          },
        ]}
      >
        <div style={{ padding: "50px" }}>
          <h2>
            Deseja realmente remover o documento {document?.name} associado à{" "}
            {document.related_to_name} ?{" "}
          </h2>
        </div>
      </Modal>
    </div>
  );
};
export default Documents;
