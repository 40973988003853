import Margin from "~/components/Margin";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Input,
  InputCurrency,
  InputSelect,
  Modal,
  TableBatchActions,
  Tag,
} from "prosperita-dumbo-react";
import filterIcon from "~/assets/Filter.png";
import moneyIcon from "~/assets/Money.png";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import lockIcon from "~/assets/lock.png";
import cleanIcon from "~/assets/clean.png";
import arrowRightIcon from "~/assets/arrow-right.png";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Column, Grid, ProgressBar } from "@carbon/react";
import { useFormik } from "formik";
import {
  formatCPFCNPJ,
  formatNumberToCurrencyWithoutSimbol,
  removeEmptyFields,
} from "~/services/utils";
import { format, parseISO } from "date-fns";
import { getOperationsAPI, IOperations } from "~/services/api/operations";
import useOperation from "~/hooks/useOperation";
import useLoading from "~/hooks/useLoading";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const filterKeyDict = {
  status: "Status",
  productCategory: "Categoria do Produto",
  borrower: "Tomador",
  involvedParty: "Envolvido",
  relationshipType: "Tipo de Relação",
  startDateFrom: "Data de Início De",
  startDateTo: "Data de Início Até",
  paymentDateFrom: "Data de Pagamento De",
  paymentDateTo: "Data de Pagamento Até",
  minimumValue: "Valor Mínimo",
  maximumValue: "Valor Máximo",
  correspondentBank: "Correspondente Bancário",
  operationNumber: "Número da Operação",
  ownSettlement: "Liquidação Própria",
  group: "Grupo",
  ownComplianceApproval: "Aprovação Compliance Própria",
};

type FormValues = {
  status: string;
  productCategory: string;
  borrower: string;
  involvedParty: string;
  relationshipType: string;
  startDateFrom: null;
  startDateTo: null;
  paymentDateFrom: null;
  paymentDateTo: null;
  minimumValue: string;
  maximumValue: string;
  correspondentBank: string;
  operationNumber: string;
  ownSettlement: string[];
  group: string[];
  ownComplianceApproval: string[];
};

const initialValues: FormValues = {
  status: "",
  productCategory: "",
  borrower: "",
  involvedParty: "",
  relationshipType: "",
  startDateFrom: null,
  startDateTo: null,
  paymentDateFrom: null,
  paymentDateTo: null,
  minimumValue: "",
  maximumValue: "",
  correspondentBank: "",
  operationNumber: "",
  ownSettlement: [],
  group: [],
  ownComplianceApproval: [],
};

const Excluded = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [render, setRender] = useState<boolean>(false);
  {
    /*const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };*/
  }

  const { setOperations, operations } = useOperation();

  const [checkedIndexes, setCheckedIndexes] = useState<boolean[]>(
    Array(operations.length).fill(false)
  );

  useEffect(() => {
    setCheckedIndexes(Array(operations.length).fill(false));
  }, [operations]);

  const [currentDate, setCurrentDate] = useState(() =>
    new Date().toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  );

  const [currentTime, setCurrentTime] = useState(() =>
    new Date().toLocaleString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  );

  const [appliedExcludedFilters, setAppliedExcludedFilters] =
    useState<FormValues>(initialValues);

  const [isExcludedFilterModalOpen, setIsExcludedFilterModalOpen] =
    useState(false);

  const updateDateTime = () => {
    setCurrentDate(
      new Date().toLocaleString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    );
    setCurrentTime(
      new Date().toLocaleString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
    );
  };

  const titlesColumn = [
    <Checkbox />,
    "Status",
    "Operações",
    "Tomador",
    "CPF / CNPJ",
    "Valor principal",
    "Data de início",
    "Taxa",
  ];

  const canViewOperations = true;

  function handleOpenExcludedFilterModal() {
    setIsExcludedFilterModalOpen(true);
  }
  function handleCloseExcludedFilterModal() {
    setIsExcludedFilterModalOpen(false);
  }

  const filteredFilters = useMemo(() => {
    return removeEmptyFields(appliedExcludedFilters);
  }, [appliedExcludedFilters]);

  console.log(filteredFilters);

  const statusOperations = {
    processing: "Processando",
    denied: "Negado",
    opened: "Aberto",
    expired: "Removido",
    signing: "Assinando",
    confirmed: "Confirmado",
    finished: "Finalizado",
  };

  const statusOperationsTagColor = {
    processing: "gray",
    denied: "red",
    opened: "gray",
    expired: "red",
    signing: "blue",
    confirmed: "green",
    finished: "green",
  };

  const dataTable = operations?.map((operation: IOperations, index: number) => {
    return {
      Checkbox: (
        <Checkbox
          key={index}
          checked={checkedIndexes[index]}
          onClick={() => {
            //   handleCheckboxChange(index, operation); null
          }}
        />
      ),
      Status: (
        <div style={{ maxWidth: "150px" }}>
          <Tag
            type={statusOperationsTagColor[operation.status]}
            size="medium"
            position="center"
          >
            <div style={{ padding: "2px" }}>
              {" "}
              {statusOperations[operation.status]}
            </div>
          </Tag>
        </div>
      ),
      status: operation.status,
      operations: `${operation.operation_type} Dias`,
      borrower: operation.debtor_name,
      documentNumber: formatCPFCNPJ(operation.debtor_document),
      principalAmount: `R$ ${formatNumberToCurrencyWithoutSimbol(operation.principal_amount)}`,
      startDate: format(parseISO(operation.operation_date), "dd/MM/yyyy"),
      rate: operation.interest_rate ? `${operation.interest_rate}%` : "-",
    };
  });

  const handleApplyExcludedFilters = (values: FormValues) => {
    setAppliedExcludedFilters(values);
    setIsExcludedFilterModalOpen(false);
  };

  const { values, setFieldValue, handleChange, resetForm } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const renderFilters = () => {
    const filterKeys = Object.keys(filteredFilters);

    return (
      filterKeys.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            marginBottom: "16px",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            {filterKeys.map((key) => {
              const filterKey = filterKeyDict[key] || key;

              // Verifica se o valor é um array e renderiza adequadamente
              const values = Array.isArray(filteredFilters[key])
                ? filteredFilters[key].every((item) => typeof item === "object")
                  ? // Se todos os itens forem objetos (como no caso do InputSelect), pegamos o label ou value
                    filteredFilters[key]
                      .map((item) => item.label || item.value)
                      .join(", ")
                  : // Se os itens forem strings (como nos valores de checkbox), apenas junta os valores
                    filteredFilters[key].join(", ")
                : filteredFilters[key];

              return (
                <Tag type="blue" key={key}>
                  {filterKey}:{" "}
                  {key === "minimumValue" || key === "maximumValue"
                    ? values === "" || values == null || isNaN(Number(values))
                      ? values
                      : `R$ ${formatNumberToCurrencyWithoutSimbol(Number(values))}`
                    : key === "startDateFrom" ||
                        key === "startDateTo" ||
                        key === "startPaymentFrom" ||
                        key === "startPaymentTo"
                      ? (typeof values === "string" ||
                          typeof values === "number") &&
                        !isNaN(new Date(values).getTime())
                        ? format(new Date(values), "dd/MM/yyyy")
                        : "Data inválida"
                      : values}
                </Tag>
              );
            })}
          </div>
          <Button
            kind="tertiary"
            label="Limpar tudo"
            icon={cleanIcon}
            onClick={() => {
              setAppliedExcludedFilters(initialValues);

              resetForm();
            }}
            size="small"
          />
        </div>
      )
    );
  };

  const fetchOperations = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getOperationsAPI({
        status: ["expired"],
      });
      setOperations(data.payload);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    } finally {
      setRender(true);
    }
  }, [setOperations, setLoading]);

  useEffect(() => {
    fetchOperations();
  }, [fetchOperations]);

  return (
    <>
      {render && (
        <Container>
          <Breadcrumb
            crumbsOptions={[
              { crumb: "Início" },
              { crumb: "Crédito" },
              { crumb: "Operações Excluídas" },
            ]}
            endHiddenIndex={5}
            startHiddenIndex={5}
          />

          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            ></div>
          </div>
          <div style={{ width: "100%" }}>
            <>
              {canViewOperations && operations?.length > 0 ? (
                <>
                  <Margin mt={32} />

                  {renderFilters()}

                  <>
                    <div>
                      <TableBatchActions
                        search
                        headerAction={{
                          active: false,
                          count: 0,
                        }}
                        columnTitles={titlesColumn}
                        rowData={dataTable}
                        columsKeys={[
                          "",
                          "Checkbox",
                          "Status",
                          "operations",
                          "borrower",
                          "documentNumber",
                          "principalAmount",
                          "startDate",
                          "rate",
                        ]}
                        label="Operações excluidas"
                        pageSizes={[5, 10, 15, 20, 50, 100]}
                        width="1000px"
                        actions={[
                          {
                            text: "Baixa de cobrança",
                            icon: moneyIcon,
                          },
                          {
                            text: "Editar boleto",
                            feature: true,
                          },
                          {
                            text: "Cancelar",
                            icon: "",
                          },
                        ]}
                        buttons={[
                          {
                            kind: "ghost",
                            label: "",
                            position: "right",
                            icon: filterIcon,
                            width: "40px",
                            dimension: "36",
                            onClick: handleOpenExcludedFilterModal,
                          },
                          {
                            kind: "ghost",
                            label: "",
                            position: "right",
                            icon: renewIcon,
                            width: "40px",
                            dimension: "36",
                            onClick: updateDateTime,
                          },
                        ]}
                      />
                    </div>
                    <div id="lastUpdate">
                      <Margin all={16}>
                        <h5>
                          Atualizado em{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {currentDate}
                          </span>
                          , às{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {currentTime}h
                          </span>
                        </h5>
                      </Margin>
                    </div>
                  </>
                </>
              ) : (
                canViewOperations && (
                  <div>
                    <Margin mt={50} />
                    <div>
                      <TagLup src={lupaIcon} />
                      <Text>Sem exclusões</Text>
                      <Subtext>
                        Você não tem exclusões para serem exibidas no momento
                      </Subtext>
                    </div>
                    <Margin mt={50} />
                    <div style={{ width: "320px" }}>
                      <Button
                        kind="primary"
                        size="medium"
                        label={"Atualizar"}
                        onClick={() => navigate("/app/credit/operations/new")}
                      />
                    </div>
                  </div>
                )
              )}
            </>

            {!canViewOperations && (
              <>
                <div>
                  <Margin mt={70} />
                  <TagLup src={lockIcon} />
                  <Margin mt={30} />
                  <Text>Acesso restrito</Text>

                  <Subtext>
                    Você não tem permissão para visualizar as exclusões.
                  </Subtext>
                </div>
              </>
            )}
          </div>
        </Container>
      )}

      <Modal
        label="Filtros"
        background="#FFFFFF"
        open={isExcludedFilterModalOpen}
        onClose={handleCloseExcludedFilterModal}
      >
        <ProgressBar label={""} value={25} size="small" />
        <Grid fullWidth style={{ padding: 0 }}>
          <Column lg={16}>
            <Margin mb={32} />
          </Column>

          <Column lg={8} noGutter>
            <InputSelect
              isMulti
              kind="default"
              label="Status"
              message="Warning message goes here"
              onChange={(event) => {
                setFieldValue("status", event);
              }}
              value={values.status}
              options={[
                {
                  label: "Pago",
                  value: "Pago",
                },
                {
                  label: "Erro",
                  value: "Erro",
                },
                {
                  label: "Garantia",
                  value: "Garantia",
                },
              ]}
              placeholder="Escolha uma opção"
              size="large"
            />
          </Column>
          <Column lg={8}>
            <InputSelect
              isMulti
              kind="default"
              label="Categoria do produto"
              message="Warning message goes here"
              onChange={(selected) =>
                setFieldValue("productCategory", selected)
              }
              value={values.productCategory}
              options={[
                {
                  label: "Categoria 1",
                  value: "Categoria 1",
                },
                {
                  label: "Categoria 2",
                  value: "Categoria 2",
                },
              ]}
              placeholder="Escolha uma opção"
              size="large"
            />
          </Column>
          <Column lg={16}>
            <Margin mb={16} />
          </Column>
          <Column lg={16}>
            <InputSelect
              isMulti
              kind="default"
              label="borrower"
              message="Warning message goes here"
              onChange={(selected) => setFieldValue("borrower", selected)}
              value={values.borrower}
              options={[
                {
                  label: "Tomador 1",
                  value: "Tomador 1",
                },
                {
                  label: "Tomador 2",
                  value: "Tomador 2",
                },
              ]}
              placeholder="Escolha uma opção"
              size="large"
            />
          </Column>

          <Column lg={16}>
            <Margin mb={40} />
          </Column>

          <Column lg={16}>
            <h4>Envolvidos</h4>
            <Margin mb={16} />
          </Column>

          <Column lg={8}>
            <InputSelect
              isMulti
              kind="default"
              label="Envolvido"
              message="Warning message goes here"
              onChange={(event) => {
                setFieldValue("involvedParty", event);
              }}
              value={values.involvedParty}
              options={[
                {
                  label: "Envolvido 1",
                  value: "Envolvido 1",
                },
                {
                  label: "Envolvido 2",
                  value: "Envolvido 2",
                },
              ]}
              placeholder="Escolha uma opção"
              size="large"
            />
          </Column>
          <Column lg={8}>
            <InputSelect
              isMulti
              kind="default"
              label="Tipo de relação"
              message="Warning message goes here"
              onChange={(selected) =>
                setFieldValue("relationshipType", selected)
              }
              value={values.relationshipType}
              options={[
                {
                  label: "Tipo 1",
                  value: "Tipo 1",
                },
                {
                  label: "Tipo 2",
                  value: "Tipo 2",
                },
              ]}
              placeholder="Escolha uma opção"
              size="large"
            />
          </Column>

          <Column lg={16}>
            <Margin mb={40} />
          </Column>

          <Column lg={16}>
            <h4>Data de início</h4>
            <Margin mb={16} />
          </Column>

          <Column lg={8}>
            <Input
              label="De"
              placeholder="Placeholder Text"
              size="large"
              type="date"
              onChange={(e) => setFieldValue("startDateFrom", e.target.value)}
              value={values.startDateFrom || ""}
            />
          </Column>
          <Column lg={8}>
            <Input
              label="Até"
              placeholder="Placeholder Text"
              size="large"
              type="date"
              onChange={(e) => setFieldValue("startDateTo", e.target.value)}
              value={values.startDateTo || ""}
            />
          </Column>

          <Column lg={16}>
            <Margin mb={40} />
          </Column>

          <Column lg={16}>
            <h4>Data de pagamento</h4>
            <Margin mb={16} />
          </Column>

          <Column lg={8}>
            <Input
              label="De"
              placeholder="Placeholder Text"
              size="large"
              type="date"
              onChange={(e) => setFieldValue("paymentDateFrom", e.target.value)}
              value={values.paymentDateFrom || ""}
            />
          </Column>
          <Column lg={8}>
            <Input
              label="Até"
              placeholder="Placeholder Text"
              size="large"
              type="date"
              onChange={(e) => setFieldValue("paymentDateTo", e.target.value)}
              value={values.paymentDateTo || ""}
            />
          </Column>

          <Column lg={16}>
            <Margin mb={40} />
          </Column>

          <Column lg={16}>
            <h4>Valor</h4>
            <Margin mb={16} />
          </Column>

          <Column lg={8}>
            <InputCurrency
              name="minimumValue"
              id="minimumValue"
              label="Valor minimo"
              placeholder="R$"
              size="large"
              type="text"
              message="Warning message goes here"
              onChange={(e) => setFieldValue("minimumValue", e.target.value)}
              value={values.minimumValue}
            />
          </Column>
          <Column lg={8}>
            <InputCurrency
              label="Valor maximo"
              placeholder="R$"
              size="large"
              type="text"
              onChange={(e) => setFieldValue("maximumValue", e.target.value)}
              value={values.maximumValue}
            />
          </Column>

          <Column lg={16}>
            <Margin mb={40} />
          </Column>

          <Column lg={16}>
            <h4>Outros tipos de filtros</h4>
            <Margin mb={16} />
          </Column>

          <Column lg={8}>
            <Input
              name="correspondentBank"
              id="correspondentBank"
              label="Correspondente bancário"
              placeholder="Digite"
              size="large"
              type="text"
              onChange={handleChange}
              value={values.correspondentBank}
            />
          </Column>
          <Column lg={8}>
            <Input
              label="Numero da operação"
              placeholder="#"
              size="large"
              type="text"
              onChange={(e) => setFieldValue("operationNumber", e.target.value)}
              value={values.operationNumber}
            />
          </Column>

          <Column lg={16}>
            <Margin mb={40} />
          </Column>

          <Column lg={8}>
            <p>Liquidação propria</p>
            <Checkbox
              label="Sim"
              checked={values.ownSettlement.includes("Sim")} // Verifica se "Sim" está selecionado
              onChange={() => {
                const newValue = values.ownSettlement.includes("Sim")
                  ? values.ownSettlement.filter((item) => item !== "Sim") // Remove se já estiver selecionado
                  : [...values.ownSettlement, "Sim"]; // Adiciona "Sim"

                setFieldValue("ownSettlement", newValue);
              }}
            />
            <Checkbox
              label="Não"
              checked={values.ownSettlement.includes("Nao")} // Verifica se "Sim" está selecionado
              onChange={() => {
                const newValue = values.ownSettlement.includes("Nao")
                  ? values.ownSettlement.filter((item) => item !== "Nao") // Remove se já estiver selecionado
                  : [...values.ownSettlement, "Nao"]; // Adiciona "Sim"

                setFieldValue("ownSettlement", newValue);
              }}
            />
          </Column>

          <Column lg={8}>
            <p>Grupo</p>
            <Checkbox
              label="Administrador"
              checked={values.group.includes("Administrador")} // Verifica se "Sim" está selecionado
              onChange={() => {
                const newValue = values.group.includes("Administrador")
                  ? values.group.filter((item) => item !== "Administrador") // Remove se já estiver selecionado
                  : [...values.group, "Administrador"]; // Adiciona "Sim"
                setFieldValue("group", newValue);
              }}
            />
            <Checkbox
              label="Usuario"
              checked={values.group.includes("Usuario")} // Verifica se "Sim" está selecionado
              onChange={() => {
                const newValue = values.group.includes("Usuario")
                  ? values.group.filter((item) => item !== "Usuario") // Remove se já estiver selecionado
                  : [...values.group, "Usuario"]; // Adiciona "Sim"
                setFieldValue("group", newValue);
              }}
            />
          </Column>

          <Column lg={16}>
            <Margin mb={40} />
          </Column>

          <Column lg={8}>
            <p>Aprovação compliance própria</p>
            <Checkbox
              label="Sim"
              checked={values.ownComplianceApproval.includes("Sim")} // Verifica se "Sim" está selecionado
              onChange={() => {
                const newValue = values.ownComplianceApproval.includes("Sim")
                  ? values.ownComplianceApproval.filter(
                      (item) => item !== "Sim"
                    ) // Remove se já estiver selecionado
                  : [...values.ownComplianceApproval, "Sim"]; // Adiciona "Sim"

                setFieldValue("ownComplianceApproval", newValue);
              }}
            />
            <Checkbox
              label="Não"
              checked={values.ownComplianceApproval.includes("Nao")} // Verifica se "Nao" está selecionado
              onChange={() => {
                const newValue = values.ownComplianceApproval.includes("Nao")
                  ? values.ownComplianceApproval.filter(
                      (item) => item !== "Nao"
                    ) // Remove se já estiver selecionado
                  : [...values.ownComplianceApproval, "Nao"]; // Adiciona "Nao"

                setFieldValue("ownComplianceApproval", newValue);
              }}
            />
          </Column>

          <Column lg={16}>
            <Margin mb={40} />
          </Column>

          <Column lg={8}>
            <Button
              kind="tertiary"
              label="Limpar tudo"
              icon={cleanIcon}
              onClick={() => {
                setAppliedExcludedFilters(initialValues);

                resetForm();
              }}
              size="large"
            />
          </Column>
          <Column lg={8}>
            <Button
              kind="primary"
              label="Aplicar filtros"
              icon={arrowRightIcon}
              onClick={() => handleApplyExcludedFilters(values)}
              size="large"
            />
          </Column>

          <Column lg={16}>
            <Margin mb={48} />
          </Column>
        </Grid>
      </Modal>
    </>
  );
};

export default Excluded;
