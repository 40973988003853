import { Section } from "@carbon/react";
import { Logout } from "@carbon/icons-react";
import "@fontsource/ibm-plex-sans";
//import { Badge, Logout } from "@carbon/icons-react";
import styled from "styled-components";
import Margin from "~/components/Margin";
import { Outlet, useNavigate } from "react-router-dom"; //useLocation
import useAuth from "~/hooks/useAuth";
import BanvoxLogo from "~/assets/banvox.png";
import { useCallback, useEffect, useState } from "react";
import "prosperita-dumbo-react/dist/style.css";
import "prosperita-dumbo-react/dist/style.css";
import { Notification, Tooltip, UIHeaderNav } from "prosperita-dumbo-react";
import { useNotification } from "~/hooks/useNotification";
import { getPartnersAPI } from "~/services/api/partner";
import axios, { AxiosError } from "axios";
import { getUserAPI, IUser } from "~/services/api/user";
import { useCookies } from "react-cookie";
import { getTokenExternalAPI } from "~/services/api/auth";
import UserDropdown from "~/components/UserDropdown";
import portalManagerIcon from "~/assets/portal_manager.png";
// import myDataIcon from "~/assets/my_data.png";
import AppStorage from "~/services/storage";

const DashboardContainer = styled.div`
  min-height: 100vh;

  .cds--header--navigation {
    top: 48px;

    nav {
      padding-left: 0;
    }
  }
  .header {
    z-index: 1;
  }
`;

// type MenuItem = {
//   label: string;
//   route?: string;
//   children?: MenuItem[];
// };

const ResetCss = styled.div``;

const DashboardStructure = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const route = location.pathname;

  const { logout } = useAuth();

  const { notification } = useNotification();
  const [user, setUser] = useState<IUser>();
  const [cookies, setCookie, removeCookie] = useCookies([
    "token_cred_engine",
    "token_portal_manager",
  ]);

  const handleLogout = () => {
    removeCookie("token_cred_engine", {
      path: "/",
      secure: window.location.hostname !== "localhost",
      sameSite: window.location.hostname === "localhost" ? "lax" : "strict",
      domain:
        window.location.hostname === "localhost" ? undefined : ".prosperita.io",
    });
    removeCookie("token_portal_manager", {
      path: "/",
      secure: window.location.hostname !== "localhost",
      sameSite: window.location.hostname === "localhost" ? "lax" : "strict",
      domain:
        window.location.hostname === "localhost" ? undefined : ".prosperita.io",
    });
    logout();
  };

  const fetchUser = useCallback(async (): Promise<void> => {
    try {
      const { data } = await getUserAPI();
      setUser(data.payload[0]);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchPartners = useCallback(async (): Promise<void> => {
    try {
      await getPartnersAPI();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = e as AxiosError;

        if (error.response && error.response.data) {
          const errorData = error.response.data as {
            error?: { error?: string };
          };

          if (errorData.error?.error === "authentication_error") {
            removeCookie("token_cred_engine", {
              path: "/",
              secure: window.location.hostname !== "localhost",
              sameSite:
                window.location.hostname === "localhost" ? "lax" : "strict",
              domain:
                window.location.hostname === "localhost"
                  ? undefined
                  : ".prosperita.io",
            });
            removeCookie("token_portal_manager", {
              path: "/",
              secure: window.location.hostname !== "localhost",
              sameSite:
                window.location.hostname === "localhost" ? "lax" : "strict",
              domain:
                window.location.hostname === "localhost"
                  ? undefined
                  : ".prosperita.io",
            });
            AppStorage.remove("@app:token_cred_engine");
            location.reload();
          } else {
            console.log("Outro erro:", errorData);
          }
        } else {
          console.log("Erro sem dados de resposta:", e.message);
        }
      } else {
        console.error("Erro inesperado:", e);
      }
    }
  }, [removeCookie]);

  const [currentTime, setCurrentTime] = useState(() =>
    new Date().toLocaleString("pt-BR", { hour: "2-digit", minute: "2-digit" })
  );

  const fetchTokenExternal = useCallback(async (): Promise<void> => {
    try {
      const { data } = await getTokenExternalAPI();

      setCookie("token_portal_manager", data.payload, {
        path: "/",
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
        secure: window.location.hostname !== "localhost",
        sameSite: window.location.hostname === "localhost" ? "lax" : "strict",
        domain:
          window.location.hostname === "localhost"
            ? undefined
            : ".prosperita.io",
      });
    } catch (e) {
      console.log(e);
    }
  }, [setCookie]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(
        new Date().toLocaleString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    }, 1000);

    fetchPartners();

    fetchUser();
    fetchTokenExternal();

    return () => clearInterval(intervalId);
  }, [fetchPartners, fetchUser, fetchTokenExternal]);

  const renderNavigationMenu = () => {
    return (
      <>
        {" "}
        <UIHeaderNav
          SectionRightTime={`${new Date().toLocaleDateString()} 
      - ${currentTime}`}
          options={[
            {
              label: "Simulações",
              permission: true,
              onClick: () => navigate("/app/credit/simulations"),
            },
            {
              label: "Operações",
              onClick: () => navigate("/app/credit/operations"),
              permission: true,
            },
            {
              label: "Templates",
              onClick: () => navigate("/app/credit/templates"),
              permission: true,
            },
            // {
            //   label: "Excluídos",
            //   permission: true,
            //   onClick: () => navigate("/app/credit/excludeds"),
            // },

            {
              label: "Contatos",
              permission: true,
              onClick: () => navigate("/app/contacts/"),
            },
          ]}
          icon={
            <img
              onClick={() => navigate("/")}
              src={BanvoxLogo}
              alt=""
              style={{
                height: "60px",
                cursor: "pointer",
                filter: "brightness(10%) invert(1) brightness(100%)",
              }}
            />
          }
          userName={
            <h1
              style={{
                fontSize: "13px",
                fontWeight: "400",
                fontStyle: "normal",
                fontFamily: "sans-serif",
                margin: "0",
                padding: "0",
              }}
            >
              Olá, {user?.first_name ?? user?.last_name ?? "Usuário"}
            </h1>
          }
          emailuser={`${user?.email ?? ""}`}
          // bgHeader={"#29183A"}
          bgHeader={"#0a4a87"}
        >
          <UserDropdown
            options={[
              // {
              //   icon: myDataIcon,
              //   label: "Meus dados",
              //   onClick: () => console.log("Option 1 clicked"),
              // },
              {
                icon: portalManagerIcon,
                label: "Portal Gestor",
                onClick: () =>
                  window.open(
                    "https://dev.app.managers.prosperita.io/",
                    "_blank"
                  ),
              },
            ]}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "60px",
              justifyContent: "center",
              width: "50px",
              padding: "0",
              margin: "0 10px 0 0",
            }}
          >
            {" "}
            <Tooltip align="bottom" size="small" label="Sair" width="60px">
              <div
                style={{
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleLogout}
              >
                <Logout size={20} />
              </div>
            </Tooltip>
          </div>
        </UIHeaderNav>
      </>
    );
  };

  return (
    <>
      <ResetCss>
        <>{renderNavigationMenu()}</>
      </ResetCss>
      <Margin mt={115} />
      <DashboardContainer>
        <Section>
          <Outlet />
        </Section>{" "}
        <Margin mt={90} />
      </DashboardContainer>
      <Notification
        actived={notification.actived}
        zindex="9999"
        status={notification.status}
        message={notification.message}
        title={notification.title}
      />
    </>
  );
};

export default DashboardStructure;
