import { Button, Input, InputSelect, Radio } from "prosperita-dumbo-react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import LateralModal from "~/components/LateralModal";
import useOperation from "~/hooks/useOperation";
import CloseIcon from "~/assets/Close--outline.png";
import SaveIcon from "~/assets/Save.png";
import usePartner from "~/hooks/usePartner";
import { IPartner } from "~/services/api/partner";
import { truncatedText, zIndex } from "~/services/utils";
import { useFormik } from "formik";
import Label from "~/components/Label";
import {
  getSubscriberAPI,
  IBodySubscriberInvolved,
  postSubscriberInvolvedAPI,
} from "~/services/api/subscriber";
import { useParams } from "react-router-dom";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";

const Container = styled.div`
  width: 100%;
  padding: 30px;
  display: grid;
  height: auto;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  z-index: 2;
`;

const Footer = styled.div`
  width: 90%;
  position: fixed;
  bottom: 0;
  display: grid;
  padding-bottom: 50px;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
`;

type Values = {
  involved_partner: string;
  related_to: string;
  relationship: string;
  auth_type: string;
  send_type: string;
  is_signer: boolean;
  sign_weight: string;
};

const initialValues: Values = {
  involved_partner: "",
  related_to: "",
  relationship: "",
  auth_type: "",
  send_type: "",
  is_signer: false,
  sign_weight: "",
};

const NewInvolved = () => {
  const { addInvolved, setAddInvolved, setSubscriber, subscriber } =
    useOperation();
  const { partners } = usePartner();

  const { setLoading } = useLoading();

  const { setFieldValue, values, resetForm } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const handleClose = useCallback(() => {
    setAddInvolved(false);
    resetForm();
    setFieldValue("involved_partner", "");
    setFieldValue("related_to", "");
    setFieldValue("relationship", "");
    setFieldValue("auth_type", "");
    setFieldValue("send_type", "");
    setFieldValue("is_signer", false);
    setFieldValue("sign_weight", "");
  }, [setFieldValue, resetForm, setAddInvolved]);

  const bodyRef = useRef(document.body);

  const areValuesEmptyOrFalse = (values) => {
    if (
      !values.involved_partner ||
      !values.related_to ||
      !values.relationship ||
      !values.auth_type ||
      !values.send_type ||
      (values.is_signer === true && !values.sign_weight)
    ) {
      return true;
    }

    return false;
  };

  const listAuths = useMemo(() => {
    return [
      { label: "Email", value: "email" },
      { label: "Biometria", value: "biometric" },
      { label: "ICP Brasil", value: "icp_brasil" },
    ];
  }, []);

  const listSends = useMemo(() => {
    return [
      { label: "Email", value: "email" },
      { label: "SMS", value: "sms" },
      { label: "Whatsapp", value: "whatsapp" },
    ];
  }, []);

  const listRelationships = useMemo(() => {
    return [
      { label: "Correntista", value: "account_holder" },
      { label: "Administrador", value: "administrator" },
      { label: "Procurador", value: "attorney" },
      { label: "Comodatário", value: "borrower" },
      { label: "Responsável Solidário", value: "co_responsible" },
      { label: "Caucionante", value: "collateral_provider" },
      { label: "Anuente", value: "consenting" },
      { label: "Interveniente Anuente", value: "consenting_intervenor" },
      { label: "Credor", value: "creditor" },
      { label: "Devedor", value: "debtor" },
      { label: "Endossante", value: "endorser" },
      { label: "Endossatário", value: "endorsee" },
      { label: "Avalista / Fiador", value: "guarantor" },
      { label: "Cônjuge do Fiador", value: "guarantor_spouse" },
      { label: "Intermediário", value: "intermediary" },
      { label: "Interveniente Garantidor", value: "intervening_guarantor" },
      { label: "Emitente", value: "issuer" },
      { label: "Devedor Solidário", value: "joint_debtor" },
      { label: "Responsável Legal", value: "legal_guardian" },
      { label: "Representante Legal", value: "legal_representative" },
      { label: "Mutuante", value: "lender" },
      { label: "Sócio", value: "partner" },
      { label: "Presidente", value: "president" },
      { label: "Cedente", value: "transferor" },
      { label: "Cessionário", value: "transferee" },
      { label: "Testemunha", value: "witness" },
    ];
  }, []);

  const optionsRelationship = useMemo(() => {
    return listRelationships
      ?.map((relationship) => ({
        value: `${relationship.label}/*?${relationship.value}`,
        label: relationship.label,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [listRelationships]);

  const partnersOptions = useMemo(() => {
    return partners
      .map((partner: IPartner) => ({
        value: `${partner.name}/*?${partner.uuid}`,
        label: partner.name,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [partners]);

  const partnersRelatedToOptions = useMemo(() => {
    if (subscriber?.partner.uuid) {
      const uuid = subscriber?.partner.uuid;

      return partners
        .filter((partner: IPartner) => partner.uuid === uuid)
        .map((partner: IPartner) => ({
          value: `${partner.name}/*?${partner.uuid}`,
          label: partner.name,
        }))
        .sort((a, b) =>
          a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
        );
    }
  }, [partners, subscriber]);

  const authOptions = useMemo(() => {
    return listAuths
      .map((auth) => ({
        value: `${auth.label}/*?${auth.value}`,
        label: auth.label,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [listAuths]);

  const sendsOptions = useMemo(() => {
    return listSends
      .map((send) => ({
        value: `${send.label}/*?${send.value}`,
        label: send.label,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [listSends]);

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  const { id } = useParams();

  const fetchSubscriber = useCallback(async () => {
    if (id) {
      try {
        const { data } = await getSubscriberAPI(id);
        setSubscriber(data.payload);
      } catch (e) {
        console.log(e);
      }
    }
  }, [setSubscriber, id]);

  const sendSubscriberInvolved = useCallback(async () => {
    setLoading(true);
    if (id) {
      const newInvolved: IBodySubscriberInvolved = {
        involved_partner_uuid: values.involved_partner.split("/*?")[1],
        related_to_uuid: values.related_to.split("/*?")[1],
        relationship: values.relationship.split("/*?")[1],
        auth_type: values.auth_type.split("/*?")[1],
        send_type: values.send_type.split("/*?")[1],
        subscriber_uuid: id,
        is_signer: Boolean(values.is_signer),
        sign_weight: Number(values.sign_weight),
      };

      try {
        await postSubscriberInvolvedAPI(newInvolved);

        handleClose();
        toast.success("Envolvido adicionado com sucesso");

        await fetchSubscriber();

        setLoading(false);
      } catch (e) {
        console.log(e);
        toast.error(
          "Não foi possível adicionar o envolvido! Tente novamente mais tarde"
        );
        setLoading(false);
      }
    }
  }, [id, values, setLoading, fetchSubscriber, handleClose]);

  useEffect(() => {
    if (addInvolved) {
      const body = bodyRef.current;
      body.style.overflow = "hidden";
    } else {
      const body = bodyRef.current;
      body.style.overflow = "";
    }

    return () => {
      const body = bodyRef.current;
      body.style.overflow = "";
    };
  }, [addInvolved]);

  return (
    <LateralModal
      open={addInvolved}
      title="Envolvido"
      header
      toggleModal={() => handleClose()}
    >
      <Container>
        <div style={{ gridColumn: "span 6", zIndex: zIndex }}>
          <InputSelect
            name="involved_partner"
            id="involved_partner"
            kind="default"
            size="large"
            value=""
            label="Envolvido"
            onChange={(e) => handleSelect("involved_partner", e)}
            options={partnersOptions}
            placeholder={
              values.involved_partner !== ""
                ? truncatedText(values?.involved_partner.split("/*?")[0])
                : "Selecione uma opção"
            }
          />
        </div>

        <div style={{ gridColumn: "span 6", zIndex: zIndex }}>
          <InputSelect
            name="related_to"
            id="related_to"
            kind="default"
            size="large"
            value=""
            label="Relacionado à"
            placeholder={
              values.related_to !== ""
                ? truncatedText(values?.related_to.split("/*?")[0])
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("related_to", e)}
            options={partnersRelatedToOptions}
          />
        </div>

        <div style={{ gridColumn: "span 12" }}>
          <InputSelect
            name="relationship"
            kind="default"
            value=""
            size="large"
            options={optionsRelationship}
            label="Tipo de relação"
            placeholder={
              values.relationship !== ""
                ? truncatedText(values?.relationship.split("/*?")[0])
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("relationship", e)}
          />
        </div>

        <div style={{ gridColumn: "span 6" }}>
          <InputSelect
            name="send_type"
            kind="default"
            size="large"
            label="Forma de envio"
            value=""
            placeholder={
              values.send_type !== ""
                ? truncatedText(values?.send_type.split("/*?")[0])
                : "Selecione uma opção"
            }
            options={sendsOptions}
            onChange={(e) => handleSelect("send_type", e)}
          />
        </div>

        <div style={{ gridColumn: "span 6" }}>
          <InputSelect
            name="auth_type"
            kind="default"
            size="large"
            label="Forma de validação"
            value=""
            placeholder={
              values.auth_type !== ""
                ? truncatedText(values?.auth_type.split("/*?")[0])
                : "Selecione uma opção"
            }
            options={authOptions}
            onChange={(e) => handleSelect("auth_type", e)}
          />
        </div>

        <div
          style={{
            gridColumn: "span 6",
          }}
        >
          <Label text="Assinatura" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",

              gap: "16px",
            }}
          >
            <div
              style={{
                marginTop: "10px",

                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                gap: "16px",
              }}
            >
              <div>
                <Radio
                  label="Não Signatário"
                  checked={!values.is_signer}
                  onClick={() => {
                    setFieldValue("sign_weight", "");
                    setFieldValue("is_signer", false);
                  }}
                  onChange={() => setFieldValue("is_signer", false)}
                />
              </div>
              <div>
                <Radio
                  label="Signatário"
                  checked={values.is_signer}
                  onClick={() => {
                    setFieldValue("sign_weight", "1");
                    setFieldValue("is_signer", true);
                  }}
                  onChange={() => setFieldValue("is_signer", true)}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            gridColumn: "span 6",
          }}
        >
          <Input
            max={3}
            maxLength={3}
            name="sign_weight"
            id="sign_weight"
            type="text"
            size="large"
            value={values.sign_weight}
            error={
              values.is_signer &&
              (Number(values.sign_weight) < 1 ||
                Number(values.sign_weight) > 100)
            }
            message={
              values.is_signer &&
              (Number(values.sign_weight) < 1 ||
                Number(values.sign_weight) > 100)
                ? "Digite um valor entre 1 e 100"
                : ""
            }
            disabled={!values.is_signer}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^\d]/g, "");
              const numericValue = value;
              setFieldValue("sign_weight", numericValue);
            }}
            placeholder="%"
            label="Porcentagem da assinatura (%)"
          />
        </div>

        <div style={{ height: "200px" }}></div>
        <Footer>
          {" "}
          <div style={{ gridColumn: "span 6" }}>
            <Button
              kind="ghost"
              size="large"
              onClick={() => handleClose()}
              label="Cancelar"
              icon={CloseIcon}
            />
          </div>
          <div style={{ gridColumn: "span 6" }}>
            <Button
              kind="primary"
              size="large"
              icon={SaveIcon}
              onClick={() => sendSubscriberInvolved()}
              label="Adicionar envolvido"
              disabled={
                areValuesEmptyOrFalse(values) ||
                (values.is_signer &&
                  (Number(values.sign_weight) < 1 ||
                    Number(values.sign_weight) > 100))
              }
            />
          </div>
        </Footer>
      </Container>
    </LateralModal>
  );
};

export default NewInvolved;
