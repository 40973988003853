import { Margin } from "prosperita-dumbo-react";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppsIcon from "~/assets/Apps.png";
import CloseIcon from "~/assets/Close.png";

type UserMenuProps = {
  options: { icon: string; label: string; onClick: () => void }[];
};

const UserDropdown: React.FC<UserMenuProps> = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Fecha o dropdown ao clicar fora
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);
  return (
    <Container ref={dropdownRef}>
      <Button open={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <span>
          {isOpen ? (
            <img src={CloseIcon} />
          ) : (
            <img style={{ height: "25px" }} src={AppsIcon} />
          )}
        </span>
      </Button>
      {isOpen && (
        <Dropdown>
          {options.map((option, index) => (
            <DropdownItem
              key={index}
              onClick={() => {
                option.onClick();
                setIsOpen(!isOpen);
              }}
            >
              <img
                style={{ height: "28px" }}
                src={option.icon}
                alt={option.label}
              />
              <Margin top={15} />
              {option.label}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Container>
  );
};

export default UserDropdown;

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const Button = styled.div<{ open: boolean }>`
  background: ${({ open }) => (open ? "white" : "transparent")};
  color: white;
  border: none;
  height: 60px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  background: white;
  color: black;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  width: 368px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  z-index: 2;
`;

const DropdownItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  max-width: 95px;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
`;
