import {
  Breadcrumb,
  Button,
  TableBasic,
  Tooltip,
  Tab,
  TabPane,
} from "prosperita-dumbo-react";
import Margin from "~/components/Margin";
import styled from "styled-components";
import ViewIcon from "~/assets/View.png";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import editIcon from "~/assets/Edit.png";

import addIcon from "~/assets/Addd.png";
import {
  formatCPFCNPJ,
  formatPhoneNumber,
  getLabelByValue,
} from "~/services/utils";
import { format } from "date-fns";

import useLoading from "~/hooks/useLoading";

import {
  getPartnerDocumentsAPI,
  getPartnersDetailsAPI,
} from "~/services/api/partner";
import usePartner from "~/hooks/usePartner";
import { getBankAccountsAPI, IBankAccount } from "~/services/api/bankAccount";
import AddBankAccount from "./AddBankAccount";
import {
  bussinesSectorOptions,
  entityOptions,
  genderOptions,
  maritalStatusOptions,
  residenceOptions,
} from "~/utils/options";
import EditBankAccount from "./EditBankAccount";
import { IDocumentDataPartner } from "~/types";
import NewDocument from "../NewContact/NewDocument";
import lockIcon from "~/assets/lock.png";

// import { getPartnerPersonAPI, getPartnersAPI } from "~/services/api/partner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
`;

const TitleContainer = styled.div`
  background-color: #f4f4f4;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 32px;
`;

const TitleBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  gap: 16px;
`;

const Title = styled.h1`
  font-family: IBM Plex Sans;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Content = styled.div`
  width: 100%;
  padding: 24px 32px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const titlesColumnBankAccounts = ["Instituição", "Agência", "Conta", ""];
const titlesColumnDocuments = ["Tipo de documento", ""];

const ContactsDetails = () => {
  const { uuid } = useParams();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const {
    setPartner,
    partner,
    bankAccounts,
    setBankAccounts,
    setAddBankAccounts,
    setDocuments,
    setAddDocument,
    documents,
    setBankAccountData,
  } = usePartner();

  const canReadContacts = useMemo(() => true, []);
  const canEditContacts = useMemo(() => true, []);

  const listDocuments = useMemo(() => {
    return [
      { label: "Contrato Social", value: "social_contract" },
      { label: "Comprovante de endereço", value: "proof_residence" },
      { label: "RG (Frente)", value: "front_document" },
      { label: "RG (Verso)", value: "back_document" },
      { label: "Imagem", value: "image" },
      { label: "Selfie", value: "selfie" },
      { label: "CPF", value: "cpf" },
      { label: "CNPJ", value: "cnpj" },
      { label: "CNH (Frente)", value: "cnh_front" },
      { label: "CNH (Verso)", value: "cnh_back" },
      { label: "Documento de Identidade (RG)", value: "identity_document" },
      { label: "Outro", value: "other" },
    ];
  }, []);

  const dataTableDocuments = useMemo(() => {
    return documents?.map((document: IDocumentDataPartner) => ({
      "Tipo de documento": getLabelByValue(document.type, listDocuments),
      "": (
        <div
          style={{
            position: "relative",
            top: "-8px",
            height: "20px",
            width: "50px",
          }}
        >
          {document.file_url || document.file ? (
            <Tooltip align="top" size="large" label={"Visualizar"}>
              <Button
                kind="ghost"
                size="small"
                icon={ViewIcon}
                label=""
                onClick={() =>
                  document.file_url
                    ? window.open(document.file_url, "_blank")
                    : document.file && window.open(document.file, "_blank")
                }
              />
            </Tooltip>
          ) : (
            <Button
              kind="ghost"
              size="small"
              icon={ViewIcon}
              disabled
              label=""
              onClick={() =>
                document.file_url
                  ? window.open(document.file_url, "_blank")
                  : document.file && window.open(document.file, "_blank")
              }
            />
          )}
        </div>
      ),
    }));
  }, [documents, listDocuments]);

  const bankAccountsData = bankAccounts?.map((bankAccount: IBankAccount) => {
    return {
      Instituição: `${bankAccount.bank_code} - ${bankAccount.bank_name}`,
      Agência: bankAccount.agency_digit
        ? `${bankAccount.agency}-${bankAccount.agency_digit}`
        : bankAccount.agency,
      Conta: `${bankAccount.account_number}-${bankAccount.account_number_digit}`,
      actionsColumn: canEditContacts ? (
        <Tooltip align="top" size="medium" label="Editar">
          <div
            style={{
              position: "relative",
              top: "-8px",
              height: "20px",
            }}
          >
            <Button
              kind="ghost"
              size="small"
              icon={editIcon}
              label=""
              onClick={() => setBankAccountData(bankAccount)}
            />
          </div>
        </Tooltip>
      ) : (
        <div></div>
      ),
    };
  });

  const fetchDocuments = useCallback(async () => {
    try {
      const { data } = await getPartnerDocumentsAPI(uuid as string);

      setDocuments(data.payload);

      setLoading(false);
    } catch (e) {
      console.log(e);

      setLoading(false);
    }
  }, [setLoading, uuid, setDocuments]);

  const fetchBankAccounts = useCallback(async () => {
    try {
      if (uuid) {
        const { data } = await getBankAccountsAPI(uuid);

        setBankAccounts(data.payload.reverse());
      } else {
        console.error("UUID is undefined");
      }
    } catch (err) {
      console.error("Erro ao buscar detalhes da operação:", err);
    }
  }, [uuid, setBankAccounts]);

  const fetchPartnerDetails = useCallback(async () => {
    setPartner(null);
    setLoading(true);
    try {
      if (uuid) {
        const { data } = await getPartnersDetailsAPI(uuid);

        setPartner(data.payload);

        await fetchBankAccounts();

        setLoading(false);
      } else {
        console.error("UUID is undefined");
        setLoading(false);
      }
    } catch (err) {
      console.error("Erro ao buscar detalhes da operação:", err);
      setLoading(false);
    }
  }, [uuid, setLoading, setPartner, fetchBankAccounts]);

  useEffect(() => {
    fetchDocuments();
    fetchPartnerDetails();
  }, [fetchPartnerDetails, fetchDocuments]);

  interface IRenderContent {
    content?: JSX.Element;
    tab: string;
    label: string;
    size?: string;
    position?: "left" | "center" | "right";
  }

  const [tab, setTab] = useState<string>("bank_accounts");

  const renderContent: IRenderContent[] = useMemo(() => {
    return [
      {
        content: (
          <div style={{ marginTop: "20px" }}>
            <TableBasic
              search
              sizeSearch="large"
              pageSizes={[5, 10, 20, 50, 100]}
              columnTitles={titlesColumnBankAccounts}
              rowData={bankAccountsData}
              width="1000px"
              buttons={[
                {
                  kind: "primary",
                  position: "right",
                  size: "large",
                  disabled: !canEditContacts,
                  label: "Adicionar conta bancária",
                  icon: addIcon,
                  onClick: () => setAddBankAccounts(true),
                },
              ]}
            />
          </div>
        ),
        tab: "bank_accounts",
        label: "Contas bancárias",
        size: "150px",
        position: "left",
      },

      {
        content: (
          <div style={{ marginTop: "20px" }}>
            <TableBasic
              search
              sizeSearch="large"
              pageSizes={[10, 20, 50, 100]}
              columnTitles={titlesColumnDocuments}
              rowData={dataTableDocuments}
              width="1000px"
              buttons={[
                {
                  kind: "primary",
                  position: "right",
                  disabled: !canEditContacts,
                  size: "large",
                  label: "Adicionar Documento",
                  icon: addIcon,
                  onClick: () => setAddDocument(true),
                },
              ]}
            />
          </div>
        ),
        tab: "documents",
        label: "Documentos",
        size: "95px",
        position: "left",
      },
    ];
  }, [
    bankAccountsData,
    canEditContacts,
    dataTableDocuments,
    setAddBankAccounts,
    setAddDocument,
  ]);

  return (
    <>
      <Container>
        <TitleContainer>
          <Breadcrumb
            crumbsOptions={[
              {
                crumb: "Contatos",
                onClick: () => navigate("/app/contacts"),
              },
              {
                crumb: "Detalhes",
              },
            ]}
            endHiddenIndex={5}
            startHiddenIndex={5}
            aria-label="Navegação de detalhes de operações"
          />
          {canReadContacts && (
            <TitleBar>
              <Title style={{ fontFamily: "sans-serif", fontSize: "25px" }}>
                {partner?.name}
              </Title>
            </TitleBar>
          )}
        </TitleContainer>

        <Content>
          {canReadContacts && (
            <>
              <h3 style={{ fontSize: "24px" }}>Dados básicos</h3>
              {/* <Margin top={10} /> */}
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  gap: "32px",
                  rowGap: "30px",
                  marginTop: "20px",
                }}
              >
                {[
                  {
                    label:
                      partner?.document_type === "company" ? "CNPJ" : "CPF",
                    value: partner?.document_number
                      ? formatCPFCNPJ(String(partner?.document_number))
                      : "-",
                  },
                  ...(partner?.document_type === "person"
                    ? [
                        {
                          label: "Data de nascimento",
                          value: partner?.birth_date
                            ? format(
                                new Date(partner?.birth_date),
                                "dd/MM/yyyy"
                              )
                            : "-",
                        },
                        {
                          label: "Nacionalidade",
                          value: partner?.nationality || "-",
                        },
                        {
                          label: "Estado civil",
                          value:
                            getLabelByValue(
                              partner?.marital_status,
                              maritalStatusOptions
                            ) || "-",
                        },
                        {
                          label: "Nome da mãe",
                          value: partner?.mother_name || "-",
                        },
                        {
                          label: "Nome do pai",
                          value: partner?.father_name || "-",
                        },
                        {
                          label: "Gênero",
                          value:
                            getLabelByValue(partner?.gender, genderOptions) ||
                            "-",
                        },
                      ]
                    : [
                        {
                          label: "Data de fundação",
                          value: partner?.foundation_date
                            ? format(
                                new Date(partner?.foundation_date),
                                "dd/MM/yyyy"
                              )
                            : "-",
                        },
                        {
                          label: "CNAE",
                          value: partner?.cnae || "-",
                        },
                        {
                          label: "Natureza jurídica",
                          value:
                            (partner?.legal_nature &&
                              getLabelByValue(
                                partner?.legal_nature,
                                entityOptions
                              )) ||
                            "-",
                        },
                        {
                          label: "Inscrição estadual",
                          value: partner?.state_registration || "-",
                        },

                        {
                          label: "Setor de atuação",
                          value:
                            (partner?.business_sector &&
                              getLabelByValue(
                                partner?.business_sector,
                                bussinesSectorOptions
                              )) ||
                            "-",
                        },
                        {
                          label: "Site",
                          value: partner?.website || "-",
                        },
                      ]),

                  {
                    label: "Email",
                    value: `${
                      partner?.contact?.email ? partner.contact.email : "-"
                    }`,
                  },

                  {
                    label: "Telefone",
                    value: ` ${partner?.contact?.phone ? formatPhoneNumber(partner.contact.phone) : "-"}`,
                  },
                ].map((item, index) => (
                  <div key={index}>
                    <p style={{ color: "#525252" }}>{item.label}</p>
                    <h4>{item.value}</h4>
                  </div>
                ))}
              </div>
              <Margin mt={40} />
              {partner?.address && (
                <h3 style={{ fontSize: "24px" }}>Endereço</h3>
              )}
              {/* <Margin top={10} /> */}
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  gap: "32px",
                  rowGap: "30px",
                  marginTop: "20px",
                }}
              >
                {[
                  ...(partner?.address
                    ? [
                        {
                          label: "Logradouro",
                          value: partner?.address?.street || "-",
                        },
                        {
                          label: "Número",
                          value: partner?.address?.number || "-",
                        },
                        {
                          label: "Complemento",
                          value: partner?.address?.additional_info || "-",
                        },
                        {
                          label: "Bairro",
                          value: partner?.address?.district || "-",
                        },
                        {
                          label: "Cidade",
                          value: partner?.address?.city || "-",
                        },
                        {
                          label: "Estado",
                          value: partner?.address?.state || "-",
                        },
                        {
                          label: "CEP",
                          value: partner?.address?.zip_code || "-",
                        },
                        {
                          label: "Tipo de endereço",
                          value:
                            getLabelByValue(
                              partner?.address.type,
                              residenceOptions
                            ) || "-",
                        },
                      ]
                    : []),
                ].map((item, index) => (
                  <div key={index}>
                    <p style={{ color: "#525252" }}>{item.label}</p>
                    <h4>{item.value}</h4>
                  </div>
                ))}
              </div>
              <Margin mb={50} />
              <Tab width="1000px">
                {renderContent.map((list) => (
                  <TabPane
                    isActive={list.tab === tab}
                    width={list.size}
                    position={list.position}
                    bordercolor="rgb(10, 74, 135)"
                    onClick={() => setTab(list.tab)}
                    label={list.label}
                    disabled={list.tab === "consigned"}
                  >
                    <div
                      style={{
                        marginBottom: "58px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        paddingTop: "30px",
                        width: "100%",
                      }}
                    >
                      {list.content}
                    </div>
                  </TabPane>
                ))}
              </Tab>
              <Margin mt={50} />
            </>
          )}
          {!canReadContacts && (
            <div>
              <Margin mt={70} />
              <TagLup
                src={lockIcon}
                aria-label="Ícone indicando não há permissão para visualizar as operações"
              />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para ver os contatos
                <br /> contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          )}
        </Content>
        <div style={{ zIndex: "999" }}>
          <AddBankAccount />
          <EditBankAccount />
          <NewDocument />
        </div>
      </Container>
    </>
  );
};

export default ContactsDetails;
