import {
  Breadcrumb,
  Button,
  Margin,
  TableBasic,
  Tooltip,
  Tag,
} from "prosperita-dumbo-react";
import styled from "styled-components";

import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@carbon/react";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import trashCanIcon from "~/assets/Trash-can.png";
// import editIcon from "~/assets/Edit.png";
import addIcon from "~/assets/Addd.png";
import ViewIcon from "~/assets/View.png";

import {
  formatCPFCNPJ,
  formatNumberToCurrencyWithoutSimbol,
  getLabelByValue,
} from "~/services/utils";
import { format, parseISO } from "date-fns";
import useOperation from "~/hooks/useOperation";
import useLoading from "~/hooks/useLoading";
import { getSubscriberAPI } from "~/services/api/subscriber";
import { InvolvedData } from "~/types";
import NewInvolved from "./NewInvolved";
import NewDocument from "./NewDocument";
import { PropsTag } from "prosperita-dumbo-react/dist/types/Components/Tag";
import EditInvolved from "../../Operations/OperationsDetails/EditInvolved";
import DeleteInvolved from "../../Operations/OperationsDetails/DeleteInvolved";
import { getPartnersListAPI } from "~/services/api/partner";
import usePartner from "~/hooks/usePartner";
import { getOperationDetailAPI } from "~/services/api/operations";
import DeleteDocument from "../../Operations/OperationsDetails/DeleteDocument";
import {
  ICreateDocumentBody,
  postCreateDocument,
  postSendDocumentToSignature,
} from "~/services/api/signature";

// import { getPartnerPersonAPI, getPartnersAPI } from "~/services/api/partner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
`;

const TitleContainer = styled.div`
  background-color: #f4f4f4;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 32px;
`;

const TitleBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  gap: 16px;
`;

const Title = styled.h1`
  font-family: IBM Plex Sans;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Content = styled.div`
  width: 100%;
  padding: 24px 32px;
`;

const statusOperations: { [key: string]: string } = {
  processing: "Processando",
  denied: "Negado",
  opened: "Aberto",
  expired: "Removido",
  signing: "Assinando",
  confirmed: "Confirmado",
  finished: "Finalizado",
  canceled: "Cancelado",
  error: "Erro",
};

const statusOperationsTagColor: { [key: string]: PropsTag["type"] } = {
  processing: "gray",
  denied: "red",
  opened: "gray",
  expired: "red",
  signing: "blue",
  confirmed: "green",
  finished: "green",
  canceled: "red",
  error: "red",
};

const tabs = [
  {
    label: "Envolvidos",
    value: "detalhes",
  },
  {
    label: "Documentos",
    value: "parcelas",
  },
];

const titlesColumnDocuments = ["Relacionado à", "Tipo", ""];

const titlesColumnInvolveds = [
  "Nome",
  "Relacionado à",
  "Tipo de relação",
  "Assinante",
  "% da assinatura",
  "Forma de envio",
  "Forma de autenticação",
  "",
];

const SubscriberDetails = () => {
  const { uuid, id } = useParams();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { setPartners } = usePartner();

  const {
    setSubscriber,
    subscriber,
    setAddInvolved,
    setAddDocument,
    setOperationDetails,
    setDeleteDocumentData,
    setDeleteInvolvedData,
    // setEditInvolvedData,
  } = useOperation();

  const listRelationship = useMemo(() => {
    return [
      { label: "Correntista", value: "account_holder" },
      { label: "Administrador", value: "administrator" },
      { label: "Procurador", value: "attorney" },
      { label: "Comodatário", value: "borrower" },
      { label: "Responsável Solidário", value: "co_responsible" },
      { label: "Caucionante", value: "collateral_provider" },
      { label: "Anuente", value: "consenting" },
      { label: "Interveniente Anuente", value: "consenting_intervenor" },
      { label: "Credor", value: "creditor" },
      { label: "Devedor", value: "debtor" },
      { label: "Endossante", value: "endorser" },
      { label: "Endossatário", value: "endorsee" },
      { label: "Avalista / Fiador", value: "guarantor" },
      { label: "Cônjuge do Fiador", value: "guarantor_spouse" },
      { label: "Intermediário", value: "intermediary" },
      { label: "Interveniente Garantidor", value: "intervening_guarantor" },
      { label: "Emitente", value: "issuer" },
      { label: "Devedor Solidário", value: "joint_debtor" },
      { label: "Responsável Legal", value: "legal_guardian" },
      { label: "Representante Legal", value: "legal_representative" },
      { label: "Mutuante", value: "lender" },
      { label: "Sócio", value: "partner" },
      { label: "Presidente", value: "president" },
      { label: "Cedente", value: "transferor" },
      { label: "Cessionário", value: "transferee" },
      { label: "Testemunha", value: "witness" },
    ];
  }, []);

  const listAuths = useMemo(() => {
    return [
      { label: "Email", value: "email" },
      { label: "Biometria", value: "biometric" },
      { label: "ICP Brasil", value: "icp_brasil" },
    ];
  }, []);

  const listSends = useMemo(() => {
    return [
      { label: "Email", value: "email" },
      { label: "SMS", value: "sms" },
      { label: "Whatsapp", value: "whatsapp" },
    ];
  }, []);

  const listDocuments = useMemo(() => {
    return [
      { label: "Documento", value: "document" },
      { label: "Autorização", value: "authorization" },
      { label: "Carteira de Motorista", value: "drivers_license" },
      { label: "Carteira de Trabalho", value: "work_card" },
      {
        label: "Comprovante de Residência da Empresa",
        value: "residence_proof_company",
      },
      {
        label: "Comprovante de Residência dos Sócios",
        value: "residence_proof_partners",
      },
      { label: "Rascunho", value: "draft" },
      { label: "Contrato Social", value: "social_contract" },
      { label: "Número de Registro", value: "registration_number" },
      {
        label: "Documento de Identificação com Foto",
        value: "identification_document_with_photo",
      },
      { label: "Extrato Bancário", value: "bank_statement" },
      { label: "Faturamento Gerencial", value: "managerial_billing" },
      { label: "Formulário de Cadastro", value: "registration_form" },
      { label: "Outros", value: "others" },
      { label: "Selfie", value: "selfie" },
      {
        label: "Selfie com Documento de Identidade",
        value: "selfie_with_identity_card",
      },
      { label: "Última Declaração", value: "last_declaration" },
      { label: "Contrato Assinado", value: "signed_contract" },
      {
        label: "Contrato com Selo de Assinatura",
        value: "signing_stamp_contract",
      },
      { label: "Relatório de Crédito", value: "credit_report" },
      { label: "Cessão", value: "assignment" },
      { label: "Adição", value: "addition" },
      { label: "Cessão Fiduciária", value: "fiduciary_assignment" },
      { label: "P7S", value: "p7s" },
      { label: "Arquivos Compactados", value: "ziped_files" },
      { label: "CNAB", value: "cnab" },
      { label: "Nota Fiscal", value: "invoice" },
      { label: "Ata de Eleição", value: "minutes_of_election" },
      { label: "Procuração", value: "procuration" },
      { label: "Termo de Posse", value: "term_of_ownership" },
      { label: "Estatuto Social", value: "bylaws" },
      { label: "Carteira de Identidade", value: "identity_card" },
      { label: "Garantia", value: "collateral" },
      { label: "Regulamento", value: "regulation" },
      { label: "Saldo", value: "balance" },
      { label: "Balanço Patrimonial", value: "balance_sheet" },
      { label: "PLDFT", value: "pldft" },
      { label: "Imposto de Renda", value: "income_tax" },
      { label: "DRE", value: "dre" },
      { label: "Recibo", value: "receipt" },
      { label: "Cartão da Empresa", value: "company_card" },
      { label: "Relatório de Cessão", value: "assignment_report" },
      {
        label: "Registro Nacional de Estrangeiros",
        value: "national_registry_of_foreigners",
      },
      {
        label: "Documento Nacional de Identidade",
        value: "national_identity_card",
      },
      {
        label: "Carteira de Registro Regional",
        value: "regional_registration_card",
      },
      { label: "Passaporte", value: "passport" },
      { label: "Contra-cheque", value: "paycheck" },
    ];
  }, []);

  const sendCreateDocumentAndSendToSignature =
    useCallback(async (): Promise<void> => {
      const payload: ICreateDocumentBody = {
        subscriber_uuid: "521ca4fa-a21d-4d63-bb25-f929656213f9",
        template_uuid: "d93be2db-d97e-4fa5-adb2-bd641d7d5544",
      };

      try {
        const { data } = await postCreateDocument(payload);
        await postSendDocumentToSignature(data.payload.uuid);
      } catch (e) {
        console.error("Erro ao buscar operações:", e);
      }
    }, []);

  const dataTableDocuments = subscriber?.documents?.map((document) => {
    const doc = document as {
      related_to: string;
      type: string;
      file: string;
      uuid: string;
    };

    return {
      "Relacionado à": doc?.related_to,
      Tipo: getLabelByValue(doc.type, listDocuments),
      "": (
        <div style={{ display: "flex" }}>
          <Tooltip size="medium" align="top" label="Visualizar">
            <Button
              kind="ghost"
              size="small"
              icon={ViewIcon}
              onClick={() => window.open(doc?.file, "_blank")}
            />
          </Tooltip>{" "}
          {subscriber.status !== "opened" &&
          subscriber.status !== "processing" ? (
            <Tooltip
              size="medium"
              align="top"
              label="Não é possível remover documentos"
            >
              <Button
                kind="ghost"
                size="small"
                disabled={true}
                icon={trashCanIcon}
              />
            </Tooltip>
          ) : (
            <Tooltip size="medium" align="top" label="Remover">
              <Button
                kind="ghost"
                size="small"
                icon={trashCanIcon}
                onClick={() => setDeleteDocumentData(doc)}
              />
            </Tooltip>
          )}
        </div>
      ),
    };
  });

  const dataTableInvolveds = subscriber?.involveds?.map(
    (involved: InvolvedData) => {
      return {
        Nome: involved.name,
        "Relacionado à": involved.related_to,
        "Tipo de relação": `${getLabelByValue(involved.relationship, listRelationship)}`,
        Assinante: involved.is_signer ? "Sim" : "Não",
        "% da assinatura": involved.is_signer
          ? `${involved.sign_weight} %`
          : "-",
        "Forma de envio": `${getLabelByValue(involved.send_type, listSends)}`,
        "Forma de autenticação": `${getLabelByValue(involved.auth_type, listAuths)}`,

        actionsColumn: (
          <div style={{ display: "flex", gap: "8px" }}>
            <>
              {/* <Button
                kind="ghost"
                size="small"
                icon={editIcon}
                onClick={() => setEditInvolvedData(involved)}
              /> */}

              {subscriber.status !== "opened" &&
              subscriber.status !== "processing" ? (
                <Tooltip
                  size="medium"
                  align="top"
                  label="Não é possível remover envolvidos"
                >
                  <Button
                    kind="ghost"
                    size="small"
                    disabled={true}
                    icon={trashCanIcon}
                    onClick={() => setDeleteInvolvedData(involved)}
                  />
                </Tooltip>
              ) : (
                <Tooltip size="medium" align="top" label="Remover">
                  <Button
                    kind="ghost"
                    size="small"
                    icon={trashCanIcon}
                    onClick={() => setDeleteInvolvedData(involved)}
                  />
                </Tooltip>
              )}
            </>
          </div>
        ),
      };
    }
  );

  // const fetchPartnerDetails = useCallback(async () => {
  //   try {
  //     if (id) {
  //       const { data } = await getPartnerPersonAPI(id);

  //       console.log(data);
  //     } else {
  //       console.error("UUID is undefined");
  //     }
  //   } catch (err) {
  //     console.error("Erro ao buscar detalhes da operação:", err);
  //   }
  // }, [id]);

  const fetchPartners = useCallback(async (): Promise<void> => {
    try {
      const { data } = await getPartnersListAPI();

      setPartners(data.payload);
    } catch (e) {
      console.error("Erro ao buscar contatos:", e);
    }
  }, [setPartners]);

  const fetchSubscriberDetails = useCallback(async () => {
    setLoading(true);
    try {
      if (id) {
        const { data } = await getSubscriberAPI(id);

        setSubscriber(data.payload);
        // await fetchPartnerDetails();
        setLoading(false);
      } else {
        console.error("UUID is undefined");
        setLoading(false);
      }
    } catch (err) {
      console.error("Erro ao buscar detalhes da operação:", err);
      setLoading(false);
    }
  }, [id, setLoading, setSubscriber]);

  const fetchOperationDetails = useCallback(async () => {
    try {
      if (uuid) {
        const { data } = await getOperationDetailAPI(uuid);

        setOperationDetails(data.payload);
        setLoading(false);
      } else {
        console.error("UUID is undefined");
        setLoading(false);
      }
    } catch (err) {
      console.error("Erro ao buscar detalhes da operação:", err);
      setLoading(false);
    }
  }, [uuid, setOperationDetails, setLoading]);

  useEffect(() => {
    fetchOperationDetails();
    sendCreateDocumentAndSendToSignature();
    fetchSubscriberDetails();
    fetchPartners();
  }, [
    fetchSubscriberDetails,
    fetchPartners,
    fetchOperationDetails,
    sendCreateDocumentAndSendToSignature,
  ]);

  return (
    <>
      <Container>
        <TitleContainer>
          <Breadcrumb
            crumbsOptions={[
              {
                crumb: "Operações",
                onClick: () => navigate("/app/credit/operations"),
              },
              {
                crumb: "Detalhes",
                onClick: () =>
                  navigate(`/app/credit/operations/details/${uuid}`),
              },
              { crumb: "Detalhes do subscritor" },
            ]}
            endHiddenIndex={5}
            startHiddenIndex={5}
            aria-label="Navegação de detalhes de operações"
          />
          <TitleBar>
            <Title style={{ fontFamily: "sans-serif", fontSize: "25px" }}>
              {subscriber?.partner.name}
            </Title>
          </TitleBar>
        </TitleContainer>
        <Content>
          {/* <h4>Detalhes do subscritor</h4> */}
          {/* <Margin top={10} /> */}
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "32px",
              rowGap: "30px",
              marginTop: "20px",
            }}
          >
            {[
              {
                label: "Status",
                value: (
                  <div style={{ maxWidth: "150px" }}>
                    <Tag
                      type={
                        statusOperationsTagColor[String(subscriber?.status)]
                      }
                      size="medium"
                      position="center"
                    >
                      <div style={{ padding: "2px" }}>
                        {statusOperations[String(subscriber?.status)]}
                      </div>
                    </Tag>
                  </div>
                ),
              },
              {
                label:
                  subscriber?.partner?.document_type === "company"
                    ? "CNPJ"
                    : "CPF",
                value: formatCPFCNPJ(
                  String(subscriber?.partner.document_number)
                ),
              },
              {
                label:
                  subscriber?.partner?.document_type === "company"
                    ? "Data de fundação"
                    : "Data de nascimento",
                value:
                  subscriber?.partner?.document_type === "company"
                    ? subscriber?.partner?.foundation_date
                      ? format(
                          parseISO(subscriber.partner.foundation_date),
                          "dd/MM/yyyy"
                        )
                      : "Data não disponível"
                    : subscriber?.partner?.birth_date
                      ? format(
                          parseISO(subscriber.partner.birth_date),
                          "dd/MM/yyyy"
                        )
                      : "Data não disponível",
              },

              {
                label: "Data da subscrição",
                value:
                  subscriber?.subscription_date &&
                  format(
                    parseISO(String(subscriber?.subscription_date)),
                    "dd/MM/yyyy"
                  ),
              },
              {
                label: "Quantidade adquirida",
                value: subscriber?.quantity_of_units_acquired,
              },
              {
                label: "Valor subscrito",
                value: ` ${subscriber?.unit_amount_acquired ? `R$${formatNumberToCurrencyWithoutSimbol(subscriber?.unit_amount_acquired)}` : "-"}`,
              },
              {
                label: "ID da operação",
                value: uuid,
              },
            ].map((item, index) => (
              <div key={index}>
                <p style={{ color: "#525252" }}>{item.label}</p>
                <h4>{item.value}</h4>
              </div>
            ))}
          </div>
          <Margin top={50} />
          <Tabs>
            <TabList aria-label="List of tabs">
              {tabs.map((tab, index) => (
                <Tab key={index}>{tab.label}</Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel key={"index"}>
                <div>
                  <TableBasic
                    search
                    sizeSearch="large"
                    pageSizes={[10, 5, 20, 50, 100]}
                    columnTitles={titlesColumnInvolveds}
                    rowData={dataTableInvolveds || []}
                    width="1000px"
                    buttons={[
                      {
                        kind: "primary",
                        size: "large",
                        position: "right",
                        disabled:
                          subscriber?.status !== "opened" &&
                          subscriber?.status !== "processing",
                        label: "Adicionar envolvido",
                        onClick: () => setAddInvolved(true),
                        icon: addIcon,
                      },
                    ]}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <TableBasic
                    search
                    sizeSearch="large"
                    pageSizes={[10, 5, 20, 50, 100]}
                    columnTitles={titlesColumnDocuments}
                    rowData={dataTableDocuments || []}
                    width="1000px"
                    buttons={[
                      {
                        kind: "primary",
                        size: "large",
                        disabled:
                          subscriber?.status !== "opened" &&
                          subscriber?.status !== "processing",
                        position: "right",
                        label: "Adicionar documento",
                        onClick: () => setAddDocument(true),
                        icon: addIcon,
                      },
                    ]}
                  />
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Content>
        <div style={{ zIndex: "999" }}>
          <NewInvolved />
          <NewDocument />
          <EditInvolved />
          <DeleteDocument />
          <DeleteInvolved />
        </div>
      </Container>
    </>
  );
};

export default SubscriberDetails;
