import { useFormik } from "formik";
import {
  Button,
  Input,
  InputCurrency,
  InputSelect,
  Modal,
  Table,
  Tile,
  Tooltip,
} from "prosperita-dumbo-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useOperation from "~/hooks/useOperation";
import EditIcon from "~/assets/Edit.png";
import {
  getCreditProductAPI,
  getCreditProductDetailsAPI,
  ICreditProducts,
  IProductDetails,
} from "~/services/api/creditProduct";
import {
  getSimulateOperationAPI,
  Installment,
  ISimulateOperationBody,
  ISimulateOperationInstallmentPatch,
  patchSimulateOperationAPI,
} from "~/services/api/operations";
import {
  convertStringCurrencyToNumber,
  convertStringToNumber,
  formatNumberToCurrencyWithoutSimbol,
  getLabelByValue,
} from "~/services/utils";
import ArrowIcon from "~/assets/arrow-right-_1_.png";
import { useNavigate } from "react-router-dom";
import Margin from "~/components/Margin";
import {
  endOfDay,
  format,
  isAfter,
  isBefore,
  isEqual,
  isPast,
  parseISO,
} from "date-fns";
import { toast } from "react-toastify";
import useLoading from "~/hooks/useLoading";

type IInitialValues = {
  nickname: string;
  product_uuid: string;
  calculation_type: string;
  requested_amount: string;
  duration: string;
  calculation_base: string;
  unit_amount: string;
  release_date: string;
  due_date: string;
  interest_rate_monthly: string;
  interest_periodicity: string;
  amortization_grace_period: string;
  interest_grace_period: string;
  commission: string;
  indexer: string;
  indexer_rate: string;
  birthday_date_type: string;
  birthday_date_day: string;
  amortization_amount: string | number;
  due_date_installment: string;
  interest: string | number;
  number: number;
  outstanding_amount: string | number;
  pmt: string | number;
};

const Operation = () => {
  const {
    setCreditProducts,
    creditProducts,
    setPayloadSimulateOperation,
    setTab,
    setSimulateResult,
    simulateResult,
    setInstallmentData,
    installmentData,
    editInstallment,
    setEditInstallment,
  } = useOperation();

  const [previousInstallment, setPreviousInstallment] =
    useState<Installment | null>(null);

  const navigate = useNavigate();

  const { setLoading } = useLoading();

  const initialValues = useMemo(() => {
    const storedOperation = localStorage.getItem("@:simulate_operation");
    const defaultValues: IInitialValues = {
      nickname: "",
      product_uuid: "",
      calculation_type: "",
      requested_amount: "10.000,00",
      duration: "",
      calculation_base: "",
      birthday_date_day: "1",
      birthday_date_type: "",
      release_date: "",
      due_date: "",
      interest_rate_monthly: "",
      interest_periodicity: "",
      amortization_grace_period: "",
      interest_grace_period: "",
      commission: "",
      unit_amount: "1.000,00",
      indexer: "",
      indexer_rate: "100",
      amortization_amount: 0,
      due_date_installment: "",
      interest: "",
      number: 0,
      outstanding_amount: "",
      pmt: "",
    };

    if (storedOperation) {
      try {
        const parsedOperation = JSON.parse(storedOperation);
        return {
          ...defaultValues,
          nickname: parsedOperation.nickname,
          product_uuid: parsedOperation.product_uuid || "",
          calculation_type: parsedOperation.calculation_type || "",
          birthday_date_day: String(parsedOperation.birthday_date_day) || "1",
          birthday_date_type: parsedOperation.birthday_date_type || "",
          requested_amount: `${formatNumberToCurrencyWithoutSimbol(parsedOperation.requested_amount)}`,
          unit_value:
            parsedOperation.unit_value < 100
              ? `${formatNumberToCurrencyWithoutSimbol(1000)}`
              : `${formatNumberToCurrencyWithoutSimbol(parsedOperation.unit_amount)}`,
          duration: parsedOperation.duration || "",
          calculation_base: parsedOperation.calculation_base || "",
          release_date: parsedOperation.release_date || "",
          due_date: parsedOperation.due_date || "",
          interest_rate_monthly:
            String(parsedOperation?.taxes_settings?.interest_rate_monthly) ||
            "",
          interest_periodicity:
            String(parsedOperation?.taxes_settings?.interest_periodicity) || "",
          amortization_grace_period:
            String(
              parsedOperation?.taxes_settings?.amortization_grace_period
            ) || "",
          interest_grace_period:
            String(parsedOperation?.taxes_settings?.interest_grace_period) ||
            "",

          commission: String(parsedOperation?.taxes_settings?.commission) || "",
          indexer: String(parsedOperation?.taxes_settings?.indexer) || "",
          indexer_rate:
            String(parsedOperation?.taxes_settings?.indexer_rate) || "100",
        };
      } catch (error) {
        console.error("Erro ao parsear o localStorage:", error);
      }
    }

    return defaultValues;
  }, []);

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const printRef = useRef<HTMLDivElement>(null);

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  const titlesColumnInstallments = [
    "Parcela",
    "Data",
    "Valor nominal",
    "Juros + indexador",
    "Amortização",
    "Preço unitário",
    "",
  ];

  const dataTableInstallments = simulateResult?.installments.map(
    (installment, index) => {
      const isFirstInstallment = index === 0;
      const previousInstallmentData =
        index > 0 ? simulateResult?.installments[index - 1] : null;
      return {
        Parcela: installment.number,
        Data: format(parseISO(installment.due_date), "dd/MM/yyyy"),
        "Valor nominal": `R$ ${formatNumberToCurrencyWithoutSimbol(
          installment.outstanding_amount
        )}`,
        "Juros + indexador": `R$ ${formatNumberToCurrencyWithoutSimbol(installment.interest)}`,
        Amortização: `R$ ${formatNumberToCurrencyWithoutSimbol(
          installment.amortization_amount
        )}`,
        "Preço unitário": `R$ ${formatNumberToCurrencyWithoutSimbol(installment.pmt)}`,
        "": isFirstInstallment ? null : (
          <div
            style={{
              display: "flex",
              position: "relative",
              left: "-60px",
              height: "8px",
            }}
          >
            <Tooltip
              align={
                simulateResult.product.amortization_type === "sac"
                  ? "top-right"
                  : "top-right"
              }
              size="large"
              label={
                simulateResult.product.amortization_type !== "sac"
                  ? "Não é possivel editar parcelas quando a amortização não é do tipo SAC"
                  : "Editar parcela"
              }
            >
              <Button
                kind="ghost"
                size="small"
                icon={EditIcon}
                // disabled={simulateResult.product.amortization_type !== "sac"}
                onClick={() => {
                  setFieldValue(
                    "interest",
                    formatNumberToCurrencyWithoutSimbol(installment.interest)
                  );
                  setFieldValue(
                    "amortization_amount",
                    formatNumberToCurrencyWithoutSimbol(
                      installment.amortization_amount
                    )
                  );
                  setFieldValue("due_date_installment", installment.due_date);
                  setPreviousInstallment(previousInstallmentData);
                  setInstallmentData(installment);
                  setEditInstallment(true);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    }
  );

  const indexesDict: { [key: string]: string } = {
    igpm: "IGPM",
    selic: "SELIC",
    ipca: "IPCA",
    cdi: "CDI",
    no_one: "Nenhum",
  };

  const indexesOptions = useMemo(() => {
    return [
      { index: "SELIC", value: "selic" },
      { index: "IGPM", value: "igpm" },
      { index: "IPCA", value: "ipca" },
      { index: "CDI", value: "cdi" },
      { index: "Nenhum", value: "no_one" },
    ].map((index) => ({
      value: index.value,
      label: index.index,
    }));
  }, []);

  const calculationBaseOptions = useMemo(() => {
    return [
      { value: "net_value", label: "Valor Líquido" },
      { value: "gross_value", label: "Valor Bruto" },
    ].sort((a, b) =>
      a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
    );
  }, []);

  const periodicityOptions = useMemo(() => {
    return [
      { label: "Diário", value: "daily" },
      { label: "Mensal", value: "monthly" },
      { label: "Anual", value: "annually" },
    ]
      .map((periodicity) => ({
        value: periodicity.value,
        label: periodicity.label,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const creditProductsOptions = useMemo(() => {
    return creditProducts
      .filter(
        (product: ICreditProducts) => product.product_type === "commercial_note"
      )
      .map((creditProduct: ICreditProducts) => ({
        value: creditProduct.uuid,
        label: creditProduct.name,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [creditProducts]);

  const calculationTypeOptions = useMemo(() => {
    return [
      { value: "360", label: "360 Dias" },
      { value: "365", label: "365 Dias" },
      { value: "252", label: "252 Dias" },
    ]
      .map((type) => ({
        value: type.value,
        label: type.label,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const birthDateOptions = useMemo(() => {
    return [
      { index: "Pagamento", value: "payment" },
      { index: "Fixa", value: "fixed" },
    ].map((index) => ({
      value: index.value,
      label: index.index,
    }));
  }, []);

  const isNotDivisible = useMemo(() => {
    const requested = convertStringCurrencyToNumber(values.requested_amount);
    const unit = convertStringCurrencyToNumber(values.unit_amount);

    if (unit === 0) return true;
    return !Number.isInteger(requested / unit);
  }, [values.requested_amount, values.unit_amount]);

  const payloadSimulateOperation: ISimulateOperationBody = useMemo(() => {
    return {
      nickname: values.nickname,
      product_uuid: values.product_uuid,
      calculation_type: values.calculation_type,
      requested_amount: convertStringToNumber(values?.requested_amount),
      duration: Number(values.duration),
      calculation_base: values.calculation_base,
      release_date: values.release_date,
      due_date: values.due_date,
      birthday_date_type: values.birthday_date_type,
      birthday_date_day: Number(values.birthday_date_day),
      unit_amount: convertStringToNumber(values?.unit_amount),
      taxes_settings: {
        interest_rate_monthly: Number(values.interest_rate_monthly),
        interest_rate: Number(values.interest_rate_monthly),
        interest_periodicity: values.interest_periodicity,
        amortization_grace_period: Number(values.amortization_grace_period),
        interest_grace_period: Number(values.interest_grace_period),
        commission: Number(values.commission),
        indexer: values.indexer,
        indexer_rate: Number(values.indexer_rate),
      },
    };
  }, [values]);

  const sendUpdateInstallment = useCallback(async () => {
    if (simulateResult && installmentData) {
      setLoading(true);
      const payload: ISimulateOperationInstallmentPatch = {
        installment_number: installmentData.number,
      };

      const amortizationAmount = convertStringCurrencyToNumber(
        values.amortization_amount as string
      );
      if (
        amortizationAmount !== installmentData.amortization_amount ||
        (amortizationAmount === 0 && installmentData.amortization_amount !== 0)
      ) {
        payload.amortization = amortizationAmount;
      }

      const interestAmount = convertStringCurrencyToNumber(
        values.interest as string
      );
      if (
        interestAmount !== installmentData.interest ||
        (interestAmount === 0 && installmentData.interest !== 0)
      ) {
        payload.interest = interestAmount;
      }

      if (values.due_date_installment !== installmentData.due_date) {
        payload.due_date = values.due_date_installment;
      }

      if (Object.keys(payload).length > 1) {
        try {
          const { data } = await patchSimulateOperationAPI(
            simulateResult.uuid,
            payload
          );

          setLoading(false);
          setEditInstallment(false);
          setSimulateResult(data.payload);
          toast.success("Parcela atualizada com sucesso!");
        } catch (e) {
          console.log(e);
          setLoading(false);
          toast.error("Não foi possível alterar a parcela!");
        }
      } else {
        setLoading(false);
        setEditInstallment(false);
      }
    }
  }, [
    simulateResult,
    installmentData,
    setLoading,
    values,
    setEditInstallment,
    setSimulateResult,
  ]);

  const fetchSimulationOnReload = useCallback(
    async (uuid: string) => {
      try {
        const { data } = await getSimulateOperationAPI(uuid);

        const simulation = data.payload;
        setSimulateResult(simulation);

        localStorage.setItem(
          "@:simulation_uuid",
          JSON.stringify({ simulation_uuid: uuid })
        );

        const payload = {
          nickname: simulation.nickname,
          product_uuid: simulation.product.uuid,
          calculation_type: simulation.calculation_type,
          requested_amount: simulation.requested_amount,
          duration: Number(simulation.duration),
          calculation_base: simulation.calculation_base,
          release_date: simulation.release_date,
          due_date: simulation.due_date,
          birthday_date_type: simulation.birthday_date_type,
          birthday_date_day: Number(simulation.birthday_date_day),
          unit_amount: simulation.unit_amount,
          taxes_settings: {
            interest_rate_monthly:
              simulation.taxes_settings.interest_rate_monthly,
            interest_rate: simulation.taxes_settings.interest_rate_monthly,
            interest_periodicity:
              simulation.taxes_settings.interest_periodicity,
            amortization_grace_period:
              simulation.taxes_settings.amortization_grace_period,
            interest_grace_period:
              simulation.taxes_settings.interest_grace_period,
            commission: simulation.taxes_settings.commission,
            indexer: simulation.taxes_settings.indexer,
            indexer_rate: simulation.taxes_settings.indexer_rate,
          },
        };

        localStorage.setItem("@:simulate_operation", JSON.stringify(payload));
      } catch (e) {
        console.log(e);
        toast.error("Não foi possível recuperar a simulação !");
      }
    },
    [setSimulateResult]
  );

  const fetchCreditProducts = useCallback(async () => {
    try {
      const { data } = await getCreditProductAPI();
      setCreditProducts(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setCreditProducts]);

  useEffect(() => {
    fetchCreditProducts();
    setSimulateResult(null);
  }, [fetchCreditProducts, setSimulateResult]);

  const [errorDueDateInstallment, setErrorDueDateInstallment] =
    useState<boolean>(false);
  const [errorDueDateToLongInstallment, setErrorDueDateToLongInstallment] =
    useState<boolean>(false);
  const [
    errorAmortizationInstallmentAmount,
    setErrorAmortizationInstallmentAmount,
  ] = useState<boolean>(false);
  const [errorInterestAmountInstallment, setErrorInterestAmountInstallment] =
    useState<boolean>(false);

  useEffect(() => {
    const currentDueDate = parseISO(values.due_date_installment);

    if (previousInstallment) {
      const previousInstallmentDueDate = parseISO(previousInstallment.due_date);

      if (isBefore(currentDueDate, previousInstallmentDueDate)) {
        setErrorDueDateInstallment(true);
      } else {
        setErrorDueDateInstallment(false);
      }

      if (
        simulateResult?.due_date &&
        (isAfter(currentDueDate, parseISO(simulateResult?.due_date)) ||
          isEqual(currentDueDate, parseISO(simulateResult?.due_date)))
      ) {
        setErrorDueDateToLongInstallment(true);
      } else {
        setErrorDueDateToLongInstallment(false);
      }

      const currentAmortizationAmount = convertStringCurrencyToNumber(
        values.amortization_amount
      );
      const previousInstallmentOutstandingAmount = Number(
        previousInstallment?.outstanding_amount || 0
      );

      if (
        currentAmortizationAmount < 0 ||
        currentAmortizationAmount > previousInstallmentOutstandingAmount
      ) {
        setErrorAmortizationInstallmentAmount(true);
      } else {
        setErrorAmortizationInstallmentAmount(false);
      }

      const currentInterestAmortizationAmount = convertStringCurrencyToNumber(
        values.interest || 0
      );
      const currentInterestAmount = Number(installmentData?.interest || 0);

      if (
        currentInterestAmortizationAmount < 0 ||
        currentInterestAmortizationAmount > currentInterestAmount
      ) {
        setErrorInterestAmountInstallment(true);
      } else {
        setErrorInterestAmountInstallment(false);
      }
    }
  }, [values, previousInstallment, installmentData, simulateResult]);

  useEffect(() => {
    const storedSimulation = localStorage.getItem("@:simulation_uuid");
    const parsedSimulation = storedSimulation
      ? JSON.parse(storedSimulation)
      : null;

    if (parsedSimulation?.simulation_uuid) {
      fetchSimulationOnReload(parsedSimulation?.simulation_uuid);
    }
  }, [fetchSimulationOnReload]);

  useEffect(() => {
    if (values.indexer === "no_one") {
      setFieldValue("indexer_rate", "0");
    } else if (values.indexer !== "no_one" && values.indexer_rate === "0") {
      setFieldValue("indexer_rate", "100");
    }
  }, [values, setFieldValue]);

  useEffect(() => {
    setPayloadSimulateOperation(payloadSimulateOperation);
    localStorage.setItem(
      "@:simulate_operation",
      JSON.stringify(payloadSimulateOperation)
    );
  }, [payloadSimulateOperation, setPayloadSimulateOperation]);

  useEffect(() => {
    setTab(0);
  }, [setTab]);

  const [productDetails, setProductDetails] = useState<IProductDetails>();

  const fetchProductDetails = useCallback(async (uuid: string) => {
    try {
      const { data } = await getCreditProductDetailsAPI(uuid);
      setProductDetails(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (values.product_uuid) {
      fetchProductDetails(values.product_uuid);
    }
  }, [values.product_uuid, fetchProductDetails]);

  const [errorInterestRate, setErrorInterestRate] = useState<boolean>(false);
  const [errorAmortizationPeriod, setErrorAmortizationPeriod] =
    useState<boolean>(false);
  const [errorCommission, setErrorCommission] = useState<boolean>(false);
  const [errorPrincipalAmount, setErrorPrincipalAmount] =
    useState<boolean>(false);

  const [errorDuration, setErrorDuration] = useState<boolean>(false);
  const [errorReleaseDate, setErrorReleaseDate] = useState<boolean>(false);
  const [errorDueDate, setErrorDueDate] = useState<boolean>(false);

  useEffect(() => {
    const interestRate = values.interest_rate_monthly?.includes(",")
      ? convertStringToNumber(values.interest_rate_monthly)
      : Number(values.interest_rate_monthly);

    const commissionRate = values.commission?.includes(",")
      ? convertStringToNumber(values.commission)
      : Number(values.commission);

    const parsedReleaseDate = parseISO(values.release_date);
    const parsedDueDate = parseISO(values.due_date);

    if (productDetails) {
      if (
        interestRate < productDetails?.min_interest_rate ||
        interestRate > productDetails?.max_interest_rate
      ) {
        setErrorInterestRate(true);
      } else {
        setErrorInterestRate(false);
      }

      if (
        Number(values.amortization_grace_period) <
          productDetails?.min_amortization_period ||
        Number(values.amortization_grace_period) >
          productDetails?.max_amortization_period
      ) {
        setErrorAmortizationPeriod(true);
      } else {
        setErrorAmortizationPeriod(false);
      }

      if (
        convertStringToNumber(values.requested_amount) >
          productDetails.max_principal_amount ||
        convertStringToNumber(values.requested_amount) <
          productDetails.min_principal_amount
      ) {
        setErrorPrincipalAmount(true);
      } else {
        setErrorPrincipalAmount(false);
      }
    }

    if (Number(values.duration) < 1) {
      setErrorDuration(true);
    } else {
      setErrorDuration(false);
    }
    if (commissionRate < 0 || commissionRate > 100) {
      setErrorCommission(true);
    } else {
      setErrorCommission(false);
    }

    if (isPast(endOfDay(parsedReleaseDate))) {
      setErrorReleaseDate(true);
    } else {
      setErrorReleaseDate(false);
    }
    if (isBefore(parsedDueDate, parsedReleaseDate)) {
      setErrorDueDate(true);
    } else {
      setErrorDueDate(false);
    }
  }, [
    values.interest_rate_monthly,
    values.amortization_grace_period,
    values.commission,
    productDetails,
    values.requested_amount,
    values.duration,
    values.release_date,
    values.due_date,
  ]);

  useEffect(() => {
    const storedSimulationUUID = localStorage.getItem("@:simulation_uuid");
    const parsedSimulationUUID = storedSimulationUUID
      ? JSON.parse(storedSimulationUUID)
      : null;

    const storedSimulation = localStorage.getItem("@:simulate_operation");
    const parsedSimulation = storedSimulation
      ? JSON.parse(storedSimulation)
      : null;

    if (productDetails && !parsedSimulationUUID?.simulation_uuid) {
      setFieldValue(
        "interest_rate_monthly",
        productDetails?.min_interest_rate !== undefined &&
          String(productDetails?.min_interest_rate)
      );

      if (
        productDetails?.min_amortization_period !== undefined &&
        parsedSimulation.amortization_grace_period === ""
      ) {
        setFieldValue(
          "amortization_grace_period",
          String(productDetails?.min_amortization_period)
        );
      }

      setFieldValue(
        "interest_grace_period",
        productDetails?.min_amortization_period !== undefined &&
          String(productDetails?.min_amortization_period)
      );
    }

    if (
      parsedSimulation.taxes_settings.indexer === null ||
      parsedSimulation.taxes_settings.indexer === "null"
    ) {
      setFieldValue("indexer", "no_one");
    }

    if (!parsedSimulation?.duration && !parsedSimulationUUID?.simulation_uuid) {
      setFieldValue("duration", "1");
    } else {
      setFieldValue("duration", String(parsedSimulation.duration));
    }

    if (
      !parsedSimulation.taxes_settings.commission &&
      !parsedSimulationUUID?.simulation_uuid
    ) {
      setFieldValue("commission", "0");
    } else {
      setFieldValue(
        "commission",
        String(parsedSimulation.taxes_settings.commission)
      );
    }

    if (
      parsedSimulation.birthday_date_type !== null ||
      parsedSimulation.birthday_date_type !== "null" ||
      parsedSimulation.birthday_date_type !== ""
    ) {
      setFieldValue("birthday_date_type", parsedSimulation.birthday_date_type);
    }
  }, [productDetails, setFieldValue]);

  const [errorBirthdayDate, setErrorBirthdayDate] = useState(false);

  useEffect(() => {
    if (values.indexer !== "igpm" && values.indexer !== "ipca") {
      setFieldValue("birthday_date_type", "");
      setFieldValue("birthday_date_day", "1");
    }

    if (values.birthday_date_type !== "fixed") {
      setFieldValue("birthday_date_day", "1");
    }

    if (
      ((values.indexer === "igpm" || values.indexer === "ipca") &&
        values.birthday_date_type === "") ||
      ((values.indexer === "igpm" || values.indexer === "ipca") &&
        values.birthday_date_type === "fixed" &&
        (values.birthday_date_day === "0" || values.birthday_date_day === ""))
    ) {
      setErrorBirthdayDate(true);
    } else {
      setErrorBirthdayDate(false);
    }
  }, [values, setFieldValue]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          gridColumn: "1 / -1",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          position: "sticky",
          top: "0",
        }}
      >
        <div>
          <Button
            kind="ghost"
            size="large"
            label="Cancelar"
            onClick={() => {
              setTab(0);
              localStorage.removeItem("@:involveds");
              localStorage.removeItem("@:simulate_operation");
              localStorage.removeItem("@:operation");
              localStorage.removeItem("@:documents");
              navigate("/app/credit/operations/");
            }}
          />
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            <Button
              kind="primary"
              size="large"
              label="Avançar"
              icon={ArrowIcon}
              onClick={() => {
                navigate(
                  "/app/credit/operations/new/commercial-note/product-info/"
                );
                setTab(1);
              }}
              disabled={
                !values.release_date ||
                isNotDivisible ||
                convertStringCurrencyToNumber(values.unit_amount) < 1 ||
                convertStringCurrencyToNumber(values.unit_amount) >
                  convertStringCurrencyToNumber(values.requested_amount) ||
                convertStringCurrencyToNumber(values.requested_amount) <
                  convertStringCurrencyToNumber(values.unit_amount) ||
                !values.amortization_grace_period ||
                !values.calculation_base ||
                errorBirthdayDate ||
                !values.calculation_type ||
                !values.due_date ||
                !values.commission ||
                errorDueDate ||
                !values.duration ||
                !values.indexer ||
                (values.indexer !== "no_one" &&
                  Number(values.indexer_rate) < 1) ||
                !values.interest_grace_period ||
                !values.interest_periodicity ||
                !values.interest_rate_monthly ||
                !values.nickname ||
                errorAmortizationPeriod ||
                !values.product_uuid ||
                errorCommission ||
                errorDuration ||
                errorInterestRate ||
                errorPrincipalAmount ||
                errorReleaseDate ||
                (values.indexer === "selic" &&
                  values.calculation_type !== "252") ||
                (values.indexer === "cdi" && values.calculation_type !== "252")
              }
            />
          </div>
        </div>
      </div>
      <Margin mb={30} />
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: "32px",
          rowGap: "30px",
          marginTop: "20px",
        }}
      >
        <div>
          {" "}
          <Input
            name="nickname"
            id="nickname"
            value={values.nickname}
            size="large"
            label="Nome"
            placeholder="Digite"
            onChange={(e) => setFieldValue("nickname", e.target.value)}
          />
        </div>
        <div>
          {" "}
          <InputSelect
            name="product_uuid"
            kind="default"
            size="small"
            label="Produto"
            options={creditProductsOptions}
            placeholder={
              values.product_uuid
                ? getLabelByValue(values.product_uuid, creditProductsOptions)
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("product_uuid", e)}
          />
        </div>
        <div>
          <InputSelect
            name="calculation_type"
            kind="default"
            size="small"
            label="Tipo de cálculo"
            options={calculationTypeOptions}
            placeholder={
              values.calculation_type
                ? getLabelByValue(
                    values.calculation_type,
                    calculationTypeOptions
                  )
                : "Selecione uma opção"
            }
            error={
              values.calculation_type !== "252" &&
              (values.indexer === "cdi" || values.indexer === "selic")
            }
            message={
              values.indexer === "cdi" && values.calculation_type !== "252"
                ? "Para o indexador pós-fixado 'CDI' mude o tipo de calculo para 252 dias "
                : values.indexer === "selic" &&
                    values.calculation_type !== "252"
                  ? "Para o indexador pós-fixado 'SELIC' mude o tipo de calculo para 252 dias "
                  : ""
            }
            onChange={(e) => handleSelect("calculation_type", e)}
          />
        </div>
        <div>
          {" "}
          <InputCurrency
            name="requested_amount"
            id="requested_amount"
            size="large"
            label="Valor solicitado (R$)"
            max={24}
            maxLength={24}
            placeholder="R$"
            error={
              errorPrincipalAmount ||
              isNotDivisible ||
              convertStringToNumber(values.requested_amount) <
                convertStringCurrencyToNumber(values.unit_amount)
            }
            message={
              productDetails && errorPrincipalAmount
                ? ` Digite um valor entre R$ ${formatNumberToCurrencyWithoutSimbol(productDetails.min_principal_amount)} e R$ ${formatNumberToCurrencyWithoutSimbol(productDetails.max_principal_amount)}`
                : convertStringToNumber(values.requested_amount) <
                    convertStringCurrencyToNumber(values.unit_amount)
                  ? "O valor solicitado deve ser maior ou igual ao unitário"
                  : isNotDivisible &&
                      convertStringToNumber(values.requested_amount) >
                        convertStringCurrencyToNumber(values.unit_amount)
                    ? "O valor solicitado precisa ser divisível pelo valor unitário"
                    : ""
            }
            value={values.requested_amount}
            onChange={(e) => setFieldValue("requested_amount", e.target.value)}
          />
        </div>
        <div>
          <InputCurrency
            name="unit_amount"
            id="unit_amount"
            max={24}
            maxLength={24}
            size="large"
            label="Valor unitário (R$)"
            placeholder="R$"
            error={
              convertStringCurrencyToNumber(values.unit_amount) < 1 ||
              convertStringCurrencyToNumber(values.unit_amount) >
                convertStringCurrencyToNumber(values.requested_amount)
            }
            message={
              convertStringCurrencyToNumber(values.unit_amount) < 1 ||
              convertStringCurrencyToNumber(values.unit_amount) >
                convertStringCurrencyToNumber(values.requested_amount)
                ? ` Digite um valor entre R$ 1,00 e R$ ${formatNumberToCurrencyWithoutSimbol(convertStringCurrencyToNumber(values.requested_amount))}`
                : ""
            }
            value={values.unit_amount}
            onChange={(e) => setFieldValue("unit_amount", e.target.value)}
          />
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="duration"
            id="duration"
            maxLength={5}
            max={5}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^0-9]/g, "");
              setFieldValue("duration", value);
            }}
            size="large"
            label="Quantidade de amortizações"
            value={values.duration}
            placeholder="Digite"
            message={errorDuration ? "Digite um valor maior que 1" : ""}
            error={errorDuration}
          />
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Tooltip
            align="top"
            size="medium"
            label="Data simulada da primeira subscrição"
          >
            {" "}
            <Input
              type="date"
              name="release_date"
              size="large"
              label="Data de lançamento"
              placeholder="Digite"
              error={errorReleaseDate}
              message={
                errorReleaseDate
                  ? "A data de lançamento precisa ser igual ou posterior a data atual"
                  : ""
              }
              value={values.release_date}
              onChange={(e) => setFieldValue("release_date", e.target.value)}
            />
          </Tooltip>
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Input
            type="date"
            name="due_date"
            size="large"
            error={errorDueDate}
            message={
              errorDueDate
                ? "A data de vencimento precisa ser igual ou posterior a data de lançamento"
                : ""
            }
            label="Data de vencimento"
            placeholder="Digite"
            value={values.due_date}
            onChange={(e) => {
              const inputValue = e.target.value;

              setFieldValue("due_date", inputValue);
            }}
          />
        </div>

        <div style={{ position: "relative", top: "3px" }}>
          <Input
            name="interest_rate_monthly"
            id="interest_rate_monthly"
            maxLength={6}
            max={6}
            size="large"
            label="Taxa de juros (%)"
            placeholder="%"
            message={
              errorInterestRate
                ? `Informe um valor entre ${productDetails?.min_interest_rate}% e ${productDetails?.max_interest_rate}%`
                : ""
            }
            error={errorInterestRate}
            value={values.interest_rate_monthly}
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d.]/g, "");
              const parts = value.split(".");
              if (parts.length > 2) {
                return;
              }

              setFieldValue("interest_rate_monthly", value);
            }}
          />
        </div>
        <div>
          <InputSelect
            name="interest_periodicity"
            id="interest_periodicity"
            kind="default"
            size="small"
            label="Periodicidade dos juros"
            options={periodicityOptions}
            placeholder={
              values.interest_periodicity
                ? getLabelByValue(
                    values.interest_periodicity,
                    periodicityOptions
                  )
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("interest_periodicity", e)}
          />
        </div>

        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="amortization_grace_period"
            id="amortization_grace_period"
            maxLength={5}
            max={5}
            size="large"
            label="Carência de amortização "
            placeholder="Quantidade"
            message={
              values.amortization_grace_period &&
              values.duration &&
              Number(values.amortization_grace_period) >=
                Number(values.duration)
                ? "A Carência de amortização não pode ser maior ou igual ao valor da Duração"
                : errorAmortizationPeriod
                  ? ` Informe um valor entre ${productDetails?.min_amortization_period}  e ${productDetails?.max_amortization_period}`
                  : ""
            }
            error={
              values.amortization_grace_period &&
              values.duration &&
              Number(values.amortization_grace_period) >=
                Number(values.duration)
                ? true
                : errorAmortizationPeriod
                  ? true
                  : false
            }
            value={values.amortization_grace_period}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^0-9]/g, "");
              setFieldValue("amortization_grace_period", value);
            }}
          />
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="interest_grace_period"
            id="interest_grace_period"
            maxLength={5}
            max={5}
            size="large"
            label="Carência de juros"
            placeholder="Quantidade"
            value={values.interest_grace_period}
            message={
              values.interest_grace_period &&
              values.duration &&
              Number(values.interest_grace_period) >= Number(values.duration)
                ? "O valor da Carência de juros não pode ser maior ou igual ao valor da Duração"
                : values.interest_grace_period &&
                    values.amortization_grace_period &&
                    Number(values.interest_grace_period) >
                      Number(values.amortization_grace_period)
                  ? "O valor da Carência de juros não pode ser maior do que o valor da Carência de amortização"
                  : ""
            }
            error={
              values.interest_grace_period &&
              values.duration &&
              Number(values.interest_grace_period) >= Number(values.duration)
                ? true
                : values.interest_grace_period &&
                    values.amortization_grace_period &&
                    Number(values.interest_grace_period) >
                      Number(values.amortization_grace_period)
                  ? true
                  : false
            }
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^0-9]/g, "");
              setFieldValue("interest_grace_period", value);
            }}
          />
        </div>

        <div>
          <InputSelect
            name="calculation_base"
            kind="default"
            size="small"
            label="Base de cálculo"
            options={calculationBaseOptions}
            placeholder={
              values.calculation_base
                ? getLabelByValue(
                    values.calculation_base,
                    calculationBaseOptions
                  )
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("calculation_base", e)}
          />
        </div>

        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="commission"
            id="commission"
            size="large"
            error={errorCommission || values.commission === ""}
            message={
              errorCommission || values.commission === ""
                ? "Informe um valor entre 0 e 100"
                : ""
            }
            maxLength={6}
            max={6}
            label="Correspondente bancário (%)"
            placeholder="%"
            value={values.commission}
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d.]/g, "");
              const parts = value.split(".");
              if (parts.length > 2) {
                return;
              }

              setFieldValue("commission", value);
            }}
          />
        </div>

        <div style={{ position: "relative", top: "7px" }}>
          <InputSelect
            name="indexer"
            kind="default"
            size="small"
            label="Indexador pós-fixado"
            options={indexesOptions}
            placeholder={
              values.indexer !== null || values.indexer === "null"
                ? getLabelByValue(values.indexer, indexesOptions)
                : values.indexer === null || values.indexer === "null"
                  ? "Nenhum"
                  : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("indexer", e)}
          />
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="indexer_rate"
            id="indexer_rate"
            size="large"
            disabled={!values.indexer || values.indexer === "no_one"}
            maxLength={6}
            max={6}
            value={values.indexer_rate}
            label="Percentual indexador (%)"
            placeholder="%"
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d.]/g, "");
              const parts = value.split(".");
              if (parts.length > 2) {
                return;
              }

              setFieldValue("indexer_rate", value);
            }}
            error={
              values.indexer !== "no_one" && Number(values.indexer_rate) < 1
            }
            message={
              values.indexer !== "no_one" && Number(values.indexer_rate) < 1
                ? "Digite um valor maior que 0"
                : ""
            }
          />
        </div>
        {(values.indexer === "igpm" || values.indexer === "ipca") && (
          <div style={{ position: "relative", top: "7px" }}>
            <InputSelect
              name="birth_date_type"
              id="birth_date_type"
              kind="default"
              size="small"
              label="Tipo da data de aniversário"
              options={birthDateOptions}
              placeholder={
                values.birthday_date_type !== null ||
                values.birthday_date_type === "null"
                  ? getLabelByValue(values.birthday_date_type, birthDateOptions)
                  : values.birthday_date_type === null ||
                      values.birthday_date_type === "null"
                    ? "Nenhum"
                    : "Selecione uma opção"
              }
              onChange={(e) => handleSelect("birthday_date_type", e)}
            />
          </div>
        )}
        {(values.indexer === "igpm" || values.indexer === "ipca") &&
          values.birthday_date_type === "fixed" && (
            <div style={{ position: "relative", top: "7px" }}>
              <Input
                name="birth_date_day"
                id="birth_date_day"
                maxLength={5}
                max={5}
                size="large"
                label="Dia da Data de Aniversário"
                placeholder={"Digite"}
                value={values.birthday_date_day}
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/[^0-9]/g, "");

                  if (value === "") {
                    setFieldValue("birthday_date_day", "");
                    return;
                  }

                  let numValue = Number(value);
                  if (numValue > 31) numValue = 31;

                  setFieldValue("birthday_date_day", numValue.toString());
                }}
              />
            </div>
          )}
      </div>
      <div>
        {simulateResult?.calculation_type && (
          <div ref={printRef} style={{ marginTop: "40px" }}>
            <h4>Detalhes da simulação</h4>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                gap: "32px",
                rowGap: "30px",
                marginTop: "20px",
              }}
            >
              <Tile variations="default">
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px 20px",
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                    }}
                  >
                    Data de emissão
                  </h4>
                  <Margin mt={17} />
                  <p>
                    {simulateResult?.operation_date
                      ? format(
                          parseISO(simulateResult?.operation_date),
                          "dd/MM/yyyy"
                        )
                      : "-"}
                  </p>
                </div>
              </Tile>
              <Tile variations="default">
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px 20px",
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                    }}
                  >
                    Data de vencimento
                  </h4>
                  <Margin mt={17} />
                  <p>
                    {" "}
                    {simulateResult?.due_date
                      ? format(parseISO(simulateResult?.due_date), "dd/MM/yyyy")
                      : "-"}
                  </p>
                </div>
              </Tile>
              <Tile variations="default">
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px 20px",
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                    }}
                  >
                    Qtde. de amortizações
                  </h4>
                  <Margin mt={17} />
                  <p>{simulateResult?.duration}</p>
                </div>
              </Tile>

              <Tile variations="default">
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px 20px",
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                    }}
                  >
                    Valor do contrato
                  </h4>
                  <Margin mt={17} />
                  <p>
                    {" "}
                    R${" "}
                    {formatNumberToCurrencyWithoutSimbol(
                      simulateResult?.requested_amount ?? 0
                    )}
                  </p>
                </div>
              </Tile>

              <Tile variations="default">
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px 20px",
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                    }}
                  >
                    Custo da emissão
                  </h4>
                  <Margin mt={17} />
                  <p>
                    {" "}
                    R${" "}
                    {formatNumberToCurrencyWithoutSimbol(
                      simulateResult?.taxes_values?.emission_cost ?? 0
                    )}
                  </p>
                </div>
              </Tile>
              <Tile variations="default">
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px 20px",
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                    }}
                  >
                    Indexador
                  </h4>
                  <Margin mt={17} />
                  <p>
                    {simulateResult?.taxes_settings?.indexer
                      ? indexesDict[simulateResult?.taxes_settings?.indexer]
                      : "-"}
                  </p>
                </div>
              </Tile>
              <Tile variations="default">
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px 20px",
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                    }}
                  >
                    Taxa A.M.
                  </h4>
                  <Margin mt={17} />
                  <p>
                    {simulateResult?.taxes_settings.interest_rate_monthly?.toFixed(
                      4
                    )}{" "}
                    %
                  </p>
                </div>
              </Tile>
              <Tile variations="default">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px 20px",
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                    }}
                  >
                    Taxa A.A.
                  </h4>
                  <Margin mt={17} />
                  <p>
                    {simulateResult?.taxes_settings.interest_rate_annually?.toFixed(
                      4
                    )}{" "}
                    %
                  </p>
                </div>
              </Tile>
            </div>
          </div>
        )}

        {simulateResult?.calculation_type && (
          <div ref={printRef} style={{ marginTop: "50px" }}>
            {" "}
            <Table
              pageSizes={[10, 5, 20, 50, 100]}
              columnTitles={titlesColumnInstallments}
              rowData={dataTableInstallments ?? []}
              width="1000px"
              radius
              // buttons={[
              //   {
              //     kind: "primary",
              //     position: "right",
              //     label: "Adicionar parcelas",
              //     icon: MoreIcon,
              //   },
              // ]}
            />
          </div>
        )}
      </div>
      <Margin mt={50} />
      <div
        style={{
          gridColumn: "1 / -1",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <Button
            kind="ghost"
            size="large"
            label="Cancelar"
            onClick={() => {
              setTab(0);
              localStorage.removeItem("@:involveds");
              localStorage.removeItem("@:simulate_operation");
              localStorage.removeItem("@:operation");
              localStorage.removeItem("@:documents");
              navigate("/app/credit/operations/");
            }}
          />
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            <Button
              kind="primary"
              size="large"
              label="Avançar"
              icon={ArrowIcon}
              onClick={() => {
                navigate(
                  "/app/credit/operations/new/commercial-note/product-info/"
                );
                setTab(1);
              }}
              disabled={
                !values.release_date ||
                isNotDivisible ||
                convertStringCurrencyToNumber(values.unit_amount) < 1 ||
                convertStringCurrencyToNumber(values.unit_amount) >
                  convertStringCurrencyToNumber(values.requested_amount) ||
                convertStringCurrencyToNumber(values.requested_amount) <
                  convertStringCurrencyToNumber(values.unit_amount) ||
                !values.amortization_grace_period ||
                !values.calculation_base ||
                !values.calculation_type ||
                !values.due_date ||
                !values.commission ||
                errorBirthdayDate ||
                errorDueDate ||
                !values.duration ||
                !values.indexer ||
                (values.indexer !== "no_one" &&
                  Number(values.indexer_rate) < 1) ||
                !values.interest_grace_period ||
                !values.interest_periodicity ||
                !values.interest_rate_monthly ||
                !values.nickname ||
                errorAmortizationPeriod ||
                errorCommission ||
                errorDuration ||
                errorInterestRate ||
                errorPrincipalAmount ||
                errorReleaseDate ||
                (values.indexer === "selic" &&
                  values.calculation_type !== "252") ||
                (values.indexer === "cdi" && values.calculation_type !== "252")
              }
            />
          </div>
        </div>
      </div>
      <Modal
        height="600px"
        width="755px"
        background="#f7f4f4"
        onClose={() => setEditInstallment(false)}
        label={
          <div>
            <h4>
              {" "}
              Simulação Nota Comercial{" "}
              {simulateResult?.uuid ? ` - ID: ${simulateResult.uuid}` : ""}
            </h4>
            <h4>Parcela {installmentData?.number}</h4>
          </div>
        }
        buttons={[
          {
            kind: "ghost",
            label: "Cancelar",
            onClick: () => setEditInstallment(false),
          },
          {
            kind: "primary",
            label: "Salvar",
            disabled:
              errorAmortizationInstallmentAmount ||
              errorDueDateInstallment ||
              errorInterestAmountInstallment ||
              errorDueDateToLongInstallment ||
              !values.due_date_installment,
            onClick: () => {
              sendUpdateInstallment();
            },
          },
        ]}
        open={editInstallment}
        radius
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
            padding: "20px",
          }}
        >
          <InputCurrency
            label="Valor para amortização"
            name="amortization_amount"
            id="amortization_amount"
            size="medium"
            error={errorAmortizationInstallmentAmount}
            message={
              errorAmortizationInstallmentAmount
                ? `O valor para amortização não pode ser superior à R$ ${previousInstallment ? formatNumberToCurrencyWithoutSimbol(previousInstallment?.outstanding_amount) : ""}`
                : ""
            }
            onChange={(e) =>
              setFieldValue("amortization_amount", e.target.value)
            }
            value={values.amortization_amount}
            disabled={
              Number(installmentData?.number) + 1 ===
              simulateResult?.installments?.length
            }
          />
          <InputCurrency
            name="interest"
            id="interest"
            label="Juros + indexador"
            onChange={(e) => setFieldValue("interest", e.target.value)}
            value={values.interest}
            error={errorInterestAmountInstallment}
            message={
              errorInterestAmountInstallment
                ? `O valor do juros não pode ser superior à R$ ${installmentData?.interest ? formatNumberToCurrencyWithoutSimbol(installmentData?.interest) : formatNumberToCurrencyWithoutSimbol(0)}`
                : ""
            }
            disabled={
              Number(installmentData?.number) + 1 ===
              simulateResult?.installments?.length
            }
          />

          <Input
            name="due_date_installment"
            id="due_date_installment"
            size="large"
            type="date"
            error={errorDueDateInstallment || errorDueDateToLongInstallment}
            message={
              errorDueDateInstallment
                ? `A data de vencimento não pode ser anterior à ${previousInstallment?.due_date ? format(parseISO(previousInstallment?.due_date), "dd/MM/yyyy") : ""}`
                : errorDueDateToLongInstallment
                  ? `A data de vencimento não pode ser igual ou superior à ${simulateResult?.due_date ? format(parseISO(simulateResult?.due_date), "dd/MM/yyyy") : ""}`
                  : ""
            }
            label="Data de vencimento"
            onChange={(e) => {
              setFieldValue("due_date_installment", e.target.value);
            }}
            value={values.due_date_installment}
          />

          <Input
            name="outstanding_amount"
            id="outstanding_amount"
            size="large"
            label="Saldo devedor"
            readOnly
            placeholder={`R$ ${formatNumberToCurrencyWithoutSimbol(
              values.outstanding_amount
            )}`}
            onChange={(e) =>
              setFieldValue("outstanding_amount", e.target.value)
            }
          />
        </div>
      </Modal>
      <Margin mb={50} />
    </div>
  );
};

export default Operation;
