import { useFormik } from "formik";
import {
  Button,
  Input,
  InputSelect,
  Modal,
  Table,
} from "prosperita-dumbo-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import useOperation from "~/hooks/useOperation";

import {
  formatCPFCNPJ,
  formatNumberToCurrencyWithoutSimbol,
} from "~/services/utils";
import ArrowIcon from "~/assets/arrow-right-_1_.png";
import { useNavigate, useParams } from "react-router-dom";
import Margin from "~/components/Margin";
import { getPartnersAPI, IPartner } from "~/services/api/partner";
import usePartner from "~/hooks/usePartner";
import {
  postSubscriberAPI,
  postSubscriberConfirmationAPI,
} from "~/services/api/subscriber";
import { toast } from "react-toastify";
import useLoading from "~/hooks/useLoading";
import { formatDate } from "date-fns";

type IInitialValues = {
  partner_uuid: string;
  quantity_of_units_acquired: string;
};

const initialValues: IInitialValues = {
  partner_uuid: "",
  quantity_of_units_acquired: "0",
};

const titlesColumnInstallments = [
  "Parcela",
  "Data",
  "Amortização Juros",
  "Amortização Principal",
  "Total à Receber",
];

const BasicDataSubscriber = () => {
  const {
    setTab,
    operationDetails,
    setSubscriberSimulationPayload,
    installments,
    setInstallments,
  } = useOperation();
  const { partners, setPartners } = usePartner();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const { uuid } = useParams();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  const partnersOptions = useMemo(() => {
    return partners
      .map((partner: IPartner) => ({
        value: `${partner.uuid}`,
        label: `${partner.name} | ${formatCPFCNPJ(partner.document_number)}`,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [partners]);

  const fetchPartners = useCallback(async (): Promise<void> => {
    try {
      const { data } = await getPartnersAPI();
      setPartners(data.payload.reverse());
    } catch (e) {
      console.error("Erro ao buscar contatos:", e);
    }
  }, [setPartners]);

  const payload = useMemo(() => {
    return {
      asset_uuid: uuid && uuid,
      partner_uuid: values.partner_uuid,
      quantity_of_units_acquired: Number(values.quantity_of_units_acquired),
    };
  }, [uuid, values]);

  const addSubscriber = useCallback(async (): Promise<void> => {
    const payloadSubscriber = {
      partner_uuid: values.partner_uuid,
      quantity_of_units_acquired: Number(values.quantity_of_units_acquired),
      asset_uuid: String(uuid),
    };
    setLoading(true);

    try {
      const { data } = await postSubscriberAPI(payloadSubscriber);

      const id = data.payload.uuid;

      await postSubscriberConfirmationAPI(id);

      setTab(2);

      toast.success("Subscritor adicionado com sucesso");
      navigate(
        `/app/credit/operations/details/${uuid}/subscriber/new/${id}/involveds/`
      );
      setLoading(false);
    } catch (e) {
      console.error("Erro ao buscar contatos:", e);
      toast.error(
        "Não foi possível adicionar o subscritor! Tente novamente mais tarde."
      );
      setLoading(false);
    }
  }, [values, uuid, navigate, setTab, setLoading]);

  type Installment = {
    number: number;
    date: string;
    interest_amortization: number;
    principal_amortization: number;
    total_to_receive: number;
  };

  const dataTableInstallments = installments?.installments?.map(
    (installmentsDetail: Installment) => {
      return {
        number: installmentsDetail.number,
        dua_date: formatDate(installmentsDetail.date, "dd/MM/yyyy"),
        insterest_amortization: `R$ ${formatNumberToCurrencyWithoutSimbol(installmentsDetail.interest_amortization)}`,
        principal_amortization: `R$ ${formatNumberToCurrencyWithoutSimbol(installmentsDetail.interest_amortization)}`,
        total: `R$ ${formatNumberToCurrencyWithoutSimbol(installmentsDetail.total_to_receive)}`,
      };
    }
  );

  useEffect(() => {
    setTab(0);
  }, [setTab]);

  useEffect(() => {
    if (operationDetails?.commercial_note.available_quantity_of_units !== 0) {
      setFieldValue("quantity_of_units_acquired", "1");
    }
  }, [operationDetails, setFieldValue]);

  useEffect(() => {
    if (payload) {
      setSubscriberSimulationPayload(payload);
    }
  }, [payload, setSubscriberSimulationPayload]);

  useEffect(() => {
    fetchPartners();
    setInstallments(null);
  }, [fetchPartners, setInstallments]);

  return (
    <div style={{ width: "100%", height: "auto" }}>
      {operationDetails?.commercial_note.available_quantity_of_units === 0 && (
        <p style={{ color: "red", fontSize: "18px", marginBottom: "50px" }}>
          Esta nota comercial não possui mais cotas disponíveis para serem
          subscritas
        </p>
      )}

      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "2fr 1fr ",
          gap: "32px",
          rowGap: "30px",
          marginTop: "20px",
          height: "100px",
        }}
      >
        <div>
          {" "}
          <InputSelect
            name="product_uuid"
            kind="default"
            size="small"
            label="Subscritor"
            options={partnersOptions}
            disabled={
              operationDetails?.commercial_note.available_quantity_of_units ===
              0
            }
            placeholder={"Selecione o subscritor"}
            onChange={(e) => handleSelect("partner_uuid", e)}
          />
        </div>
        <Input
          size="large"
          max={5}
          maxLength={5}
          label={`Quantidade (${operationDetails?.commercial_note.available_quantity_of_units} disponíveis)`}
          disabled={
            operationDetails?.commercial_note.available_quantity_of_units === 0
          }
          name="quantity_of_units_acquired"
          value={values.quantity_of_units_acquired}
          onChange={(e) => {
            let value = e.target.value;
            value = value.replace(/[^0-9]/g, "");
            setFieldValue("quantity_of_units_acquired", value);
          }}
          error={
            operationDetails
              ? Number(values.quantity_of_units_acquired) >
                operationDetails?.commercial_note?.available_quantity_of_units
              : false
          }
          message={
            operationDetails &&
            Number(values.quantity_of_units_acquired) >
              operationDetails?.commercial_note?.available_quantity_of_units
              ? `Esta nota possui apenas ${operationDetails?.commercial_note?.available_quantity_of_units} cotas disponíveis para subscrição. Por favor, digite um valor menor`
              : ""
          }
        />
      </div>
      <Margin mt={50} />

      {installments && installments?.installments?.length > 0 && (
        <>
          <h4>Resultados da simulação</h4>
          <Margin mt={20} />
          <Table
            pageSizes={[10, 5, 20, 50, 100]}
            columnTitles={titlesColumnInstallments}
            rowData={dataTableInstallments || []}
            width="1000px"
            radius
          />
          <div
            style={{
              gridColumn: "1 / -1",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "50px",
            }}
          >
            <div>
              <Button
                kind="ghost"
                size="large"
                label="Cancelar"
                onClick={() => {
                  setTab(0);
                  localStorage.removeItem("@:involveds");
                  localStorage.removeItem("@:simulate_operation");
                  localStorage.removeItem("@:operation");
                  localStorage.removeItem("@:documents");
                  navigate(`/app/credit/operations/details/${uuid}`);
                }}
              />
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <div>
                <Button
                  kind="primary"
                  size="large"
                  label="Avançar"
                  icon={ArrowIcon}
                  onClick={() => {
                    setOpenConfirm(true);
                  }}
                  disabled={
                    (operationDetails &&
                      Number(values.quantity_of_units_acquired) >
                        (operationDetails?.commercial_note
                          ?.available_quantity_of_units ?? 0)) ||
                    !values.partner_uuid ||
                    !values.quantity_of_units_acquired ||
                    Number(values.quantity_of_units_acquired) < 1 ||
                    !(installments && installments?.installments?.length > 0)
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}

      <Margin mt={110} />

      <Modal
        radius
        height={"368px"}
        width="960px"
        background="#f4f4f4"
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        label={<h3>Adicionar subscritor</h3>}
        buttons={[
          {
            kind: "secondary",
            label: "Não, manter como está",
            onClick: () => setOpenConfirm(false),
          },
          {
            kind: "primary",
            label: "Sim, incluir subscritor",
            onClick: () => addSubscriber(),
          },
        ]}
      >
        <div style={{ padding: "20px" }}>
          {" "}
          <p style={{ fontSize: "24px" }}>
            Deseja confirmar a adição do subscritor ?
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default BasicDataSubscriber;
