import constate from "constate";
import { useState } from "react";
import AppStorage from "~/services/storage";

const [AuthProvider, useAuth] = constate(() => {
  const [token, setToken] = useState<string>();
  const [user, setUser] = useState<Record<string, unknown>>();

  const login = (token: string, user: Record<string, unknown>) => {
    AppStorage.setToken(token);
    AppStorage.setUser(user);

    setToken(token);
    setUser(user);
  };

  const logout = () => {
    AppStorage.remove("@app:token_cred_engine");
    AppStorage.remove("@app:pass");
    setToken(undefined);
    setUser(undefined);
    location.reload();
  };

  return {
    token,
    setToken,
    user,
    setUser,

    login,
    logout,
  };
});

export { AuthProvider };

export default useAuth;
