import Margin from "~/components/Margin";
import {
  Outlet,
  useLocation,
  // useLocation,
  useNavigate,
} from "react-router-dom";
import {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
  // useEffect,
} from "react";
import ArrowIcon from "~/assets/arrow-right-_1_.png";
import WhiteLeft from "~/assets/white-left.png";
import { Breadcrumb, Button, Input, Modal } from "prosperita-dumbo-react";
import activityIcon from "~/assets/Activity.png";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import NewDebitor from "./NewDebitor";
import useOperation from "~/hooks/useOperation";
import NewInvolvedCCB from "./NewInvolved";
import Stepper from "~/components/Stepper";
import NewDocumentCCB from "./NewDocumentCCB";
import { getSimulateOperationAPI } from "~/services/api/operations";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow: hidden;
  align-items: start;
  padding: 30px 50px;
  box-sizing: border-box;
`;

const Title = styled.h2`
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 100;
  line-height: 40px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const canViewOperations = true;

const NewOperationCCB: FunctionComponent = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const {
    tab,
    setRecoverSimulation,
    recoverSimulation,
    setTab,
    setSimulateResult,
    canAdvance,
    setConfirmOperation,
  } = useOperation();
  // const locationURL = useLocation();
  const location = useLocation();
  const steps = [
    {
      label: "Operação",
      isCompleted: tab > 0,
      isActive: tab === 0,
    },
    {
      label: "Informações do produto",
      isCompleted: tab > 1,
      isActive: tab === 1,
    },
    { label: "Envolvidos", isCompleted: tab > 2, isActive: tab === 2 },
    { label: "Documentos", isCompleted: tab > 3, isActive: tab === 3 },
  ];

  const handleClose = () => {
    setRecoverSimulation(false);
  };

  const [uuid, setUuid] = useState<string>("");

  const isNotCCBRecover = useCallback(() => {
    toast.error("Este ID é inválido ou não pertence a uma simulação de CCB.");
    setRecoverSimulation(false);
    setLoading(false);
    localStorage.removeItem("@:simulation_uuid");
  }, [setLoading, setRecoverSimulation]);

  const fetchSimulation = async (uuid: string) => {
    setLoading(true);

    try {
      const { data } = await getSimulateOperationAPI(uuid.trim());

      if (data.payload.product.product_type !== "ccb") {
        return isNotCCBRecover();
      }

      const simulation = data.payload;

      localStorage.setItem(
        "@:simulation_uuid",
        JSON.stringify({ simulation_uuid: data.payload.uuid })
      );

      const payload = {
        nickname: simulation.nickname,
        product_uuid: simulation.product.uuid,
        calculation_type: simulation.calculation_type,
        requested_amount: String(simulation.requested_amount),
        duration: simulation.duration,
        calculation_base: simulation.calculation_base,
        first_payment_date: simulation.first_payment_date,
        release_date: simulation.release_date,
        legal_person: simulation.legal_person
          ? "legal_person"
          : "not_legal_person",
        payment_every: simulation.payment_every,
        due_date_on_business: simulation.due_date_on_business,
        payment_periodicity: simulation.payment_periodicity,

        taxes_settings: {
          fiduciary_guarantee: simulation.taxes_settings.fiduciary_guarantee,
          interest_rate_monthly:
            simulation.taxes_settings.interest_rate_monthly,
          interest_periodicity: simulation.taxes_settings.interest_periodicity,
          amortization_grace_period:
            simulation.taxes_settings.amortization_grace_period,
          interest_grace_period:
            simulation.taxes_settings.interest_grace_period,
          commission: simulation.taxes_settings.commission,
          indexer: simulation.taxes_settings.indexer,
          indexer_rate: simulation.taxes_settings.indexer_rate,
        },
      };

      localStorage.setItem("@:simulate_operation", JSON.stringify(payload));

      setRecoverSimulation(false);

      setLoading(false);
    } catch (e) {
      console.log(e);
      toast.error("Não foi possível recuperar a simulação !");
      setLoading(false);
    }
  };

  const advanceFunctionsDict = useMemo(() => {
    return {
      "operations/new/ccb/operation": () => {
        navigate("/app/credit/operations/new/ccb/product-info/");
        setTab(1);
      },
      "operations/new/ccb/product-info": () => {
        navigate("/app/credit/operations/new/ccb/involveds/");
        setTab(2);
      },
      "operations/new/ccb/involveds": () => {
        navigate("/app/credit/operations/new/ccb/documents/");
        setTab(3);
      },
      "operations/new/ccb/documents/": () => {
        setConfirmOperation(true);
      },
    };
  }, [navigate, setTab, setConfirmOperation]);

  const returnFunctionsDict = useMemo(() => {
    return {
      "/app/credit/operations/new/ccb/product-info/": () => {
        navigate("/app/credit/operations/new/ccb/operation/");
        setTab(0);
      },
      "/app/credit/operations/new/ccb/involveds/": () => {
        navigate("/app/credit/operations/new/ccb/product-info/");
        setTab(1);
      },
      "/app/credit/operations/new/ccb/documents/": () => {
        navigate("/app/credit/operations/new/ccb/involveds/");
        setTab(2);
      },
    };
  }, [navigate, setTab]);

  const returnFunctions = useCallback(() => {
    const path = location.pathname;
    Object.keys(returnFunctionsDict).forEach((rota) => {
      if (path.includes(rota)) {
        returnFunctionsDict[rota]();
      }
    });
  }, [location.pathname, returnFunctionsDict]);

  const advance = useCallback(() => {
    const path = location.pathname;
    Object.keys(advanceFunctionsDict).forEach((rota) => {
      if (path.includes(rota)) {
        advanceFunctionsDict[rota]();
      }
    });
  }, [location.pathname, advanceFunctionsDict]);

  return (
    <Container>
      <div
        style={{
          position: "fixed",
          top: 110,
          right: "0",
          background: "#fff",
          width: "100%",
          zIndex: 4,
          paddingLeft: "47px",
          paddingRight: "47px",
          paddingBottom: "20px",
          paddingTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Breadcrumb
            crumbsOptions={[
              {
                crumb: "Operações",
                onClick: () => navigate("/app/credit/operations"),
              },
              {
                crumb: "Nova operação (CCB)",
              },
            ]}
            endHiddenIndex={5}
            startHiddenIndex={5}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "30px ",
          }}
        >
          <Title>Nova operação - CCB</Title>
          <div style={{ display: "flex", gap: "10px" }}>
            <div>
              <Button
                kind="ghost"
                size="large"
                label="Cancelar"
                onClick={() => {
                  setTab(0);
                  localStorage.removeItem("@:involveds");
                  localStorage.removeItem("@:simulate_operation");
                  localStorage.removeItem("@:operation");
                  localStorage.removeItem("@:documents");
                  navigate("/app/credit/operations/");
                  setSimulateResult(null);
                }}
              />
            </div>
            {!location.pathname.includes("operations/new/ccb/operation") && (
              <div>
                <Button
                  kind="secondary"
                  size="large"
                  icon={WhiteLeft}
                  label="Voltar"
                  onClick={() => {
                    returnFunctions();
                  }}
                />
              </div>
            )}
            {/* <div style={{ width: "250px" }}>
              <Button
                kind="secondary"
                size="large"
                label="Nova Simulação"
                icon={activityIcon}
                onClick={() => navigate("/app/credit/new-simulate/ccb")}
              />
            </div> */}
            <div style={{ width: "250px" }}>
              <Button
                kind="primary"
                size="large"
                disabled={!canAdvance}
                label={
                  location.pathname.includes(
                    "/app/credit/operations/new/ccb/documents/"
                  )
                    ? "Finalizar"
                    : "Avançar"
                }
                icon={
                  location.pathname.includes(
                    "/app/credit/operations/new/ccb/documents/"
                  )
                    ? activityIcon
                    : ArrowIcon
                }
                onClick={() => advance()}
              />
            </div>

            {/* <div style={{ width: "250px" }}>
                    <Button
                      kind="primary"
                      disabled={
                        locationURL.pathname.includes("new/ccb/operation")
                          ? false
                          : true
                      }
                      size="large"
                      label="Recuperar simulação"
                      onClick={() => setRecoverSimulation(true)}
                    />
                  </div> */}
            {/* <div style={{ width: "250px" }}>
                    <Button
                      kind="secondary"
                      size="large"
                      label="Recuperar simulação"
                    />
                  </div> */}
          </div>
        </div>
        <Stepper steps={steps} />
      </div>

      <div style={{ width: "100%", marginTop: "160px" }}>
        <>
          {canViewOperations && (
            <div>
              <Margin mt={50} />
              <Outlet />
            </div>
          )}
        </>

        {!canViewOperations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para criar operações de crédito, <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>

      <NewDebitor />
      <div style={{ zIndex: 9999 }}>
        <NewInvolvedCCB />
      </div>

      <div style={{ zIndex: 9999 }}>
        <NewDocumentCCB />
      </div>
      <div style={{ zIndex: 999 }}>
        <Modal
          radius
          height={"368px"}
          width="760px"
          background="#f4f4f4"
          open={recoverSimulation}
          onClose={() => handleClose()}
          label={<h4>Recuperar simulação</h4>}
          buttons={[
            {
              kind: "secondary",
              label: "Cancelar",
              onClick: () => handleClose(),
            },
            {
              kind: "primary",
              disabled: uuid.length < 36,
              label: "Recuperar simulação",
              onClick: () => fetchSimulation(uuid),
            },
          ]}
        >
          <div style={{ padding: "20px" }}>
            <Input
              label="Insira um ID para recuperar a simulação"
              size="large"
              type="text"
              max={37}
              maxLength={37}
              placeholder="ID*"
              value={uuid}
              onChange={(e) => setUuid(e.target.value.trim())}
            />
          </div>
        </Modal>
      </div>
    </Container>
  );
};

export default NewOperationCCB;
