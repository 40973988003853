import { Button, FileUploader, InputSelect } from "prosperita-dumbo-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import LateralModal from "~/components/LateralModal";
import CloseIcon from "~/assets/Close--outline.png";
import SaveIcon from "~/assets/Save.png";

import { zIndex } from "~/services/utils";
import { useFormik } from "formik";

import { IDocumentDataPartner } from "~/types";

import usePartner from "~/hooks/usePartner";
import { useParams } from "react-router-dom";
import {
  getPartnerDocumentsAPI,
  postPartnerDocumentsAPI,
} from "~/services/api/partner";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import FileItem from "./FileItem";
const Container = styled.div`
  width: 100%;
  padding: 30px;
  display: grid;
  height: auto;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  z-index: 2;
`;

const Footer = styled.div`
  width: 90%;
  position: fixed;
  bottom: 0;
  display: grid;
  padding-bottom: 50px;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
`;

type Values = {
  related_to_uuid: string;
  type: string;
};

const initialValues: Values = {
  related_to_uuid: "",
  type: "",
};

const NewDocument = () => {
  const { addDocument, setAddDocument } = usePartner();
  const [binaryContent, setBinaryContent] = useState<string | null>(null);
  const { uuid } = useParams();

  const [file, setFile] = useState<File | undefined>(undefined);
  const { setFieldValue, values, resetForm } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const bodyRef = useRef(document.body);

  const areValuesEmptyOrFalse = (values) => {
    if (!values.type || binaryContent === null) {
      return true;
    }

    return false;
  };

  const listDocuments = useMemo(() => {
    return [
      { label: "Contrato Social", value: "social_contract" },
      { label: "Comprovante de endereço", value: "proof_residence" },
      { label: "RG (Frente)", value: "front_document" },
      { label: "RG (Verso)", value: "back_document" },
      { label: "Imagem", value: "image" },
      { label: "Selfie", value: "selfie" },
      { label: "CPF", value: "cpf" },
      { label: "CNPJ", value: "cnpj" },
      { label: "CNH (Frente)", value: "cnh_front" },
      { label: "CNH (Verso)", value: "cnh_back" },
      { label: "Documento de Identidade (RG)", value: "identity_document" },
      { label: "Outro", value: "other" },
    ];
  }, []);

  const documentsOptions = useMemo(() => {
    return listDocuments
      .map((document) => ({
        value: `${document.label}/*?${document.value}`,
        label: document.label,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [listDocuments]);

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  const { setLoading } = useLoading();

  const { setDocuments } = usePartner();

  const getDocuments = useCallback(async () => {
    try {
      const { data } = await getPartnerDocumentsAPI(uuid as string);

      setDocuments(data.payload);

      setLoading(false);
    } catch (e) {
      console.log(e);

      setLoading(false);
    }
  }, [setLoading, uuid, setDocuments]);

  const handleClose = () => {
    setAddDocument(false);
    resetForm();
    setFile(undefined);
  };

  const sendNewDocument = useCallback(async (): Promise<void> => {
    const newDocument: IDocumentDataPartner[] = [
      {
        partner_uuid: String(uuid),
        type: values.type.split("/*?")[1],
        description: values.type.split("/*?")[1],
        file: binaryContent,
      },
    ];
    setLoading(true);
    try {
      if (Array.isArray(newDocument) && newDocument.length > 0) {
        const documentsPromises = newDocument.map(async (document) => {
          const { type, file } = document;
          const formData = new FormData();

          if (typeof file === "string") {
            try {
              const byteCharacters = atob(file.split(",")[1]);
              const byteArray = new Uint8Array(byteCharacters.length);

              for (let i = 0; i < byteCharacters.length; i++) {
                byteArray[i] = byteCharacters.charCodeAt(i);
              }

              const blob = new Blob([byteArray], {
                type: "application/octet-stream",
              });
              formData.append("file", blob, "filename.ext");
            } catch (error) {
              console.error("Error processing base64 file:", error);
              return;
            }
          } else {
            formData.append("file", String(file));
          }

          formData.append("type", type);
          formData.append("description", type);
          formData.append("partner_uuid", String(uuid));

          return postPartnerDocumentsAPI(formData);
        });

        const results = await Promise.allSettled(documentsPromises);

        if (results[0]?.status === "fulfilled") {
          toast.success("Documento adicionado com sucesso !");

          setFile(undefined);
          setLoading(false);
          setFieldValue("type", "");
          setAddDocument(false);
          await getDocuments();
        } else {
          toast.error(
            "Não foi possível adicionar o documento ! Tente novamente mais tarde."
          );
        }
      }
    } catch (error) {
      console.error("Error in sendNewOperation:", error);
      toast.error(
        "Não foi possível adicionar o documento ! Tente novamente mais tarde."
      );
    }
    setLoading(false);
  }, [
    uuid,
    binaryContent,
    values.type,
    setFieldValue,
    getDocuments,
    setLoading,
    setAddDocument,
  ]);

  useEffect(() => {
    if (addDocument) {
      const body = bodyRef.current;
      body.style.overflow = "hidden";
    } else {
      const body = bodyRef.current;
      body.style.overflow = "";
    }
    return () => {
      const body = bodyRef.current;
      body.style.overflow = "";
    };
  }, [addDocument]);

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;

      if (target.files && target.files.length > 0) {
        const filer = target.files[0];

        setFile(filer);

        const reader = new FileReader();
        reader.onload = (event) => {
          const content = event.target?.result as string;
          setBinaryContent(content);
        };
        reader.onerror = () => {
          console.error("Erro ao ler o arquivo.");
        };

        reader.readAsDataURL(filer);

        target.value = "";
      } else {
        console.error("Nenhum arquivo selecionado.");
      }
    },
    []
  );

  return (
    <LateralModal
      open={addDocument}
      title="Enviar documentos"
      header
      toggleModal={() => handleClose()}
    >
      <Container>
        <div
          style={{
            gridColumn: "span 12",
            zIndex: zIndex,
            marginBottom: "30px",
          }}
        >
          <InputSelect
            id="type"
            name="type"
            kind="default"
            value=""
            size="large"
            label="Tipo de documento"
            placeholder={
              values.type === ""
                ? "Selecione uma opção"
                : values.type.split("/*?")[0]
            }
            onChange={(e) => handleSelect("type", e)}
            options={documentsOptions}
          />
        </div>

        <div style={{ gridColumn: "span 12" }}>
          <FileUploader
            maxlenghtindividual={1}
            size="large"
            accept=".png, .pdf, .jpeg, .jpg"
            noList
            onChange={handleFileChange}
            type="drag and top"
            headers={{
              label: "Envio de documentos",
              TextLabel:
                "O tamanho máximo do arquivo é 500kb. Os tipos de arquivo suportados são .jpg, .png. e .pdf",
            }}
          >
            <div
              style={{
                paddingTop: "10px",
              }}
            >
              Clique aqui para fazer o carregamento dos arquivos
            </div>
          </FileUploader>

          {file && (
            <div style={{ marginTop: "10px" }}>
              <FileItem
                text={String(file?.name)}
                onRemove={() => setFile(undefined)}
              />
            </div>
          )}
        </div>
        <div style={{ height: "200px" }}></div>
        <Footer>
          {" "}
          <div style={{ gridColumn: "span 6" }}>
            <Button
              kind="ghost"
              size="large"
              onClick={() => handleClose()}
              label="Cancelar"
              icon={CloseIcon}
            />
          </div>
          <div style={{ gridColumn: "span 6" }}>
            <Button
              kind="primary"
              size="large"
              icon={SaveIcon}
              onClick={() => sendNewDocument()}
              label="Adicionar documento"
              disabled={areValuesEmptyOrFalse(values) || !file}
            />
          </div>
        </Footer>

        {/* <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
        
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
           
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
        
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
          
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
        
            placeholder="Selecione uma opção"
          />
        </SelectContainer> */}
      </Container>
    </LateralModal>
  );
};

export default NewDocument;
