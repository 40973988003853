import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Breadcrumb,
  Button,
  TableBasic,
  Tooltip,
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import editIcon from "~/assets/Edit.png";
// import filterIcon from "~/assets/Filter.png";
import NewIcon from "~/assets/Addd.png";
import moneyIcon from "~/assets/Money.png";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import { formatCPFCNPJ, formatPhoneNumber } from "~/services/utils";
import useLoading from "~/hooks/useLoading";
import { getPartnersAPI, IPartner } from "~/services/api/partner";
import usePartner from "~/hooks/usePartner";
import ViewIcon from "~/assets/View.png";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const ContainerCanReadContacts = styled.div`
  width: 100%;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const Contacts: FunctionComponent = () => {
  const navigate = useNavigate();
  const { setPartners, partners } = usePartner();
  const { setLoading } = useLoading();

  const [render, setRender] = useState<boolean>(false);

  const [currentDate, setCurrentDate] = useState(() =>
    new Date().toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  );

  const [currentTime, setCurrentTime] = useState(() =>
    new Date().toLocaleString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  );

  const canReadContacts = useMemo(() => true, []);
  const canCreateContacts = useMemo(() => true, []);
  const canEditContacts = useMemo(() => true, []);
  // const canDeleteContacts = useMemo(() => true, []);

  type IDataTableItem = {
    Nome: string;
    "CPF / CNPJ": string;
    Tipo: string;
    Email: string;
    Celular: string;
    "": React.ReactNode;
  };

  const dataTable: IDataTableItem[] = useMemo(() => {
    return partners
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.map((partner: IPartner) => {
        return {
          Nome: partner.name,
          "CPF / CNPJ": formatCPFCNPJ(partner.document_number),
          Tipo:
            partner.document_type === "person"
              ? "Pessoa Física"
              : "Pessoa Jurídica",
          Email: partner.contact?.email ?? "-",
          Celular: partner.contact?.phone
            ? formatPhoneNumber(partner.contact?.phone)
            : "-",
          "": (
            <div
              style={{
                height: "20px",
                display: "flex",
              }}
            >
              {canEditContacts && (
                <Tooltip align="top" size="large" label={"Editar"}>
                  <Button
                    kind="ghost"
                    size="small"
                    icon={editIcon}
                    label=""
                    onClick={() =>
                      navigate(
                        `/app/contacts/edit/${partner.document_type}/${partner.uuid}/`
                      )
                    }
                  />
                </Tooltip>
              )}
              <Tooltip align="top" size="large" label="Visualizar detalhes">
                <Button
                  kind="ghost"
                  size="small"
                  icon={ViewIcon}
                  label=""
                  disabled={!canReadContacts}
                  onClick={() =>
                    navigate(`/app/contacts/details/${partner.uuid}/`)
                  }
                />
              </Tooltip>
            </div>
          ),
        };
      });
  }, [partners, navigate, canReadContacts, canEditContacts]);

  const fetchPartners = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await getPartnersAPI();
      setPartners(data.payload.reverse());
      setLoading(false);

      const currentDateTime = new Date();

      setCurrentDate(
        currentDateTime.toLocaleString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      );

      setCurrentTime(
        currentDateTime.toLocaleString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      );
    } catch (e) {
      console.error("Erro ao buscar contatos:", e);
      setLoading(false);
    } finally {
      setRender(true);
    }
  }, [setPartners, setLoading]);

  useEffect(() => {
    fetchPartners();
  }, [fetchPartners, canReadContacts]);

  return (
    render && (
      <Container>
        <Breadcrumb
          crumbsOptions={[{ crumb: "" }, { crumb: "Contatos" }]}
          endHiddenIndex={5}
          startHiddenIndex={5}
          aria-label="Navegação de contatos"
        />

        <ContainerCanReadContacts>
          <>
            {canReadContacts && partners?.length > 0 ? (
              <>
                <Margin mt={30} />
                <div>
                  <TableBasic
                    label="Contatos"
                    search
                    sizeSearch="large"
                    rowData={dataTable}
                    columnTitles={[
                      "Nome",
                      "CPF / CNPJ",
                      "Tipo",
                      "Email",
                      "Celular",
                      "",
                    ]}
                    pageSizes={[10, 5, 20, 50, 100]}
                    width="1000px"
                    buttons={[
                      // {
                      //   kind: "ghost",
                      //   label: "",
                      //   position: "right",
                      //   icon: filterIcon,
                      //   width: "40px",
                      //   dimension: "36",
                      // },
                      {
                        kind: "ghost",
                        label: "",
                        position: "right",
                        icon: renewIcon,
                        size: "large",
                        width: "57px",
                        dimension: "55.5",
                        onClick: () => fetchPartners(),
                      },
                      {
                        kind: "primary",
                        label: "Novo contato",
                        position: "right",
                        icon: NewIcon,
                        size: "large",
                        disabled: !canCreateContacts,

                        onClick: () => {
                          localStorage.removeItem("@:involveds");
                          localStorage.removeItem("@:simulate_operation");
                          localStorage.removeItem("@:operation");
                          localStorage.removeItem("@:documents");
                          navigate("/app/contacts/new/select-type");
                        },
                      },
                    ]}
                  />
                </div>
                <div id="lastUpdate">
                  <Margin ml={16} mt={25}>
                    <h5>
                      Atualizado em{" "}
                      <span style={{ fontWeight: "bold" }}>{currentDate}</span>,
                      às{" "}
                      <span style={{ fontWeight: "bold" }}>{currentTime}h</span>
                    </h5>
                  </Margin>
                </div>
              </>
            ) : (
              canReadContacts && (
                <div>
                  <Margin mt={70} />
                  <div>
                    <TagLup
                      src={lupaIcon}
                      aria-label="Ícone indicando que não há contatos"
                    />
                    <Text>Sem contatos</Text>
                    <Subtext>
                      Você não tem contatos para serem exibidos no momento
                    </Subtext>
                  </div>
                  <Margin mt={50} />
                  <div style={{ width: "320px" }}>
                    <Button
                      kind="primary"
                      size="medium"
                      label={"Novo contato"}
                      disabled={!canCreateContacts}
                      aria-label="Criar novo contato"
                      onClick={() => navigate("/app/contacts/new/select-type")}
                      icon={moneyIcon}
                    />
                  </div>
                </div>
              )
            )}
          </>

          {!canReadContacts && (
            <div>
              <Margin mt={70} />
              <TagLup
                src={lockIcon}
                aria-label="Ícone indicando não há permissão para visualizar as operações"
              />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para ver os contatos
                <br /> contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          )}
        </ContainerCanReadContacts>
        <div style={{ zIndex: "999" }}> </div>
      </Container>
    )
  );
};

export default Contacts;
