import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import AppStorage from "~/services/storage";

type PrivateRouterProps = {
  children: JSX.Element;
};

const PrivateRouter = ({ children }: PrivateRouterProps): JSX.Element => {
  const [cookies] = useCookies(["token_cred_engine"]);

  if (cookies.token_cred_engine) {
    AppStorage.setToken(cookies.token_cred_engine.access);
  }

  if (cookies.token_cred_engine) {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default PrivateRouter;
