import { createBrowserRouter, Navigate } from "react-router-dom";
import PublicRouter from "~/components/PublicRouter";
import DashboardStructure from "~/pages/app/DashboardStructure";
import Operations from "~/pages/app/Operations/Operations";
import SimulationCCB from "~/pages/app/Simulations/NewSimulationCCB";
import Login from "~/pages/auth/Login";

import Excluded from "~/pages/app/Excluded/Excluded";
import NewOperationCCB from "~/pages/app/Operations/NewOperation/CCB/NewOperationCCB";
import PrivateRouter from "~/components/PrivateRouter";
import ProductInfoCCB from "~/pages/app/Operations/NewOperation/CCB/ProductInfoCCB";
import OperationCCB from "~/pages/app/Operations/NewOperation/CCB/OperationCCB";
import InvolvedsCCB from "~/pages/app/Operations/NewOperation/CCB/InvolvedsCCB";
import DocumentsCCB from "~/pages/app/Operations/NewOperation/CCB/DocumentsCCB";
import Simulations from "~/pages/app/Simulations/Simulations";
import Contacts from "~/pages/app/Contacts/Contacts";
import NewContactPerson from "~/pages/app/Contacts/NewContact/NewContactPerson";
import SelectTypeContact from "~/pages/app/Contacts/SelectTypeContact";
import AddBankAccount from "~/pages/app/Contacts/NewContact/AddBankAccount";
import NewContactCompany from "~/pages/app/Contacts/NewContact/NewContactCompany";
import AddDocuments from "~/pages/app/Contacts/NewContact/AddDocuments";
import Templates from "~/pages/app/Templates/Templates";
import NewTemplate from "~/pages/app/Templates/NewTemplate";
import EditTemplate from "~/pages/app/Templates/EditTemplate";
import OperationsDetails from "~/pages/app/Operations/OperationsDetails/OperationsDetails";

import SubscriberDetails from "~/pages/app/Subscriber/NewSubscriber/SubscriberDetails";
import NewSubscriber from "~/pages/app/Subscriber/NewSubscriber/NewSubscriber";
import BasicDataSubscriber from "~/pages/app/Subscriber/NewSubscriber/BasicDataSubscriber";
import InvolvedsSubscriber from "~/pages/app/Subscriber/NewSubscriber/InvolvedsSubscriber";
import SelectTypeOperation from "~/pages/app/Operations/NewOperation/SelectTypeOperation";
import ContactsDetails from "~/pages/app/Contacts/Details/Details";
import NewOperations from "~/pages/app/Operations/NewOperation/ComercialNote/NewOperation";
import ProductInfo from "~/pages/app/Operations/NewOperation/ComercialNote/ProductInfo";
import Operation from "~/pages/app/Operations/NewOperation/ComercialNote/Operation";
import Involveds from "~/pages/app/Operations/NewOperation/ComercialNote/Involveds";
import Documents from "~/pages/app/Operations/NewOperation/ComercialNote/Documents";
import DocumentsSubscriber from "~/pages/app/Subscriber/NewSubscriber/DocumentsSubscriber";
import SelectTypeSimulation from "~/pages/app/Simulations/SelectTypeSimulation";
import SimulationCommercialNote from "~/pages/app/Simulations/NewSimulationCommercialNote";
import EditContactPerson from "~/pages/app/Contacts/EditContact/EditContactPerson";
import EditContactCompany from "~/pages/app/Contacts/EditContact/EditContactCompany";
import DetailsSimulationCommercialNote from "~/pages/app/Simulations/Details/CommercialNote";
import DetailsSimulationCCB from "~/pages/app/Simulations/Details/CCB";

const defaultOptions = {
  errorElement: <></>,
};

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRouter>
        <DashboardStructure />
      </PrivateRouter>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/app/credit/operations" replace />,
      },
    ],

    ...defaultOptions,
  },
  {
    path: "/login",
    element: (
      <PublicRouter>
        <Login />
      </PublicRouter>
    ),
    ...defaultOptions,
  },
  {
    path: "/app",

    element: (
      <PrivateRouter>
        <DashboardStructure />
      </PrivateRouter>
    ),

    ...defaultOptions,
    children: [
      {
        index: true,
        element: <Navigate to="/app/credit/operations" replace />,
      },

      {
        path: "panel",
        element: (
          <PrivateRouter>
            <Operations />
          </PrivateRouter>
        ),
      },

      {
        path: "credit/assignments",
        element: (
          <PrivateRouter>
            <></>
          </PrivateRouter>
        ),
      },
      {
        path: "credit/excludeds",
        element: (
          <PrivateRouter>
            <Excluded />
          </PrivateRouter>
        ),
      },
      {
        path: "contacts",
        element: (
          <PrivateRouter>
            <Contacts />
          </PrivateRouter>
        ),
      },
      {
        path: "contacts/details/:uuid",
        element: (
          <PrivateRouter>
            <ContactsDetails />
          </PrivateRouter>
        ),
      },
      {
        path: "contacts/edit/person/:uuid",
        element: (
          <PrivateRouter>
            <EditContactPerson />
          </PrivateRouter>
        ),
      },
      {
        path: "contacts/edit/company/:uuid",
        element: (
          <PrivateRouter>
            <EditContactCompany />
          </PrivateRouter>
        ),
      },
      {
        path: "contacts/new/select-type",
        element: (
          <PrivateRouter>
            <SelectTypeContact />
          </PrivateRouter>
        ),
      },
      {
        path: "contacts/new/person/basic-data",
        element: (
          <PrivateRouter>
            <NewContactPerson />
          </PrivateRouter>
        ),
      },
      {
        path: "contacts/new/company/basic-data",
        element: (
          <PrivateRouter>
            <NewContactCompany />
          </PrivateRouter>
        ),
      },
      {
        path: "contacts/new/person/:uuid/bank-data",
        element: (
          <PrivateRouter>
            <AddBankAccount />
          </PrivateRouter>
        ),
      },
      {
        path: "contacts/new/company/:uuid/bank-data",
        element: (
          <PrivateRouter>
            <AddBankAccount />
          </PrivateRouter>
        ),
      },
      {
        path: "contacts/new/person/:uuid/documents",
        element: (
          <PrivateRouter>
            <AddDocuments />
          </PrivateRouter>
        ),
      },
      {
        path: "contacts/new/company/:uuid/documents",
        element: (
          <PrivateRouter>
            <AddDocuments />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/operations",
        element: (
          <PrivateRouter>
            <Operations />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/operations/details/:uuid",
        element: (
          <PrivateRouter>
            <OperationsDetails />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/operations/details/:uuid/subscriber/new",
        element: (
          <PrivateRouter>
            <NewSubscriber />
          </PrivateRouter>
        ),
        ...defaultOptions,
        children: [
          {
            path: "basic-data",
            element: (
              <PrivateRouter>
                <BasicDataSubscriber />
              </PrivateRouter>
            ),
          },
          {
            path: ":id/involveds",
            element: (
              <PrivateRouter>
                <InvolvedsSubscriber />
              </PrivateRouter>
            ),
          },
          {
            path: ":id/documents",
            element: (
              <PrivateRouter>
                <DocumentsSubscriber />
              </PrivateRouter>
            ),
          },
        ],
      },
      {
        path: "credit/operations/details/:uuid/subscriber/:id",
        element: (
          <PrivateRouter>
            <SubscriberDetails />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/operations/new/select-type",
        element: (
          <PrivateRouter>
            <SelectTypeOperation />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/operations/new/ccb",
        element: (
          <PrivateRouter>
            <NewOperationCCB />
          </PrivateRouter>
        ),
        ...defaultOptions,
        children: [
          {
            path: "product-info",
            element: (
              <PrivateRouter>
                <ProductInfoCCB />
              </PrivateRouter>
            ),
          },
          {
            path: "operation",
            element: (
              <PrivateRouter>
                <OperationCCB />
              </PrivateRouter>
            ),
          },
          {
            path: "involveds",
            element: (
              <PrivateRouter>
                <InvolvedsCCB />
              </PrivateRouter>
            ),
          },
          {
            path: "documents",
            element: (
              <PrivateRouter>
                <DocumentsCCB />
              </PrivateRouter>
            ),
          },
        ],
      },
      {
        path: "credit/operations/new/commercial-note",
        element: (
          <PrivateRouter>
            <NewOperations />
          </PrivateRouter>
        ),
        ...defaultOptions,
        children: [
          {
            path: "product-info",
            element: (
              <PrivateRouter>
                <ProductInfo />
              </PrivateRouter>
            ),
          },
          {
            path: "operation",
            element: (
              <PrivateRouter>
                <Operation />
              </PrivateRouter>
            ),
          },
          {
            path: "involveds",
            element: (
              <PrivateRouter>
                <Involveds />
              </PrivateRouter>
            ),
          },
          {
            path: "documents",
            element: (
              <PrivateRouter>
                <Documents />
              </PrivateRouter>
            ),
          },
        ],
      },
      {
        path: "credit/new-simulate/",
        element: (
          <PrivateRouter>
            <SelectTypeSimulation />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/new-simulate/ccb",
        element: (
          <PrivateRouter>
            <SimulationCCB />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/new-simulate/commercial-note",
        element: (
          <PrivateRouter>
            <SimulationCommercialNote />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/simulations",
        element: (
          <PrivateRouter>
            <Simulations />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/simulations/commercial-note/details/:uuid",
        element: (
          <PrivateRouter>
            <DetailsSimulationCommercialNote />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/simulations/ccb/details/:uuid",
        element: (
          <PrivateRouter>
            <DetailsSimulationCCB />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/templates",
        element: (
          <PrivateRouter>
            <Templates />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/templates/new",
        element: (
          <PrivateRouter>
            <NewTemplate />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/templates/:id",
        element: (
          <PrivateRouter>
            <EditTemplate />
          </PrivateRouter>
        ),
      },
    ],
  },
]);

export default AppRouter;
