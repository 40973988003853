import Margin from "~/components/Margin";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputSelect,
  TableBasic,
} from "prosperita-dumbo-react";
import MoreIcon from "~/assets/Addd.png";
import "prosperita-dumbo-react/dist/style.css";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";

import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import ArrowIcon from "~/assets/arrow-right-_1_.png";

import useLoading from "~/hooks/useLoading";

import { toast } from "react-toastify";

import {
  getPartnersDetailsAPI,
  IPartner,
  postPartnerBankAccountAPI,
} from "~/services/api/partner";

import Stepper from "~/components/Stepper";
import LateralModal from "~/components/LateralModal";

// import MoreIcon from "~/assets/Addd.png";
import CloseIcon from "~/assets/Close--outline.png";
import SaveIcon from "~/assets/Save.png";
import { banksOptions } from "~/utils/banks";
import { getBankAccountsAPI, IBankAccount } from "~/services/api/bankAccount";

const Footer = styled.div`
  width: 90%;
  position: fixed;
  bottom: 0;
  display: grid;
  padding-bottom: 50px;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

type Values = {
  agency: string;
  agency_digit: string;
  account_number: string;
  account_number_digit: string;
  bank_identification_code: string;
  partner_uuid: string;
};

const initialValues: Values = {
  agency: "",
  agency_digit: "",
  account_number: "",
  account_number_digit: "",
  bank_identification_code: "",
  partner_uuid: "",
};

const AddBankAccount = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { setLoading } = useLoading();
  const [addBankAccount, setAddBankAccount] = useState(false);
  const location = useLocation();

  const route = location.pathname;

  const { values, setFieldValue, resetForm } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const canDoSimulations = true;

  const steps = [
    {
      label: "Informações",
      isCompleted: true,
      isActive: false,
    },
    {
      label: "Dados bancários",
      isCompleted: false,
      isActive: true,
    },
    { label: "Documentos", isCompleted: false, isActive: false },
  ];

  const banks = banksOptions.map((item) => ({
    value: `${item.code}/*?${item.name}`,
    label: item.name,
  }));

  const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);

  const [partner, setPartner] = useState<IPartner>();

  const getBankAccount = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await getBankAccountsAPI(uuid as string);

      setBankAccounts(data.payload.reverse());

      setLoading(false);
    } catch (e) {
      console.log(e);

      setLoading(false);
    }
  }, [setLoading, uuid]);

  const handleClose = useCallback(() => {
    resetForm();
    setAddBankAccount(false);
  }, [setAddBankAccount, resetForm]);

  const fetchPartner = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await getPartnersDetailsAPI(uuid as string);

      setPartner(data.payload);

      setLoading(false);
    } catch (e) {
      console.log(e);

      setLoading(false);
    }
  }, [setLoading, uuid]);

  const createBankAccount = useCallback(async () => {
    const payload = {
      agency: values.agency,
      agency_digit: values.agency_digit,
      account_number: values.account_number,
      account_number_digit: values.account_number_digit,
      bank_code: values.bank_identification_code.split("/*?")[0],
      partner_uuid: uuid,
    };
    setLoading(true);

    try {
      await postPartnerBankAccountAPI(payload);

      toast.success("Conta bancária adicionada com sucesso");
      handleClose();
      getBankAccount();
      setLoading(false);
    } catch (e) {
      console.log(e);

      toast.error(
        "Não foi possível adicioanr a conta bancária. Tente novamente"
      );
      setLoading(false);
    }
  }, [values, setLoading, getBankAccount, uuid, handleClose]);

  const canViewOperations = true;

  useEffect(() => {
    getBankAccount();

    fetchPartner();
  }, [getBankAccount, setFieldValue, fetchPartner]);

  const dataTableBankAccounts = useMemo(() => {
    return bankAccounts?.map((bankAccount: IBankAccount) => ({
      Banco: `${bankAccount.bank_code} - ${bankAccount.bank_name}`,
      Agência: bankAccount?.agency,
      Conta: `${bankAccount.account_number}-${bankAccount.account_number_digit}`,
    }));
  }, [bankAccounts]);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          {
            crumb: "Contatos",
            onClick: () => navigate("/app/contacts"),
          },
          { crumb: "Novo Contato" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div
        style={{
          marginTop: "30px",
          gap: "10px",
          width: "100%",
          zIndex: "4",
          padding: "10px",
          background: "#fff",
        }}
      >
        <h4>{partner?.name}</h4>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>{" "}
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              kind="primary"
              icon={ArrowIcon}
              label={
                bankAccounts.length > 0
                  ? "Avançar"
                  : "Continuar sem conta bancária"
              }
              size="large"
              onClick={() =>
                navigate(
                  `/app/contacts/new/${route.includes("person") ? "person" : "company"}/${uuid}/documents`
                )
              }
            />
          </div>
        </div>
        <Stepper steps={steps} />
        <Margin mb={10} />
      </div>

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <h2
            style={{
              margin: "10px 0 0 45px ",
              color: "#000",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "40px",
            }}
          >
          Operações de crédito
          </h2> */}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          <>
            {canViewOperations && bankAccounts?.length > 0 ? (
              <>
                <div>
                  <Margin mt={30} />
                  <Text style={{ fontSize: "25px" }}>Contas Bancárias</Text>
                  <Margin mt={50} />
                  <div>
                    <TableBasic
                      pageSizes={[10, 5, 20, 50, 100]}
                      search
                      columnTitles={["Banco", "Agência", "Conta"]}
                      rowData={dataTableBankAccounts || []}
                      width="1000px"
                      sizeSearch="large"
                      buttons={[
                        {
                          kind: "ghost",
                          label: "",
                          position: "right",
                          icon: renewIcon,
                          size: "large",
                          width: "57px",
                          dimension: "55.5",
                          // onClick: () => getInvolvedFromStorage(),
                        },
                        {
                          kind: "primary",
                          size: "large",
                          icon: MoreIcon,
                          label: "Adicionar conta bancária",
                          position: "right",

                          onClick: () => setAddBankAccount(true),
                        },
                      ]}
                    />
                  </div>
                </div>
                <Margin mt={50} />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div></div>
                </div>
              </>
            ) : (
              canViewOperations &&
              bankAccounts?.length === 0 && (
                <div>
                  <Margin mt={70} />
                  <div>
                    <TagLup src={lupaIcon} />
                    <Text>Nenhuma conta bancária cadastrada</Text>
                  </div>
                  <Margin mt={50} />
                  <div style={{ width: "320px" }}>
                    <Button
                      kind="primary"
                      size="medium"
                      icon={MoreIcon}
                      label={"Adicionar conta bancária"}
                      onClick={() => setAddBankAccount(true)}
                    />
                  </div>
                </div>
              )
            )}
          </>
        </>

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para visualizar as contas bancárias{" "}
                <br /> contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
      <Margin mt={50} />
      {/* <div
        style={{
          display: "flex",
          gap: "10px",
          width: "100%",
          justifyContent: "end",
        }}
      >
        <Button
          kind="primary"
          icon={ArrowIcon}
          label={
            bankAccounts.length > 0 ? "Avançar" : "Continuar sem conta bancária"
          }
          size="large"
          onClick={() => navigate(`/app/contacts/new/person/${uuid}/documents`)}
        />
      </div> */}
      <div style={{ zIndex: "999" }}>
        <LateralModal
          open={addBankAccount}
          title="Adicionar conta bancária"
          header
          toggleModal={() => {
            handleClose();
          }}
        >
          <div style={{ padding: "20px" }}>
            <InputSelect
              label="Banco"
              kind="default"
              name="bank_identification_code"
              size="small"
              onChange={(e) =>
                setFieldValue("bank_identification_code", e.value)
              }
              options={banks}
              value=""
              placeholder={
                values.bank_identification_code !== ""
                  ? values.bank_identification_code?.split("/*?")[1]
                  : "Selecione"
              }
            />
            <Margin mb={10} />
            <Input
              max={5}
              maxLength={5}
              name="agency"
              size="large"
              label="Agência"
              value={values.agency}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, "");
                setFieldValue("agency", numericValue);
              }}
            />{" "}
            <Margin mb={10} />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "15px",
                rowGap: "30px",
                height: "400px",
              }}
            >
              <Input
                name="account_number"
                size="large"
                max={10}
                maxLength={10}
                label="Conta"
                value={values.account_number}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, "");
                  setFieldValue("account_number", numericValue);
                }}
              />
              <Input
                max={1}
                maxLength={1}
                size="large"
                label="Dígito"
                value={values.account_number_digit}
                name="account_number_digit"
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, "");
                  setFieldValue("account_number_digit", numericValue);
                }}
              />
            </div>
            <Footer>
              {" "}
              <div style={{ gridColumn: "span 6" }}>
                <Button
                  kind="ghost"
                  size="large"
                  onClick={() => handleClose()}
                  label="Cancelar"
                  icon={CloseIcon}
                />
              </div>
              <div style={{ gridColumn: "span 6" }}>
                <Button
                  kind="primary"
                  size="large"
                  icon={SaveIcon}
                  onClick={() => createBankAccount()}
                  label="Salvar"
                  disabled={
                    !values.account_number ||
                    !values.agency ||
                    !values.bank_identification_code ||
                    !values.account_number_digit
                  }
                />
              </div>
            </Footer>
          </div>
        </LateralModal>
      </div>
    </Container>
  );
};

export default AddBankAccount;
