import Margin from "~/components/Margin";
import { useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputCurrency,
  Modal,
  Table,
  Tile,
  Tooltip,
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";

import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import EditIcon from "~/assets/Edit.png";
import openOperationIcon from "~/assets/side-panel--open.png";
import {
  getCreditProductDetailsAPI,
  IProductDetails,
} from "~/services/api/creditProduct";
import useOperation from "~/hooks/useOperation";

import {
  getSimulateOperationAPI,
  // getSimulateOperationAPI,
  Installment,
  ISimulateOperationBody,
  ISimulateOperationInstallmentPatch,
  patchSimulateOperationAPI,
} from "~/services/api/operations";
import {
  convertStringToNumber,
  convertStringCurrencyToNumber,
  formatNumberToCurrencyWithoutSimbol,
} from "~/services/utils";

import useLoading from "~/hooks/useLoading";
import { format, isAfter, isBefore, isEqual, parseISO } from "date-fns";
import { toast } from "react-toastify";
// import MoreIcon from "~/assets/Addd.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

type Values = {
  nickname: string;
  product_uuid: string;
  calculation_type: string;
  requested_amount: string;
  duration: string;
  calculation_base: string;
  operation_date: string;
  release_date: string;
  due_date: string;
  interest_rate_monthly: string;
  interest_periodicity: string;
  amortization_grace_period: string;
  interest_grace_period: string;
  commission: string;
  indexer: string;
  indexer_rate: string;
  amortization_amount: string | number;
  due_date_installment: string;
  interest: string | number;
  unit_amount: string;
  number: number;
  outstanding_amount: string | number;
  pmt: string | number;
};

const initialValues: Values = {
  nickname: "",
  product_uuid: "",
  calculation_type: "",
  requested_amount: "10.000,00",
  duration: "",
  calculation_base: "",
  operation_date: "",
  release_date: "",
  due_date: "",
  interest_rate_monthly: "",
  interest_periodicity: "",
  amortization_grace_period: "",
  interest_grace_period: "",
  commission: "",
  unit_amount: "1.000,00",
  indexer: "",
  indexer_rate: "100",
  amortization_amount: 0,
  due_date_installment: "",
  interest: "",
  number: 0,
  outstanding_amount: "",
  pmt: "",
};

const DetailsSimulationCommercialNote = () => {
  const navigate = useNavigate();

  const { uuid } = useParams();

  const {
    setPayloadSimulateOperation,
    setSimulateResult,
    simulateResult,
    editInstallment,
    setEditInstallment,
    installmentData,
    setInstallmentData,
  } = useOperation();

  const [previousInstallment, setPreviousInstallment] =
    useState<Installment | null>(null);

  const { setLoading } = useLoading();

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const titlesColumnInstallments = [
    "Parcela",
    "Data",
    "Valor nominal",
    "Juros + indexador",
    "Amortização",
    "Preço unitário",
    "",
  ];

  const dataTableInstallments = simulateResult?.installments.map(
    (installment, index) => {
      const isFirstInstallment = index === 0;
      const previousInstallmentData =
        index > 0 ? simulateResult?.installments[index - 1] : null;
      return {
        Parcela: installment.number,
        Data: format(parseISO(installment.due_date), "dd/MM/yyyy"),
        "Valor nominal": `R$ ${formatNumberToCurrencyWithoutSimbol(
          installment.outstanding_amount
        )}`,
        "Juros + indexador": `R$ ${formatNumberToCurrencyWithoutSimbol(installment.interest)}`,
        Amortização: `R$ ${formatNumberToCurrencyWithoutSimbol(
          installment.amortization_amount
        )}`,
        "Preço unitário": `R$ ${formatNumberToCurrencyWithoutSimbol(installment.pmt)}`,
        "": isFirstInstallment ? null : (
          <div
            style={{
              display: "flex",
              position: "relative",
              left: "-60px",
              height: "8px",
            }}
          >
            <Tooltip
              align={
                simulateResult.product.amortization_type === "sac"
                  ? "top-right"
                  : "top-right"
              }
              size="large"
              label={
                simulateResult.product.amortization_type !== "sac"
                  ? "Não é possivel editar parcelas quando a amortização não é do tipo SAC"
                  : "Editar parcela"
              }
            >
              <Button
                kind="ghost"
                size="small"
                icon={EditIcon}
                // disabled={simulateResult.product.amortization_type !== "sac"}
                onClick={() => {
                  setFieldValue(
                    "interest",
                    formatNumberToCurrencyWithoutSimbol(installment.interest)
                  );
                  setFieldValue(
                    "amortization_amount",
                    formatNumberToCurrencyWithoutSimbol(
                      installment.amortization_amount
                    )
                  );
                  setFieldValue("due_date_installment", installment.due_date);
                  setPreviousInstallment(previousInstallmentData);
                  setInstallmentData(installment);
                  setEditInstallment(true);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    }
  );

  const canDoSimulations = true;

  const indexesDict: { [key: string]: string } = {
    igpm: "IGPM",
    selic: "SELIC",
    ipca: "IPCA",
    cdi: "CDI",
    no_one: "Nenhum",
  };

  const payloadSimulateOperation: ISimulateOperationBody = useMemo(() => {
    const taxesSettings: ISimulateOperationBody["taxes_settings"] = {
      interest_rate: values.interest_rate_monthly?.includes(",")
        ? convertStringToNumber(values.interest_rate_monthly)
        : Number(values.interest_rate_monthly),
      interest_periodicity: values.interest_periodicity,
      amortization_grace_period: Number(values.amortization_grace_period),
      interest_grace_period: Number(values.interest_grace_period),
      commission: values.commission?.includes(",")
        ? convertStringToNumber(values.commission)
        : Number(values.commission),
      ...(values.indexer !== "no_one"
        ? {
            indexer: values.indexer,
            indexer_rate: values.indexer_rate?.includes(",")
              ? convertStringToNumber(values.indexer_rate)
              : Number(values.indexer_rate),
          }
        : {}),
    };

    return {
      nickname: values.nickname,
      product_uuid: values.product_uuid,
      calculation_type: values.calculation_type,
      requested_amount: convertStringToNumber(values.requested_amount),
      unit_amount: convertStringToNumber(values.unit_amount),
      duration: Number(values.duration),
      calculation_base: values.calculation_base,
      release_date: values.release_date,
      due_date: values.due_date,
      taxes_settings: taxesSettings,
    };
  }, [values]);

  const sendUpdateInstallment = useCallback(async () => {
    if (simulateResult && installmentData) {
      setLoading(true);
      const payload: ISimulateOperationInstallmentPatch = {
        installment_number: installmentData.number,
      };

      const amortizationAmount = convertStringCurrencyToNumber(
        values.amortization_amount as string
      );
      if (
        amortizationAmount !== installmentData.amortization_amount ||
        (amortizationAmount === 0 && installmentData.amortization_amount !== 0)
      ) {
        payload.amortization = amortizationAmount;
      }

      const interestAmount = convertStringCurrencyToNumber(
        values.interest as string
      );
      if (
        interestAmount !== installmentData.interest ||
        (interestAmount === 0 && installmentData.interest !== 0)
      ) {
        payload.interest = interestAmount;
      }

      if (values.due_date_installment !== installmentData.due_date) {
        payload.due_date = values.due_date_installment;
      }

      if (Object.keys(payload).length > 1) {
        try {
          const { data } = await patchSimulateOperationAPI(
            simulateResult.uuid,
            payload
          );

          setLoading(false);
          setEditInstallment(false);
          setSimulateResult(data.payload);
          toast.success("Parcela atualizada com sucesso!");
        } catch (e) {
          console.log(e);
          setLoading(false);
          toast.error("Não foi possível alterar a parcela!");
        }
      } else {
        setLoading(false);
        setEditInstallment(false);
      }
    }
  }, [
    simulateResult,
    installmentData,
    setLoading,
    values,
    setEditInstallment,
    setSimulateResult,
  ]);

  const fetchGetSimulation = useCallback(async () => {
    setLoading(true);

    try {
      if (uuid) {
        const { data } = await getSimulateOperationAPI(uuid);

        const simulation = data.payload;

        setSimulateResult(simulation);

        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error("Não foi possível recuperar a simulação !");
      setLoading(false);
    }
  }, [setLoading, setSimulateResult, uuid]);

  const fetchGetSimulationToOperation = useCallback(async () => {
    setLoading(true);
    localStorage.removeItem("@:involveds");
    localStorage.removeItem("@:simulate_operation");
    localStorage.removeItem("@:operation");
    localStorage.removeItem("@:simulation_uuid");
    localStorage.removeItem("@:documents");

    try {
      if (simulateResult?.uuid) {
        const { data } = await getSimulateOperationAPI(simulateResult?.uuid);

        const simulation = data.payload;
        setSimulateResult(null);
        localStorage.setItem(
          "@:simulation_uuid",
          JSON.stringify({ simulation_uuid: simulateResult?.uuid })
        );

        const payload = {
          nickname: simulation.nickname,
          product_uuid: simulation.product.uuid,
          calculation_type: simulation.calculation_type,
          requested_amount: simulation.requested_amount,
          duration: Number(simulation.duration),
          calculation_base: simulation.calculation_base,
          release_date: simulation.release_date,
          due_date: simulation.due_date,
          unit_amount: simulation.unit_amount,
          taxes_settings: {
            interest_rate_monthly:
              simulation.taxes_settings.interest_rate_monthly,
            interest_rate: simulation.taxes_settings.interest_rate_monthly,
            interest_periodicity:
              simulation.taxes_settings.interest_periodicity,
            amortization_grace_period:
              simulation.taxes_settings.amortization_grace_period,
            interest_grace_period:
              simulation.taxes_settings.interest_grace_period,
            commission: simulation.taxes_settings.commission,
            indexer: simulation.taxes_settings.indexer,
            indexer_rate: simulation.taxes_settings.indexer_rate,
          },
        };

        localStorage.setItem("@:simulate_operation", JSON.stringify(payload));

        navigate("/app/credit/operations/new/commercial-note/product-info");
        setSimulateResult(null);

        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error("Não foi possível recuperar a simulação !");
      setLoading(false);
    }
  }, [navigate, setLoading, setSimulateResult, simulateResult?.uuid]);

  const printRef = useRef<HTMLDivElement>(null);

  const [errorDueDateInstallment, setErrorDueDateInstallment] =
    useState<boolean>(false);
  const [errorDueDateToLongInstallment, setErrorDueDateToLongInstallment] =
    useState<boolean>(false);
  const [
    errorAmortizationInstallmentAmount,
    setErrorAmortizationInstallmentAmount,
  ] = useState<boolean>(false);
  const [errorInterestAmountInstallment, setErrorInterestAmountInstallment] =
    useState<boolean>(false);

  useEffect(() => {
    const currentDueDate = parseISO(values.due_date_installment);

    if (previousInstallment) {
      const previousInstallmentDueDate = parseISO(previousInstallment.due_date);

      if (isBefore(currentDueDate, previousInstallmentDueDate)) {
        setErrorDueDateInstallment(true);
      } else {
        setErrorDueDateInstallment(false);
      }

      if (
        simulateResult?.due_date &&
        (isAfter(currentDueDate, parseISO(simulateResult?.due_date)) ||
          isEqual(currentDueDate, parseISO(simulateResult?.due_date)))
      ) {
        setErrorDueDateToLongInstallment(true);
      } else {
        setErrorDueDateToLongInstallment(false);
      }

      const currentAmortizationAmount = convertStringCurrencyToNumber(
        values.amortization_amount
      );
      const previousInstallmentOutstandingAmount = Number(
        previousInstallment?.outstanding_amount || 0
      );

      if (
        currentAmortizationAmount < 0 ||
        currentAmortizationAmount > previousInstallmentOutstandingAmount
      ) {
        setErrorAmortizationInstallmentAmount(true);
      } else {
        setErrorAmortizationInstallmentAmount(false);
      }

      const currentInterestAmortizationAmount = convertStringCurrencyToNumber(
        values.interest || 0
      );
      const currentInterestAmount = Number(installmentData?.interest || 0);

      if (
        currentInterestAmortizationAmount < 0 ||
        currentInterestAmortizationAmount > currentInterestAmount
      ) {
        setErrorInterestAmountInstallment(true);
      } else {
        setErrorInterestAmountInstallment(false);
      }
    }
  }, [values, previousInstallment, installmentData]);

  useEffect(() => {
    fetchGetSimulation();
  }, [fetchGetSimulation]);

  useEffect(() => {
    if (installmentData) {
      setFieldValue("outstanding_amount", installmentData.outstanding_amount);
      setFieldValue("due_date_installment", installmentData.due_date);
      setFieldValue("pmt", String(installmentData.pmt));
      setFieldValue(
        "interest",
        formatNumberToCurrencyWithoutSimbol(installmentData.interest)
      );
      setFieldValue(
        "amortization_amount",
        formatNumberToCurrencyWithoutSimbol(installmentData.amortization_amount)
      );
    }
  }, [installmentData, setFieldValue]);

  useEffect(() => {
    setPayloadSimulateOperation(payloadSimulateOperation);
    localStorage.setItem(
      "@:simulate_operation",
      JSON.stringify(payloadSimulateOperation)
    );
  }, [payloadSimulateOperation, setPayloadSimulateOperation]);

  const [productDetails, setProductDetails] = useState<IProductDetails>();

  const fetchProductDetails = useCallback(async (uuid: string) => {
    try {
      const { data } = await getCreditProductDetailsAPI(uuid);
      setProductDetails(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (values.product_uuid) {
      fetchProductDetails(values.product_uuid);
    }
  }, [values.product_uuid, fetchProductDetails]);

  useEffect(() => {
    if (values.indexer === "no_one") {
      setFieldValue("indexer_rate", "0");
    } else if (values.indexer !== "no_one" && values.indexer_rate === "0") {
      setFieldValue("indexer_rate", "100");
    }
  }, [values, setFieldValue]);

  useEffect(() => {
    if (productDetails) {
      setFieldValue(
        "interest_rate_monthly",
        productDetails?.min_interest_rate !== undefined &&
          String(productDetails?.min_interest_rate)
      );

      setFieldValue(
        "amortization_grace_period",
        productDetails?.min_amortization_period !== undefined &&
          String(productDetails?.min_amortization_period)
      );

      setFieldValue(
        "interest_grace_period",
        productDetails?.min_amortization_period !== undefined &&
          String(productDetails?.min_amortization_period)
      );
    }
    setFieldValue("duration", "1");
    setFieldValue("commission", "0");
  }, [productDetails, setFieldValue]);

  useEffect(() => {
    setSimulateResult(null);
  }, [setSimulateResult]);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          {
            crumb: "Simulações",
            onClick: () => navigate("/app/credit/simulations"),
          },
          { crumb: "Detalhes" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />
      <Margin mb={30} />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Text style={{ fontSize: "25px" }}>
          Simulação Nota Comercial{" "}
          {simulateResult?.uuid && `- ID: ${simulateResult.uuid}`}
        </Text>
        <Button
          kind={"primary"}
          label="Abrir operação"
          size="large"
          icon={openOperationIcon}
          onClick={() => fetchGetSimulationToOperation()}
        />
      </div>
      <Margin mb={20} />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        ></div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          {canDoSimulations && (
            <>
              <Margin mt={50} />

              {simulateResult?.calculation_type && (
                <div ref={printRef}>
                  <h4>Resultados da simulação</h4>
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "32px",
                      rowGap: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Data de emissão
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.operation_date
                            ? format(
                                parseISO(simulateResult?.operation_date),
                                "dd/MM/yyyy"
                              )
                            : "-"}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Data de vencimento
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.due_date
                            ? format(
                                parseISO(simulateResult?.due_date),
                                "dd/MM/yyyy"
                              )
                            : "-"}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Qtde. de amortizações
                        </h4>
                        <Margin mt={17} />
                        <p>{simulateResult?.duration}</p>
                      </div>
                    </Tile>

                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Valor do contrato
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {" "}
                          R${" "}
                          {formatNumberToCurrencyWithoutSimbol(
                            simulateResult?.requested_amount ?? 0
                          )}
                        </p>
                      </div>
                    </Tile>

                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Custo da emissão
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {" "}
                          R${" "}
                          {formatNumberToCurrencyWithoutSimbol(
                            simulateResult?.taxes_values?.emission_cost ?? 0
                          )}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Indexador
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.taxes_settings?.indexer
                            ? indexesDict[
                                simulateResult?.taxes_settings?.indexer
                              ]
                            : "-"}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Taxa A.M.
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.taxes_settings.interest_rate_monthly?.toFixed(
                            4
                          )}{" "}
                          %
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Taxa A.A.
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.taxes_settings.interest_rate_annually?.toFixed(
                            4
                          )}{" "}
                          %
                        </p>
                      </div>
                    </Tile>
                  </div>
                </div>
              )}
            </>
          )}

          <Margin mt={50} />
          {simulateResult?.calculation_type && (
            <div ref={printRef}>
              {" "}
              <Table
                pageSizes={[10, 5, 20, 50, 100]}
                columnTitles={titlesColumnInstallments}
                rowData={dataTableInstallments ?? []}
                width="1000px"
                radius
                // buttons={[
                //   {
                //     kind: "primary",
                //     position: "right",
                //     label: "Adicionar parcelas",
                //     icon: MoreIcon,
                //   },
                // ]}
              />
            </div>
          )}
        </>

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para visualizar as simulações <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
      {/* 
      <LateralModal
        toggleModal={() => null}
        open
        title="Novo"
        header
      ></LateralModal> */}

      <Modal
        height="600px"
        width="755px"
        background="#f7f4f4"
        onClose={() => setEditInstallment(false)}
        label={
          <div>
            <h4>
              {" "}
              Simulação Nota Comercial{" "}
              {simulateResult?.uuid ? ` - ID: ${simulateResult.uuid}` : ""}
            </h4>
            <h4>Parcela {installmentData?.number}</h4>
          </div>
        }
        buttons={[
          {
            kind: "ghost",
            label: "Cancelar",
            onClick: () => setEditInstallment(false),
          },
          {
            kind: "primary",
            label: "Salvar",
            disabled:
              errorAmortizationInstallmentAmount ||
              errorDueDateInstallment ||
              errorInterestAmountInstallment ||
              errorDueDateToLongInstallment ||
              !values.due_date_installment,
            onClick: () => {
              sendUpdateInstallment();
            },
          },
        ]}
        open={editInstallment}
        radius
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
            padding: "20px",
          }}
        >
          <InputCurrency
            label="Valor para amortização"
            name="amortization_amount"
            id="amortization_amount"
            size="medium"
            error={errorAmortizationInstallmentAmount}
            message={
              errorAmortizationInstallmentAmount
                ? `O valor para amortização não pode ser superior à R$ ${previousInstallment ? formatNumberToCurrencyWithoutSimbol(previousInstallment?.outstanding_amount) : ""}`
                : ""
            }
            onChange={(e) =>
              setFieldValue("amortization_amount", e.target.value)
            }
            value={values.amortization_amount}
            disabled={
              Number(installmentData?.number) + 1 ===
              simulateResult?.installments?.length
            }
          />
          <InputCurrency
            name="interest"
            id="interest"
            label="Juros + indexador"
            onChange={(e) => setFieldValue("interest", e.target.value)}
            value={values.interest}
            error={errorInterestAmountInstallment}
            message={
              errorInterestAmountInstallment
                ? `O valor do juros não pode ser superior à R$ ${installmentData?.interest ? formatNumberToCurrencyWithoutSimbol(installmentData?.interest) : formatNumberToCurrencyWithoutSimbol(0)}`
                : ""
            }
            disabled={
              Number(installmentData?.number) + 1 ===
              simulateResult?.installments?.length
            }
          />

          <Input
            name="due_date_installment"
            id="due_date_installment"
            size="large"
            type="date"
            error={errorDueDateInstallment || errorDueDateToLongInstallment}
            message={
              errorDueDateInstallment
                ? `A data de vencimento não pode ser anterior à ${previousInstallment?.due_date ? format(parseISO(previousInstallment?.due_date), "dd/MM/yyyy") : ""}`
                : errorDueDateToLongInstallment
                  ? `A data de vencimento não pode ser igual ou superior à ${simulateResult?.due_date ? format(parseISO(simulateResult?.due_date), "dd/MM/yyyy") : ""}`
                  : ""
            }
            label="Data de vencimento"
            onChange={(e) => {
              setFieldValue("due_date_installment", e.target.value);
            }}
            value={values.due_date_installment}
          />

          <Input
            name="outstanding_amount"
            id="outstanding_amount"
            size="large"
            label="Saldo devedor"
            readOnly
            placeholder={`R$ ${formatNumberToCurrencyWithoutSimbol(
              values.outstanding_amount
            )}`}
            onChange={(e) =>
              setFieldValue("outstanding_amount", e.target.value)
            }
          />
        </div>
      </Modal>
    </Container>
  );
};

export default DetailsSimulationCommercialNote;
