import { Button, Modal, TableBasic, Tooltip } from "prosperita-dumbo-react";
import styled from "styled-components";
import Margin from "~/components/Margin";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import useOperation from "~/hooks/useOperation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { InvolvedData } from "~/types";
import { getPartnersAPI } from "~/services/api/partner";
import usePartner from "~/hooks/usePartner";
import useLoading from "~/hooks/useLoading";
import ArrowIcon from "~/assets/arrow-right-_1_.png";
import ArrowLeft from "~/assets/arrow-lftt-_1_.png";
import WhiteLeft from "~/assets/white-left.png";
import TrashIcon from "~/assets/Trash-can.png";
import { useNavigate } from "react-router-dom";
import { IOperationInvolved } from "~/services/api/operations";
import AppStorage from "~/services/storage";
import MoreIcon from "~/assets/Addd.png";

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const titlesColumnInvolveds = [
  "Nome",
  "Relacionado à",
  "Tipo de relação",
  "Assinante",
  "% da assinatura",
  "Forma de envio",
  "Forma de autenticação",
  "",
];

const Involveds = () => {
  const {
    setAddInvolved,
    setTab,
    involvedsRender,
    setInvolvedsRender,
    setRemoveInvolved,
    removeInvolved,
  } = useOperation();
  const { setPartners } = usePartner();
  const navigate = useNavigate();
  const [involved, setInvolved] = useState<{ name: string; uuid: string }>({
    name: "",
    uuid: "",
  });
  const { setLoading } = useLoading();
  const [hover, setHover] = useState<boolean>(false);

  const canViewOperations = true;

  const dataTableInvolveds = useMemo(() => {
    return involvedsRender?.map((involved: InvolvedData) => ({
      Nome: involved?.involved_partner_name,
      "Relacionado à": involved?.related_to_name,
      "Tipo de relação": involved?.relationship_name,
      Assinante: involved.is_signer === "true" ? "Sim" : "Não",
      "% da assinatura":
        involved.is_signer === "true" ? `${involved.sign_weight} %` : "-",
      "Forma de envio": involved?.send_type_name,
      "Forma de autenticação": involved.auth_type_name,
      "": (
        <div>
          <Tooltip align="top" size="medium" label="Remover">
            <img
              src={TrashIcon}
              loading="lazy"
              alt="Deletar"
              onClick={() => {
                setInvolved({
                  name: involved?.involved_partner_name,
                  uuid: involved.involved_partner_uuid,
                });
                setRemoveInvolved(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    }));
  }, [involvedsRender, setRemoveInvolved]);

  const getInvolvedFromStorage = useCallback(() => {
    setLoading(true);

    const data = localStorage.getItem("@:involveds");
    if (data) {
      setInvolvedsRender(JSON.parse(data));
    } else {
      setInvolvedsRender([]);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [setInvolvedsRender, setLoading]);

  const fetchPartners = useCallback(async () => {
    try {
      const { data } = await getPartnersAPI();
      setPartners(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setPartners]);

  const checkHasDebtor = () => {
    const payloadOperation = AppStorage.get("@:operation") as string;
    const payloadOperationParsed = JSON?.parse(payloadOperation);

    return involvedsRender.some(
      (item: IOperationInvolved) =>
        item.involved_partner_uuid === payloadOperationParsed.debtor_uuid
    );
  };

  const handleClose = () => {
    setRemoveInvolved(false);
    setInvolved({ name: "", uuid: "" });
  };
  const removeInvolvedByUuid = (uuid: string) => {
    try {
      const involvedData: InvolvedData[] = JSON.parse(
        localStorage.getItem("@:involveds") || "[]"
      );

      const updatedInvolvedData = involvedData.filter(
        (involved) => involved.involved_partner_uuid !== uuid
      );

      localStorage.setItem("@:involveds", JSON.stringify(updatedInvolvedData));

      handleClose();
      getInvolvedFromStorage();
    } catch (error) {
      console.error("Erro ao remover o envolvido:", error);
    }
  };

  useEffect(() => {
    fetchPartners();
    getInvolvedFromStorage();
    setTab(2);
  }, [setTab, getInvolvedFromStorage, fetchPartners]);

  return (
    <div style={{ width: "100%" }}>
      {!checkHasDebtor() && (
        <h4 style={{ color: "red" }}>
          Atenção: Para prosseguir, é necessário adicionar o tomador como
          envolvido.
        </h4>
      )}
      <>
        {canViewOperations && dataTableInvolveds?.length > 0 ? (
          <>
            <div>
              <Margin mt={30} />

              <div>
                <TableBasic
                  search
                  columnTitles={titlesColumnInvolveds}
                  rowData={dataTableInvolveds}
                  pageSizes={[5, 10, 20, 50, 100]}
                  width="1000px"
                  sizeSearch="large"
                  buttons={[
                    {
                      kind: "ghost",
                      label: "",
                      position: "right",
                      icon: renewIcon,
                      size: "large",
                      width: "57px",
                      dimension: "55.5",
                      onClick: () => getInvolvedFromStorage(),
                    },
                    {
                      kind: "primary",
                      size: "large",
                      icon: MoreIcon,
                      label: "Adicionar envolvido",
                      position: "right",

                      onClick: () => setAddInvolved(true),
                    },
                  ]}
                />
              </div>
            </div>
          </>
        ) : (
          canViewOperations && (
            <div>
              <Margin mt={70} />
              <div>
                <TagLup src={lupaIcon} />
                <Text>Sem Envolvidos</Text>
                <Subtext>
                  Você não tem envolvidos para serem exibidos no momento
                </Subtext>
              </div>
              <Margin mt={50} />
              <div style={{ width: "320px" }}>
                <Button
                  kind="primary"
                  size="medium"
                  icon={MoreIcon}
                  label={"Adicionar envolvido"}
                  onClick={() => setAddInvolved(true)}
                />
              </div>
            </div>
          )
        )}
      </>
      <Margin mt={100} />
      <div
        style={{
          gridColumn: "1 / -1",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <Button
            kind="ghost"
            size="large"
            label="Cancelar"
            onClick={() => navigate("/app/credit/operations/")}
          />
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Button
              kind="tertiary"
              size="large"
              label="Voltar"
              icon={hover ? WhiteLeft : ArrowLeft}
              onClick={() => {
                navigate(
                  "/app/credit/operations/new/commercial-note/product-info/"
                );
                setTab(2);
              }}
            />
          </div>
          <div>
            <Button
              kind="primary"
              size="large"
              label="Avançar"
              icon={ArrowIcon}
              disabled={!checkHasDebtor()}
              onClick={() => {
                navigate(
                  "/app/credit/operations/new/commercial-note/documents/"
                );
                setTab(3);
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        height={"468px"}
        width="960px"
        background="#f4f4f4"
        label={<h3>Remover envolvido</h3>}
        radius
        onClose={() => {
          handleClose();
        }}
        open={removeInvolved}
        buttons={[
          { kind: "ghost", label: "Cancelar", onClick: () => handleClose() },
          {
            kind: "primary",
            label: "Sim, remover",
            onClick: () => removeInvolvedByUuid(involved?.uuid),
          },
        ]}
      >
        <div style={{ padding: "50px" }}>
          <h2>Deseja realmente remover o envolvido {involved?.name} ? </h2>
        </div>
      </Modal>
    </div>
  );
};

export default Involveds;
