import { InvolvedData } from "~/types";
import ApiService from "../api";

export type IBodySubscriber = {
  asset_uuid?: string;
  partner_uuid: string;
  quantity_of_units_acquired: number;
};

export type IBodySubscriberInvolved = {
  relationship: string;
  auth_type: string;
  sign_weight: number | string;
  is_signer: boolean;
  related_to_uuid: string;
  involved_partner_uuid: string;
  subscriber_uuid: string;
  send_type: string;
};

export type ISubscriber = {
  uuid: string;
  asset_uuid: string;
  unit_amount_acquired: number;
  status: string;
  quantity_of_units_acquired: number;
  subscription_date: string;
  partner: CompanyPartner | PersonPartner;
  involveds: InvolvedData[];
  documents: unknown[];
};

export type CompanyPartner = {
  uuid: string;
  type: "Contact";
  name: string;
  document_number: string;
  document_type: "company";
  contact: {
    email: string;
    phone: string;
  };
  address: Address;
  foundation_date: string;
  cnae: string;
  fantasy_name: string;
  legal_nature: string;
  municipal_registration: string;
  state_registration: string;
  website: string;
  business_sector: string;
  employer_code: string;
  b3_account: string;
  subscriber_type: string | null;
  finantial_info: {
    id: number;
    initial_share_capital: number;
    net_equity: number;
    number_of_employees: number;
    average_monthly_revenue: number;
    average_gross_profit: number;
    exportation_percentage: number;
    suppliers_cost: number;
    tax_cost: number;
    last_12_months_revenue: number;
    leased_or_financed_value: number;
    arrangement: number;
    payroll_value: number;
    headquarters_location: string;
    pmv: number;
    funding_potential: number;
    late_sales_percentage: number;
  };
};

export type PersonPartner = {
  uuid: string;
  type: "Contact";
  name: string;
  document_number: string;
  document_type: "person";
  contact: {
    email: string;
    phone: string;
  };
  address: Address;
  birth_date: string;
  nationality: string;
  marital_status: string;
  occupation: string;
  social_name: string;
  birth_country: string;
  mother_name: string;
  father_name: string;
  gender: "male" | "female" | string;
  health_declaration: "true" | "false";
  identification_document: {
    document_type: string | null;
    document: string | null;
    emission_date: string | null;
    expiration_date: string | null;
    pep: boolean;
    issuing_state: string;
  };
  professional_info: {
    occupation_nature: string | null;
    employment_status: string | null;
    net_salary: number;
    additional_income: number;
    hiring_date: string | null;
    employee_number: string | null;
    department: string | null;
  };
  parents: unknown[];
};

export type Address = {
  zip_code: string;
  street: string;
  number: string;
  district: string;
  city: string;
  state: string;
  country: string;
  additional_info: string;
  type: "commercial" | "residential" | string;
};

type Installment = {
  number: number;
  date: string;
  interest_amortization: number;
  principal_amortization: number;
  total_to_receive: number;
};

export type InstallmentsData = {
  installments: Installment[];
};

export const getSubscriberAPI = (id: string) => {
  return ApiService.HttpGet<IResponse<ISubscriber>>({
    route: `/asset/commercial-note/subscriber/detail/${id}/`,
    token: true,
  });
};

export const postSubscriberAPI = (body: IBodySubscriber) => {
  return ApiService.HttpPost<IResponse<ISubscriber>>({
    route: `/asset/commercial-note/subscriber/`,
    token: true,
    body,
  });
};

export const postSubscriberConfirmationAPI = (subscriber_uuid: string) => {
  return ApiService.HttpPost<IResponse<unknown>>({
    route: `/asset/commercial-note/subscriber/confirmation/`,
    token: true,
    body: { subscriber_uuid },
  });
};

export const postSubscriberInvolvedAPI = (body: IBodySubscriberInvolved) => {
  return ApiService.HttpPost<IResponse<unknown>>({
    route: `/asset/commercial-note/involved/`,
    token: true,
    body,
  });
};

export const deleteSubscriberInvolvedAPI = (id: string) => {
  return ApiService.HttpDelete<IResponse<unknown>>({
    route: `/asset/commercial-note/involved/${id}/`,
    token: true,
  });
};

export const deleteSubscriberDocumentAPI = (id: string) => {
  return ApiService.HttpDelete<IResponse<unknown>>({
    route: `/asset/commercial-note/document/${id}/`,
    token: true,
  });
};

export const postSimulateSubscriberAPI = (body: IBodySubscriber) => {
  return ApiService.HttpPost<IResponse<InstallmentsData>>({
    route: `/asset/commercial-note/subscriber/simulation/`,
    token: true,
    body,
  });
};

export const deleteSubscriberIvolvedAPI = (id: string) => {
  return ApiService.HttpDelete<IResponse<unknown>>({
    route: `/asset/commercial-note/involved/${id}/`,
    token: true,
  });
};

const boundary = "----WebKitFormBoundary7MA4YWxkTrZu0gW";

export const postSubscriberDocumentAPI = (body) => {
  return ApiService.HttpPost<IResponse<unknown>>({
    route: `/asset/commercial-note/document/`,
    token: true,
    body,
    customHeaders: {
      "Content-Type": `multipart/form-data; boundary=${boundary}`,
    },
  });
};
