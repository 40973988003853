import Margin from "~/components/Margin";
import { useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputSelect,
  Radio,
  Tooltip,
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";

import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  formatCEP,
  formatCPFCNPJ,
  formatPhoneNumber,
  getLabelByValue,
  onlyNumbers,
} from "~/services/utils";

import useLoading from "~/hooks/useLoading";

import { toast } from "react-toastify";
import {
  documentTypes,
  genderOptionsMore,
  maritalStatusOptions,
  residenceOptions,
  ufOptions,
} from "~/utils/options";
import { getPartnersDetailsAPI, patchPartnerAPI } from "~/services/api/partner";
import { getCepAPI } from "~/services/api/cep";
import { isValidFullName } from "~/services/validators";
import SaveIcon from "~/assets/Save.png";
import documentNumberValidator from "~/services/validateDocumentNumber";
import { isDateInThePast, validateAge } from "~/services/validateBirthDate";
import usePartner from "~/hooks/usePartner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

type Values = {
  name: string;
  document_number: string;
  document_type: string;
  owner_uuid: string;
  contact_email: string;
  contact_phone: string;
  address_zip_code: string;
  address_street: string;
  address_number: string;
  address_district: string;
  address_city: string;
  address_state: string;
  address_country: string;
  address_type: string;
  identification_document_document_type: string;
  identification_document_document: string;
  identification_document_emission_date: string;
  identification_document_expiration_date: string;
  identification_document_pep: boolean;
  identification_document_issuing_state: string;
  professional_info_occupation_partner_company_uuid: string;
  professional_info_occupation_nature: string;
  professional_info_employment_status: string;
  professional_info_net_salary: string;
  professional_info_additional_income: string;
  professional_info_hiring_date: string;
  professional_info_employee_number: string;
  professional_info_department: string;
  parents_parent_type: string;
  parents_name: string;
  parents_document_number: string;
  parents_company_document_number: string;
  parents_occupation: string;
  parents_phone: string;
  parents_birth_date: string;
  parents_net_salary: string;
  parents_additional_income: string;
  health_declaration: boolean;
  birth_date: string;
  nationality: string;
  marital_status: string;
  occupation: string;
  social_name: string;
  birth_country: string;
  mother_name: string;
  father_name: string;
  gender: string;
  type: string;
};

const initialValues: Values = {
  name: "",
  document_number: "",
  document_type: "",
  owner_uuid: "",
  contact_email: "",
  contact_phone: "",
  address_zip_code: "",
  address_street: "",
  address_number: "",
  address_district: "",
  address_city: "",
  address_state: "",
  address_country: "",
  address_type: "",
  identification_document_document_type: "",
  identification_document_document: "",
  identification_document_emission_date: "",
  identification_document_expiration_date: "",
  identification_document_pep: false,
  identification_document_issuing_state: "",
  professional_info_occupation_partner_company_uuid: "",
  professional_info_occupation_nature: "",
  professional_info_employment_status: "",
  professional_info_net_salary: "",
  professional_info_additional_income: "",
  professional_info_hiring_date: "",
  professional_info_employee_number: "",
  professional_info_department: "",
  parents_parent_type: "",
  parents_name: "",
  parents_document_number: "",
  parents_company_document_number: "",
  parents_occupation: "",
  parents_phone: "",
  parents_birth_date: "",
  parents_net_salary: "",
  parents_additional_income: "",
  health_declaration: false,
  birth_date: "",
  nationality: "Brasileira",
  marital_status: "",
  occupation: "",
  social_name: "",
  birth_country: "",
  mother_name: "",
  father_name: "",
  gender: "",
  type: "",
};

const EditContactPerson = () => {
  const navigate = useNavigate();
  const [errorCep, setErrorCep] = useState<boolean>(false);
  const { setLoading } = useLoading();
  const [error, setError] = useState<string>("");

  const { uuid } = useParams();
  const { setPartner, partner } = usePartner();

  console.log(partner);

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const canDoSimulations = true;

  const isValidDocument = useMemo(() => {
    return (
      values.document_number.length > 13 &&
      documentNumberValidator(values.document_number)
    );
  }, [values.document_number]);

  interface ContactInfo {
    email: string;
    phone: string;
  }

  interface AddressInfo {
    zip_code: string;
    street: string;
    number: string;
    district: string;
    city: string;
    state: string;
    country: string;
    type: string;
  }

  interface IdentificationDocument {
    document_type?: string | null;
    document?: string | null;
    emission_date?: string | null;
    expiration_date?: string | null;
    pep?: boolean | null;
    issuing_state?: string;
  }

  interface Payload {
    owner_uuid?: string;
    name: string;

    contact: ContactInfo;
    address: AddressInfo;
    identification_document?: IdentificationDocument;

    health_declaration: string;
    birth_date: string;
    nationality: string;
    marital_status: string;
    occupation: string;
    social_name: string;
    birth_country: string;
    mother_name: string;
    father_name: string;
    gender: string;
  }

  const editContactPerson = useCallback(async () => {
    const payload: Payload = {
      name: values.name,

      contact: {
        email: values.contact_email,
        phone: onlyNumbers(values.contact_phone),
      },
      address: {
        zip_code: onlyNumbers(values.address_zip_code),
        street: values.address_street,
        number: values.address_number,
        district: values.address_district,
        city: values.address_city,
        state: values.address_state,
        country: "Brasil",
        type: values.address_type,
      },
      health_declaration: String(values.health_declaration),
      birth_date: values.birth_date,
      nationality: values.nationality,
      marital_status: values.marital_status,
      occupation: values.occupation,
      social_name: values.social_name,
      birth_country: values.birth_country,
      mother_name: values.mother_name,
      father_name: values.father_name,
      gender: values.gender,
    };

    const identificationDocument: IdentificationDocument = {};

    if (values.identification_document_issuing_state) {
      identificationDocument.issuing_state =
        values.identification_document_issuing_state;
    }
    if (values.identification_document_document_type)
      identificationDocument.document_type =
        values.identification_document_document_type;
    if (values.identification_document_document)
      identificationDocument.document = values.identification_document_document;
    if (values.identification_document_emission_date)
      identificationDocument.emission_date =
        values.identification_document_emission_date;
    if (values.identification_document_expiration_date)
      identificationDocument.expiration_date =
        values.identification_document_expiration_date;
    if (values.identification_document_pep)
      identificationDocument.pep = values.identification_document_pep;

    if (Object.keys(identificationDocument).length > 1) {
      payload.identification_document = identificationDocument;
    }

    setLoading(true);

    try {
      const { data } = await patchPartnerAPI(payload, String(uuid));

      localStorage.setItem("@:partner_uuid", data.payload.uuid);
      toast.success("Dados atualizados com sucesso !");
      setLoading(false);
      navigate(`/app/contacts`);
    } catch (e) {
      console.log(e);

      toast.error("Não foi possível atualizar os dados. Tente novamente");
      setLoading(false);
    }
  }, [values, setLoading, navigate, uuid]);

  const fetchPartner = useCallback(async () => {
    setPartner(null);
    setLoading(true);
    try {
      if (uuid) {
        const { data } = await getPartnersDetailsAPI(uuid);

        setPartner(data.payload);

        setLoading(false);
      } else {
        console.error("UUID is undefined");
        setLoading(false);
      }
    } catch (err) {
      console.error("Erro ao buscar detalhes da operação:", err);
      setLoading(false);
    }
  }, [uuid, setLoading, setPartner]);

  const fetchCEP = useCallback(
    async (cep: string): Promise<void> => {
      try {
        const { data } = await getCepAPI(cep);

        if (data.erro) {
          setErrorCep(true);
          setFieldValue("address_street", "");
          setFieldValue("address_district", "");
          setFieldValue("address_city", "");
          setFieldValue("address_state", "Selecione");
        } else {
          setErrorCep(false);
          setFieldValue("address_street", data.logradouro);
          setFieldValue("address_district", data.bairro);
          setFieldValue("address_city", data.localidade);
          setFieldValue("address_state", data.uf);
        }
      } catch (e) {
        console.error("Erro ao buscar contatos:", e);
        setErrorCep(false);
      }
    },
    [setFieldValue]
  );

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (partner) {
      setFieldValue("name", partner.name || "");
      setFieldValue(
        "document_number",
        formatCPFCNPJ(partner.document_number) || ""
      );
      setFieldValue("document_type", partner.document_type || "");
      setFieldValue("owner_uuid", partner.uuid || "");
      setFieldValue("contact_email", partner.contact?.email || "");
      setFieldValue(
        "contact_phone",
        formatPhoneNumber(partner.contact?.phone) || ""
      );
      setFieldValue(
        "address_zip_code",
        formatCEP(partner.address?.zip_code) || ""
      );
      setFieldValue("address_street", partner.address?.street || "");
      setFieldValue("address_number", partner.address?.number || "");
      setFieldValue("address_district", partner.address?.district || "");
      setFieldValue("address_city", partner.address?.city || "");
      setFieldValue("address_state", partner.address?.state || "");
      setFieldValue("address_country", partner.address?.country || "");
      setFieldValue("address_type", partner.address?.type || "");
      setFieldValue(
        "identification_document_document_type",
        partner.identification_document?.document_type || ""
      );
      setFieldValue(
        "identification_document_document",
        partner.identification_document?.document || ""
      );
      setFieldValue(
        "identification_document_emission_date",
        partner.identification_document?.emission_date || ""
      );
      setFieldValue(
        "identification_document_expiration_date",
        partner.identification_document?.expiration_date || null
      );
      setFieldValue(
        "identification_document_pep",
        partner.identification_document?.pep || false
      );
      setFieldValue(
        "identification_document_issuing_state",
        partner.identification_document?.issuing_state || ""
      );

      setFieldValue(
        "health_declaration",
        partner.health_declaration === "true" || false
      );
      setFieldValue("birth_date", partner.birth_date || "");
      setFieldValue("nationality", partner.nationality || "");
      setFieldValue("marital_status", partner.marital_status || "");
      setFieldValue("occupation", partner.occupation || "");
      setFieldValue("social_name", partner.social_name || "");
      setFieldValue("birth_country", partner.birth_country || "");
      setFieldValue("mother_name", partner.mother_name || "");
      setFieldValue("father_name", partner.father_name || "");
      setFieldValue("gender", partner.gender || "");
    }
  }, [partner, setFieldValue]);

  useEffect(() => {
    if (values.address_zip_code.length === 9) {
      fetchCEP(onlyNumbers(values.address_zip_code));
    }
  }, [values.address_zip_code, fetchCEP]);

  useEffect(() => {
    if (values.address_zip_code.length === 8) {
      setErrorCep(false);
    }
  }, [values.address_zip_code]);

  useEffect(() => {
    localStorage.removeItem("@:partner_uuid");
    fetchPartner();
  }, [fetchPartner]);

  useEffect(() => {
    if (values?.document_number.length <= 13 && !isValidDocument) {
      setError("initialState");
    } else if (values?.document_number.length >= 13 && isValidDocument) {
      setError("notError");
    } else if (values?.document_number.length > 13 && !isValidDocument) {
      setError("Documento inválido");
    }
  }, [values.document_number, setError, isValidDocument]);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          {
            crumb: "Contatos",
            onClick: () => navigate("/app/contacts"),
          },
          { crumb: "Editar" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <h2
            style={{
              margin: "10px 0 0 45px ",
              color: "#000",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "40px",
            }}
          >
          Operações de crédito
          </h2> */}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          {canDoSimulations && (
            <>
              <>
                <Margin mb={30} />
                <div
                  style={{
                    position: "sticky",
                    top: 113,

                    gap: "10px",
                    width: "100%",
                    zIndex: "3",
                    padding: "10px",
                    background: "#fff",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      kind={"ghost"}
                      label="Cancelar"
                      size="large"
                      onClick={() => navigate("/app/contacts")}
                    />
                    <div style={{ display: "flex", gap: "10px" }}>
                      {/* <Button
                        kind={"tertiary"}
                        label="Voltar"
                        size="large"
                        onClick={() =>
                          navigate("/app/contacts/new/select-type")
                        }
                      /> */}
                      <Button
                        kind="primary"
                        icon={SaveIcon}
                        label="Salvar"
                        disabled={
                          (values.identification_document_emission_date &&
                            values.identification_document_emission_date
                              .length > 9 &&
                            !isDateInThePast(
                              values.identification_document_emission_date
                            )) ||
                          (values.identification_document_expiration_date &&
                            values.identification_document_expiration_date
                              .length > 9 &&
                            isDateInThePast(
                              values.identification_document_expiration_date
                            )) ||
                          !isValidFullName(values?.name) ||
                          values.document_number.length < 11 ||
                          !validateEmail(values.contact_email) ||
                          !values.marital_status ||
                          !validateAge(values.birth_date) ||
                          values.birth_date.length < 10 ||
                          !values.gender ||
                          !isValidDocument ||
                          values.contact_phone.length !== 15 ||
                          !isValidFullName(values?.father_name) ||
                          !isValidFullName(values?.mother_name) ||
                          !values.nationality ||
                          !validateEmail(values?.contact_email) ||
                          !values.contact_phone ||
                          !values.address_zip_code ||
                          !values.address_type ||
                          !values.address_street ||
                          !values.address_state ||
                          !values.address_number ||
                          !values.address_city ||
                          !values.address_district ||
                          !values.birth_date
                        }
                        size="large"
                        onClick={() => editContactPerson()}
                      />
                    </div>
                  </div>

                  <Margin mb={10} />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Margin mb={30} />
                    <Text style={{ fontSize: "25px" }}>
                      Editar Contato Pessoa Física{" "}
                    </Text>
                    <Margin mb={20} />
                    <h4>Dados básicos</h4>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr 1fr",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <Tooltip
                      align="top"
                      size="large"
                      label="Não é possível editar este dado"
                    >
                      <Input
                        size="large"
                        name="document_number"
                        disabled
                        label={"* CPF"}
                        placeholder="000.000.000-00"
                        value={values.document_number}
                        maxLength={14}
                        error={
                          values.document_number.length > 13 && !isValidDocument
                        }
                        message={
                          values.document_number.length > 13 && !isValidDocument
                            ? error
                            : ""
                        }
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/\D/g, "");
                          if (value.length <= 11) {
                            value = value
                              .replace(/^(\d{3})(\d)/, "$1.$2")
                              .replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3")
                              .replace(
                                /^(\d{3})\.(\d{3})\.(\d{3})(\d)/,
                                "$1.$2.$3-$4"
                              );
                          }

                          setFieldValue("document_number", value);
                        }}
                      />
                    </Tooltip>
                    <Input
                      size="large"
                      name="name"
                      label="* Nome Completo"
                      placeholder="Digite"
                      value={values.name}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                    />
                    <Input
                      size="large"
                      name="social_name"
                      label="Nome Social (Se aplicável)"
                      placeholder="Digite"
                      required
                      value={values.social_name}
                      onChange={(e) =>
                        setFieldValue("social_name", e.target.value)
                      }
                    />
                  </>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        name="marital_status"
                        label="* Estado Civil"
                        options={maritalStatusOptions}
                        placeholder={
                          values.marital_status
                            ? getLabelByValue(
                                values.marital_status,
                                maritalStatusOptions
                              )
                            : "Escolha uma opção"
                        }
                        onChange={(e) =>
                          setFieldValue("marital_status", e.value)
                        }
                      />
                    </div>
                    <Input
                      size="large"
                      type="date"
                      name="birth_date"
                      label="* Data de Nascimento"
                      placeholder="dd/mm/aaaa"
                      message={
                        values.birth_date.length > 9 &&
                        !validateAge(values.birth_date)
                          ? "O contato deve ser maior de 18 anos"
                          : ""
                      }
                      error={
                        values.birth_date.length > 9 &&
                        !validateAge(values.birth_date)
                      }
                      value={values.birth_date}
                      onChange={(e) =>
                        setFieldValue("birth_date", e.target.value)
                      }
                    />
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        name="gender"
                        label="* Sexo"
                        value=""
                        placeholder={
                          values.gender
                            ? getLabelByValue(values.gender, genderOptionsMore)
                            : "Escolha uma opção"
                        }
                        options={genderOptionsMore}
                        onChange={(e) => setFieldValue("gender", e.value)}
                      />
                    </div>
                    <Input
                      size="large"
                      name="father_name"
                      label="* Nome completo do Pai"
                      placeholder="Digite"
                      value={values.father_name}
                      onChange={(e) =>
                        setFieldValue("father_name", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      name="mother_name"
                      label="* Nome completo da Mãe"
                      placeholder="Digite"
                      value={values.mother_name}
                      onChange={(e) =>
                        setFieldValue("mother_name", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      name="nationality"
                      label="* Nacionalidade"
                      placeholder="Escolha uma opção"
                      value={values.nationality}
                      onChange={(e) =>
                        setFieldValue("nationality", e.target.value)
                      }
                    />
                    {/* <InputSelect
                      size="large"
                      kind="default"
                      name="birth_country"
                      label="País de Nascimento"
                      placeholder="Escolha uma opção"
                      onChange={(e) => setFieldValue("birth_country", e)}
                    /> */}
                  </>
                </div>
                <Margin mt={50} />
              </>
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h4>Dados de contato</h4>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <Input
                      size="large"
                      name="contact_email"
                      label="* E-mail"
                      placeholder="contato@mail.com"
                      value={values.contact_email}
                      onChange={(e) =>
                        setFieldValue("contact_email", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      maxLength={15}
                      name="contact_phone"
                      label="* Celular"
                      placeholder="(00) 00000-0000"
                      value={values.contact_phone}
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^\d]/g, "");
                        if (value.length > 0 && value.length <= 2) {
                          value = `(${value}`;
                        } else if (value.length > 2 && value.length <= 6) {
                          value = `(${value.slice(0, 2)}) ${value.slice(2)}`;
                        } else if (value.length > 6) {
                          value = `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
                        }

                        setFieldValue("contact_phone", value);
                      }}
                    />
                  </>
                </div>
                <Margin mt={50} />
              </>
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h4>Endereço</h4>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <Input
                      size="large"
                      name="address_zip_code"
                      max={9}
                      maxLength={9}
                      label="* CEP"
                      placeholder="Digite"
                      value={values.address_zip_code}
                      error={errorCep && values.address_zip_code.length === 9}
                      message={
                        errorCep && values.address_zip_code.length === 9
                          ? "CEP inválido"
                          : ""
                      }
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^\d]/g, "");

                        if (value.length <= 5) {
                          value = `${value.slice(0, 5)}`;
                        } else if (value.length <= 8) {
                          value = `${value.slice(0, 5)}-${value.slice(5, 8)}`;
                        }

                        setFieldValue("address_zip_code", value);
                      }}
                    />
                    <div style={{ gridColumn: "span 2" }}>
                      <Input
                        size="large"
                        name="address_street"
                        label="* Endereço"
                        disabled={!values.address_zip_code}
                        placeholder="Digite"
                        value={values.address_street}
                        onChange={(e) =>
                          setFieldValue("address_street", e.target.value)
                        }
                      />
                    </div>
                    <Input
                      max={7}
                      maxLength={7}
                      size="large"
                      name="address_number"
                      label="* Número"
                      placeholder="Digite"
                      disabled={!values.address_zip_code}
                      value={values.address_number}
                      onChange={(e) =>
                        setFieldValue("address_number", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      name="address_district"
                      label="* Bairro"
                      placeholder="Digite"
                      disabled={!values.address_zip_code}
                      value={values.address_district}
                      onChange={(e) =>
                        setFieldValue("address_district", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      name="address_city"
                      label="* Cidade"
                      placeholder="Digite"
                      disabled={!values.address_zip_code}
                      value={values.address_city}
                      onChange={(e) =>
                        setFieldValue("address_city", e.target.value)
                      }
                    />
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        name="address_state"
                        disabled={!values.address_zip_code}
                        label="* Estado"
                        placeholder={
                          values.address_state ? values.address_state : "UF"
                        }
                        options={ufOptions}
                        onChange={(e) =>
                          setFieldValue("address_state", e.value)
                        }
                      />
                    </div>
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        disabled={!values.address_zip_code}
                        name="address_type"
                        label="* Tipo de residência"
                        value=""
                        placeholder={
                          values.gender
                            ? getLabelByValue(
                                values.address_type,
                                residenceOptions
                              )
                            : "Escolha uma opção"
                        }
                        options={residenceOptions}
                        onChange={(e) => setFieldValue("address_type", e.value)}
                      />
                    </div>
                  </>
                </div>
                <Margin mt={50} />
              </>

              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h4>Documento de identificação</h4>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <div>
                      {" "}
                      <InputSelect
                        size="small"
                        kind="default"
                        name="identification_document_document_type"
                        label="Tipo de Documento"
                        options={documentTypes}
                        placeholder={
                          values.identification_document_document_type
                            ? getLabelByValue(
                                values.identification_document_document_type,
                                documentTypes
                              )
                            : "Escolha uma opção"
                        }
                        value=""
                        onChange={(e) =>
                          setFieldValue(
                            "identification_document_document_type",
                            e.value
                          )
                        }
                      />
                    </div>

                    <Input
                      size="large"
                      max={11}
                      maxLength={11}
                      name="identification_document_document"
                      label="Documento de Identidade"
                      placeholder="Digite"
                      value={values.identification_document_document}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        inputValue = inputValue.replace(/[^0-9Xx]/g, "");

                        inputValue = inputValue.toUpperCase();

                        if (inputValue.includes("X")) {
                          inputValue = inputValue.slice(
                            0,
                            inputValue.indexOf("X") + 1
                          );
                        }

                        setFieldValue(
                          "identification_document_document",
                          inputValue
                        );
                      }}
                    />
                    <Input
                      size="large"
                      type="date"
                      name="identification_document_emission_date"
                      label="Data de Emissão"
                      placeholder="dd/mm/aaaa"
                      message={
                        values.identification_document_emission_date.length >
                          9 &&
                        !isDateInThePast(
                          values.identification_document_emission_date
                        )
                          ? "A data de emissão não pode ser uma data futura"
                          : ""
                      }
                      error={
                        values.identification_document_emission_date.length >
                          9 &&
                        !isDateInThePast(
                          values.identification_document_emission_date
                        )
                      }
                      value={values.identification_document_emission_date}
                      onChange={(e) =>
                        setFieldValue(
                          "identification_document_emission_date",
                          e.target.value
                        )
                      }
                    />
                    <Input
                      size="large"
                      type="date"
                      name="identification_document_expiration_date"
                      label="Data de Expiração"
                      message={
                        values?.identification_document_expiration_date
                          ?.length > 9 &&
                        isDateInThePast(
                          values?.identification_document_expiration_date
                        )
                          ? "A data de expiração não pode ser uma data passada "
                          : ""
                      }
                      error={
                        values?.identification_document_expiration_date
                          ?.length > 9 &&
                        isDateInThePast(
                          values.identification_document_expiration_date
                        )
                      }
                      placeholder="dd/mm/aaaa"
                      value={values.identification_document_expiration_date}
                      onChange={(e) =>
                        setFieldValue(
                          "identification_document_expiration_date",
                          e.target.value
                        )
                      }
                    />
                    <InputSelect
                      size="small"
                      kind="default"
                      name="identification_document_issuing_state"
                      label="Estado emissor"
                      placeholder={
                        values.identification_document_issuing_state
                          ? getLabelByValue(
                              values.identification_document_issuing_state,
                              ufOptions
                            )
                          : "UF"
                      }
                      value=""
                      options={ufOptions}
                      onChange={(e) =>
                        setFieldValue(
                          "identification_document_issuing_state",
                          e.value
                        )
                      }
                    />
                    <div>
                      {" "}
                      <div style={{ position: "relative", top: "10px" }}>
                        <span style={{ fontSize: "12px", color: "#666" }}>
                          Pessoa políticamente exposta (PEP) ?
                        </span>
                        <Margin mt={10} />
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              position: "relative",
                              left: "-5px",
                              justifyContent: "start",
                            }}
                          >
                            <Radio
                              name="identification_document_pep"
                              label={"Não"}
                              placeholder="Escolha uma opção"
                              checked={!values.identification_document_pep}
                              onClick={() =>
                                setFieldValue(
                                  "identification_document_pep",
                                  false
                                )
                              }
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              position: "relative",
                              left: "10px",
                              justifyContent: "start",
                            }}
                          >
                            <Radio
                              name="identification_document_pep"
                              label={"Sim"}
                              placeholder="Escolha uma opção"
                              checked={values.identification_document_pep}
                              onClick={() =>
                                setFieldValue(
                                  "identification_document_pep",
                                  true
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
                <Margin mt={50} />
              </>
            </>
          )}

          <Margin mt={50} />

          {/* 
          <div>
            <h4>Declaração de saúde</h4>
            <Margin mt={20} />

            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignContent: "start",
                alignItems: "start",
                cursor: "pointer",
              }}
              onClick={() =>
                setFieldValue("health_declaration", !values.health_declaration)
              }
            >
              <div
                style={{
                  width: "800px",
                  display: "inline",
                }}
              >
                <div
                  style={{
                    display: "inline",
                  }}
                >
                  <Checkbox checked={values.health_declaration} />
                </div>
                <div
                  style={{
                    display: "inline",
                  }}
                >
                  <p>
                    {" "}
                    Declaro que possuo boas condições de saúde e que não
                    apresento nenhuma das doenças listadas
                  </p>{" "}
                </div>
              </div>
            </div>
          </div> */}
        </>

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão editar contatos
                <br /> contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default EditContactPerson;
