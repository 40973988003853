import constate from "constate";
import { useState } from "react";
import { ICreditProducts } from "~/services/api/creditProduct";
import {
  Installment,
  IOperationBody,
  IOperationDetails,
  IOperations,
  ISimulateOperation,
  ISimulateOperationBody,
  ISimulateOperationBodyCCB,
} from "~/services/api/operations";
import {
  IBodySubscriber,
  InstallmentsData,
  ISubscriber,
} from "~/services/api/subscriber";
import { InvolvedData } from "~/types";

const [OperationProvider, useOperation] = constate(() => {
  const [operations, setOperations] = useState<IOperations[]>([]);
  const [operationsSimulated, setOperationsSimulated] = useState<
    ISimulateOperation[]
  >([]);
  const [creditProducts, setCreditProducts] = useState<ICreditProducts[]>([]);

  const [editInvolvedData, setEditInvolvedData] = useState<null | InvolvedData>(
    null
  );

  const [confirmOperation, setConfirmOperation] = useState<boolean>(false);

  const [deleteInvolvedData, setDeleteInvolvedData] =
    useState<null | InvolvedData>(null);

  const [canAdvance, setCanAdvance] = useState(false);
  const [deleteDocumentData, setDeleteDocumentData] = useState<null | {
    related_to?: string;
    type?: string;
    file?: string;
    uuid?: string | undefined;
  }>(null);

  const [subscriber, setSubscriber] = useState<ISubscriber>();

  const [installments, setInstallments] = useState<InstallmentsData | null>(
    null
  );

  const [operationDetails, setOperationDetails] =
    useState<IOperationDetails | null>(null);

  const [editInstallment, setEditInstallment] = useState<boolean>(false);
  const [installmentData, setInstallmentData] = useState<Installment>();

  const [tab, setTab] = useState(0);
  const [involvedsRender, setInvolvedsRender] = useState([]);
  const [documentsRender, setDocumentsRender] = useState([]);
  const [addInvolved, setAddInvolved] = useState<boolean>(false);

  const [recoverSimulation, setRecoverSimulation] = useState<boolean>(false);

  const [addDocument, setAddDocument] = useState<boolean>(false);
  const [removeInvolved, setRemoveInvolved] = useState<boolean>(false);
  const [removeDocument, setRemoveDocument] = useState<boolean>(false);
  const [sendTosign, setSendToSign] = useState<boolean>(false);
  const [simulateResult, setSimulateResult] =
    useState<ISimulateOperation | null>(null);

  const [payloadOperation, setPayloadOperation] = useState<
    Omit<IOperationBody, "operation_uuid">
  >({
    debtor_uuid: "",
    assignor_uuid: "",
    liquidation_bank_account_uuid: "",
    liquidation_type: "",
    receivables_register: false,
    batch_assignment: false,
    details: "",
  });

  const [subscriberSimulationPayload, setSubscriberSimulationPayload] =
    useState<IBodySubscriber | null>(null);

  const [payloadOperationCommercialNote, setPayloadOperationCommercialNote] =
    useState<{
      debtor_uuid: string;
    }>({
      debtor_uuid: "",
    });

  const [payloadSimulateOperationCCB, setPayloadSimulateOperationCCB] =
    useState<ISimulateOperationBodyCCB>();

  const [payloadSimulateOperation, setPayloadSimulateOperation] =
    useState<ISimulateOperationBody>({
      nickname: "",
      product_uuid: "",
      calculation_type: "",
      requested_amount: 0,
      duration: 0,
      calculation_base: "",
      operation_date: "",
      release_date: "",
      due_date: "",
      taxes_settings: {
        interest_rate: 0,
        interest_periodicity: "",
        amortization_grace_period: 0,
        interest_grace_period: 0,
        commission: 0,
        indexer: "",
        indexer_rate: 0,
      },
    });

  return {
    operations,
    setOperations,
    operationDetails,
    setOperationDetails,
    creditProducts,
    setCreditProducts,
    addInvolved,
    setAddInvolved,
    editInstallment,
    setEditInstallment,
    tab,
    setTab,
    editInvolvedData,
    setEditInvolvedData,
    involvedsRender,
    setInvolvedsRender,
    payloadSimulateOperation,
    setPayloadSimulateOperation,
    payloadOperation,
    setPayloadOperation,
    addDocument,
    setAddDocument,
    removeInvolved,
    setRemoveInvolved,
    documentsRender,
    setDocumentsRender,
    removeDocument,
    setRemoveDocument,
    simulateResult,
    setSimulateResult,
    sendTosign,
    setSendToSign,
    operationsSimulated,
    setOperationsSimulated,
    recoverSimulation,
    setRecoverSimulation,
    installmentData,
    setInstallmentData,
    subscriber,
    setSubscriber,
    payloadOperationCommercialNote,
    setPayloadOperationCommercialNote,
    subscriberSimulationPayload,
    setSubscriberSimulationPayload,
    installments,
    setInstallments,
    payloadSimulateOperationCCB,
    setPayloadSimulateOperationCCB,

    deleteInvolvedData,
    setDeleteInvolvedData,
    deleteDocumentData,
    setDeleteDocumentData,
    canAdvance,
    setCanAdvance,
    confirmOperation,
    setConfirmOperation,
  };
});

export { OperationProvider };
export default useOperation;
