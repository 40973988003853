import Margin from "~/components/Margin";
import { useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Dropdown,
  InputFluid,
  InputSelect,
  Toogle,
  Tooltip,
} from "prosperita-dumbo-react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "prosperita-dumbo-react/dist/style.css";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import "react-quill/dist/quill.snow.css";

import useLoading from "~/hooks/useLoading";

import { toast } from "react-toastify";
import MoreIcon from "~/assets/Addd.png";
import {
  getTemplateAPI,
  getVariablesTemplate,
  ITemplate,
  putTemplateAPI,
} from "~/services/api/template";
import {
  getCreditProductAPI,
  ICreditProducts,
} from "~/services/api/creditProduct";
import useOperation from "~/hooks/useOperation";

const StyledQuill = styled(ReactQuill)`
  border-radius: 8px;
  margin-top: 35px;
  .ql-toolbar {
    border-color: #3498db;
    border-radius: 8px 8px 0 0;
    background-color: #f4f4f4;
  }

  .ql-container {
    border-color: #3498db;
    border-radius: 0 0 8px 8px;
    background-color: #f4f4f4;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const FormGrid = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  background-color: #f4f4f4;

  & > div {
    flex: 1;
  }
`;

type Values = {
  content: string;
  product_uuid: string;
  name: string;
  status: string;
};

const initialValues: Values = {
  content: "",
  product_uuid: "",
  name: "",
  status: "",
};

const Font = Quill.import("formats/font");
Font.whitelist = [
  "IBM Plex Sans",
  "Arial",
  "Courier New",
  "Georgia",
  "Tahoma",
  "Verdana",
];

Quill.register(Font, true);

// Configurando a toolbar personalizada
const toolbarOptions = [
  [{ font: [] }],
  [{ size: [] }],
  ["bold", "italic", "underline", "strike"],
  [{ color: [] }, { background: [] }],
  [{ script: "sub" }, { script: "super" }],
  [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ align: [] }],
  ["link", "image"],
  ["clean"],
];

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "align",
  "color",
  "background",
  "code-block",
];

const EditTemplate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [template, setTemplate] = useState<ITemplate>();
  const { setLoading } = useLoading();
  const { setCreditProducts, creditProducts } = useOperation();

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const canDoSimulations = true;

  const productsOptions = useMemo(() => {
    return creditProducts
      ?.filter((product: ICreditProducts) =>
        product.name.toLowerCase().includes("nota")
      )
      ?.map((creditProduct: ICreditProducts) => ({
        value: creditProduct.uuid,
        label: creditProduct.name,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [creditProducts]);

  const fetchTemplate = useCallback(async () => {
    setLoading(true);
    if (id) {
      try {
        const { data } = await getTemplateAPI(id);
        setFieldValue("name", data?.payload?.name);
        setFieldValue("content", data?.payload?.html_content);
        setFieldValue("status", data.payload.status);
        setFieldValue("content", data.payload.html_content);
        setTemplate(data.payload);

        setLoading(false);
      } catch (e) {
        console.log(e);

        setLoading(false);
      }
    }
  }, [setLoading, id, setFieldValue]);

  const editTemplate = useCallback(async () => {
    const payload = {
      product_uuid: values.product_uuid,
      name: values.name,
      html_content: values.content,
      status: values.status,
      css_stylesheet: "",
    };
    setLoading(true);

    try {
      await putTemplateAPI(payload, String(id));

      toast.success("Template atualizado com sucesso");
      setLoading(false);
      navigate("/app/credit/templates");
    } catch (e) {
      console.log(e);

      toast.error("Não foi possível atualizar o Template. Tente novamente");
      setLoading(false);
    }
  }, [values, setLoading, navigate, id]);

  const handleSelect = (e) => {
    setFieldValue("product_uuid", e.value);
  };

  const fetchProducts = useCallback(async (): Promise<void> => {
    try {
      const { data } = await getCreditProductAPI();

      setCreditProducts(data.payload.reverse());
    } catch (e) {
      console.error("Erro ao buscar produtos:", e);
    }
  }, [setCreditProducts]);

  const handleDropdownChange = (selectedOption: {
    target: { value: string };
  }) => {
    const value = selectedOption.target.value;
    setFieldValue(
      "content",
      values.content ? `${values.content}  ${value}` : value
    );
  };

  const [variables, setVariables] = useState<string[]>();

  const fetchVariables = useCallback(async (): Promise<void> => {
    if (values.product_uuid) {
      try {
        const { data } = await getVariablesTemplate({
          product_uuid: values.product_uuid,
        });

        setVariables(data.payload);
      } catch (e) {
        console.error("Erro ao buscar produto:", e);
      }
    }
  }, [values.product_uuid]);

  const variablesOptions = useMemo(() => {
    return variables?.map((variable: string) => ({
      value: variable,
      label: variable,
    }));
  }, [variables]);

  useEffect(() => {
    if (values.product_uuid) {
      fetchVariables();
    }
  }, [values.product_uuid, fetchVariables]);

  useEffect(() => {
    fetchProducts();
    fetchTemplate();
  }, [fetchProducts, fetchTemplate]);

  const [labelProduct, setLabelProduct] = useState("");

  useEffect(() => {
    if (template && Array.isArray(productsOptions)) {
      const selectedProduct = productsOptions.find(
        (product) => product?.value === template.product_uuid
      );

      setFieldValue("product_uuid", selectedProduct?.value || "");
      setLabelProduct(selectedProduct?.label ?? "");
    }
  }, [template, productsOptions, setFieldValue]);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          {
            crumb: "Templates",
            onClick: () => navigate("/app/credit/templates"),
          },
          { crumb: "Editar template" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <h2
            style={{
              margin: "10px 0 0 45px ",
              color: "#000",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "40px",
            }}
          >
          Operações de crédito
          </h2> */}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {canDoSimulations && (
          <div>
            {" "}
            <Text style={{ fontSize: "25px" }}>Editar template</Text>
            <Margin mt={40} />
            <div style={{ backgroundColor: "#f4f4f4", height: "2px" }}></div>
            <FormGrid>
              <InputFluid
                label="Nome do template"
                noRadius
                placeholder="Digite"
                value={values.name}
                borderNone
                name="name"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setFieldValue("name", inputValue);
                }}
              />
              <div style={{ position: "relative", top: "-2px" }}>
                <Tooltip
                  align="top"
                  size="medium"
                  label="Não é possível alterar este dado"
                >
                  <InputSelect
                    name="product_uuid"
                    onChange={(e) => handleSelect(e)}
                    kind="fluid"
                    size="large"
                    options={productsOptions}
                    label="Produto"
                    placeholder={labelProduct}
                    noRadius
                    disabled
                    readonly
                  />
                </Tooltip>
              </div>
            </FormGrid>
            <div
              style={{
                borderBottom: "1px solid rgba(0,0,0,0.5)",
                position: "relative",
                top: "-3px",
              }}
            ></div>
            <Margin mt={20} />
            <div style={{ display: "flex" }}>
              <div>
                <p style={{ color: "#505050", fontSize: "12px" }}>Status</p>
                <Toogle
                  size="default"
                  checked={values.status === "active"}
                  onChange={() => {
                    // Alterna entre "active" e "inactive"
                    const newValue =
                      values.status === "active" ? "inactive" : "active";
                    setFieldValue("status", newValue);
                  }}
                />
              </div>
              <p> {values.status === "active" ? "Ativo" : "Inativo"}</p>
            </div>
            <Margin mt={50} />
            <div style={{ width: "50%", maxWidth: "600px" }}>
              <Dropdown
                kind="default"
                placeholder={
                  values.product_uuid
                    ? "Adicionar variável"
                    : "Selecione um produto para poder adicionar variáveis"
                }
                value={
                  values.product_uuid
                    ? "Adicionar variável"
                    : "Selecione um produto para poder adicionar variáveis"
                }
                size="medium"
                options={variablesOptions || []}
                disabled={!values.product_uuid}
                label="Variáveis"
                onChange={handleDropdownChange}
              />
              <Margin mb={-26} />
            </div>
            <label htmlFor="content"></label>
            <StyledQuill
              style={{ height: "300px", width: "100%", borderRadius: "80px" }} //
              id="content"
              className="content"
              modules={{ toolbar: toolbarOptions }}
              formats={formats}
              value={values.content}
              onChange={(e) => setFieldValue("content", e)}
            />
            <Margin mt={80} />
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <Button
                kind="primary"
                size="large"
                disabled={
                  !values.name ||
                  !values.product_uuid ||
                  values.content === "<p><br></p>" ||
                  values.content === undefined
                }
                label="Atualizar template"
                icon={MoreIcon}
                onClick={() => editTemplate()}
              />
            </div>
          </div>
        )}

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para ~visualizar as simulações <br />
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default EditTemplate;
