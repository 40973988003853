import Margin from "~/components/Margin";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { FunctionComponent, useCallback, useEffect } from "react";

import { Breadcrumb, Button } from "prosperita-dumbo-react";

import lockIcon from "~/assets/lock.png";
import ActivityIcon from "~/assets/Activity.png";
import styled from "styled-components";
import NewDebitor from "./NewDebitor";
import useOperation from "~/hooks/useOperation";
import NewInvolved from "./NewInvolved";
import Stepper from "~/components/Stepper";
import NewDocument from "./NewDocument";
import { getOperationDetailAPI } from "~/services/api/operations";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import {
  IBodySubscriber,
  postSimulateSubscriberAPI,
} from "~/services/api/subscriber";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow: hidden;
  align-items: start;
  padding: 30px 50px;
  box-sizing: border-box;
`;

const Title = styled.h2`
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 100;
  line-height: 40px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const canViewOperations = true;

const NewSubscriber: FunctionComponent = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const location = useLocation();

  const {
    tab,
    setOperationDetails,
    subscriberSimulationPayload,
    setInstallments,
    operationDetails,
    subscriber,
  } = useOperation();
  const { setLoading } = useLoading();

  const sendNewSimulate = useCallback(async (): Promise<void> => {
    setLoading(true);

    try {
      const { data } = await postSimulateSubscriberAPI(
        subscriberSimulationPayload as IBodySubscriber
      );
      setInstallments(data.payload);
      toast.success("Simulação gerada com sucesso!");

      setLoading(false);
    } catch (e) {
      console.log(e);
      toast.error("Não foi possível gerar a simulação !");

      setLoading(false);
    }
  }, [setLoading, subscriberSimulationPayload, setInstallments]);

  const fetchOperationDetails = useCallback(async () => {
    try {
      if (uuid) {
        const { data } = await getOperationDetailAPI(uuid);
        // setInstallmentsDetail(data.payload.installments);
        setOperationDetails(data.payload);
      } else {
        console.error("UUID is undefined");
      }
    } catch (err) {
      console.error("Erro ao buscar detalhes da operação:", err);
    }
  }, [uuid, setOperationDetails]);

  const steps = [
    {
      label: "Subscritor",
      isCompleted: tab > 0,
      isActive: tab === 0,
    },
    { label: "Envolvidos", isCompleted: tab > 2, isActive: tab === 2 },
    { label: "Documentos", isCompleted: tab > 3, isActive: tab === 3 },
  ];

  useEffect(() => {
    fetchOperationDetails();
  }, [fetchOperationDetails]);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          {
            crumb: "Operações",
            onClick: () => navigate("/app/credit/operations"),
          },
          {
            crumb: "Detalhes",
            onClick: () => navigate(`/app/credit/operations/details/${uuid}`),
          },
          {
            crumb: "Novo subscritor",
          },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div style={{ width: "100%" }}>
        <>
          {canViewOperations && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "30px ",
                  alignItems: "end",
                }}
              >
                <div>
                  <Title>
                    {location.pathname.includes("basic-data")
                      ? "Novo subscritor"
                      : location.pathname.includes("involveds")
                        ? "Envolvidos"
                        : location.pathname.includes("documents") &&
                          "Documentos"}
                  </Title>
                  <Margin mt={20} />
                  {(location.pathname.includes("involveds") ||
                    location.pathname.includes("documents")) && (
                    <h4 style={{ fontSize: "22px" }}>
                      Subscritor: {subscriber?.partner.name}
                    </h4>
                  )}
                  <h4 style={{ fontSize: "22px" }}>
                    Operação de Nota Comercial ID: {uuid}
                  </h4>
                </div>
                {location.pathname.includes("basic-data") && (
                  <div>
                    <Button
                      kind={"primary"}
                      label="Simular"
                      size="large"
                      icon={ActivityIcon}
                      onClick={() => sendNewSimulate()}
                      disabled={
                        (operationDetails &&
                          Number(
                            subscriberSimulationPayload?.quantity_of_units_acquired
                          ) >
                            operationDetails?.commercial_note
                              ?.available_quantity_of_units) ||
                        !subscriberSimulationPayload?.partner_uuid ||
                        !subscriberSimulationPayload?.quantity_of_units_acquired ||
                        Number(
                          subscriberSimulationPayload?.quantity_of_units_acquired
                        ) < 1
                      }
                    />
                  </div>
                )}
              </div>
              <Stepper steps={steps} />
              <Margin mt={50} />
              <Outlet />
            </div>
          )}
        </>

        {!canViewOperations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para criar operações de crédito, <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>

      <NewDebitor />
      <div style={{ zIndex: 999 }}>
        <NewInvolved />
      </div>

      <div style={{ zIndex: 999 }}>
        <NewDocument />
      </div>
    </Container>
  );
};

export default NewSubscriber;
