import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Tile } from "prosperita-dumbo-react";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import useOperation from "~/hooks/useOperation";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const canDoSimulations = true;

const SelectTypeOperation = () => {
  const navigate = useNavigate();
  const { setInvolvedsRender, setDocumentsRender } = useOperation();

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          {
            crumb: "Operações",
            onClick: () => navigate("/app/credit/operations"),
          },
          { crumb: "Nova Operação" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <h2
            style={{
              margin: "10px 0 0 45px ",
              color: "#000",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "40px",
            }}
          >
          Operações de crédito
          </h2> */}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          {canDoSimulations && (
            <div style={{ width: "500px" }}>
              <Margin mt={40} />

              <Text style={{ fontSize: "25px" }}>
                Escolha o tipo de operação que deseja criar
              </Text>

              <Margin mt={50} />
              <Tile
                variations="clickable"
                label={
                  <p
                    style={{
                      fontWeight: "600",
                      fontFamily: "sans-serif",
                      fontSize: "20px",
                    }}
                  >
                    CCB
                  </p>
                }
                textlabel="Cédula de Crédito Bancário"
                onClick={() => {
                  navigate("/app/credit/operations/new/ccb/operation");
                  setDocumentsRender([]);
                  setInvolvedsRender([]);
                  localStorage.removeItem("@:involveds");
                  localStorage.removeItem("@:simulate_operation");
                  localStorage.removeItem("@:operation");
                  localStorage.removeItem("@:documents");
                }}
              />
              <Margin mt={10} />

              <Tile
                variations="clickable"
                label={
                  <p
                    style={{
                      fontWeight: "600",
                      fontFamily: "sans-serif",
                      fontSize: "20px",
                    }}
                  >
                    Nota Comercial
                  </p>
                }
                textlabel={"Nota comercial"}
                onClick={() => {
                  navigate(
                    "/app/credit/operations/new/commercial-note/operation"
                  );
                  setDocumentsRender([]);
                  setInvolvedsRender([]);

                  localStorage.removeItem("@:involveds");
                  localStorage.removeItem("@:simulate_operation");
                  localStorage.removeItem("@:operation");
                  localStorage.removeItem("@:documents");
                }}
              />
            </div>
          )}
        </>

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para ~visualizar as simulações <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default SelectTypeOperation;
