import Margin from "~/components/Margin";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Button, Input, InputSelect } from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";

import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import SaveIcon from "~/assets/Save.png";

import {
  formatCEP,
  formatCPFCNPJ,
  formatPhoneNumber,
  getLabelByValue,
  onlyNumbers,
  validateEmail,
} from "~/services/utils";

import useLoading from "~/hooks/useLoading";

import { toast } from "react-toastify";
import {
  bussinesSectorOptions,
  cnaeOptions,
  entityOptions,
  residenceOptions,
  ufOptions,
} from "~/utils/options";
import { getPartnersDetailsAPI, patchPartnerAPI } from "~/services/api/partner";
import { getCepAPI } from "~/services/api/cep";
import { isValidFullNameCompany } from "~/services/validators";
import documentNumberValidator from "~/services/validateDocumentNumber";
import { isPast } from "date-fns";
import usePartner from "~/hooks/usePartner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

type Values = {
  name: string;
  owner_uuid: string;
  document_number: string;
  document_type: string;
  contact_email: string;
  contact_phone: string;
  foundation_date: string;
  cnae: string;
  fantasy_name: string;
  legal_nature: string;
  municipal_registration: string;
  state_registration: string;
  website: string;
  business_sector: string;
  employer_code: string;
  b3_account: string;
  address_zip_code: string;
  address_street: string;
  address_number: string;
  address_district: string;
  address_city: string;
  address_state: string;
  address_country: string;
  address_type: string;
  finantial_info_initial_share_capital: string;
  finantial_info_net_equity: string;
  finantial_info_number_of_employees: string;
  finantial_info_average_monthly_revenue: string;
  finantial_info_average_gross_profit: string;
  finantial_info_exportation_percentage: string;
  finantial_info_suppliers_cost: string;
  finantial_info_tax_cost: string;
  finantial_info_last_12_months_revenue: string;
  finantial_info_leased_or_financed_value: string;
  finantial_info_arrangement: string;
  finantial_info_payroll_value: string;
  finantial_info_headquarters_location: string;
  finantial_info_pmv: string;
  finantial_info_funding_potential: string;
  finantial_info_late_sales_percentage: string;
  profile: string;
};

const initialValues: Values = {
  name: "",
  owner_uuid: "",
  document_number: "",
  document_type: "",
  contact_email: "",
  contact_phone: "",
  foundation_date: "",
  cnae: "",
  fantasy_name: "",
  legal_nature: "",
  municipal_registration: "",
  state_registration: "",
  website: "",
  business_sector: "",
  employer_code: "",
  b3_account: "",
  address_zip_code: "",
  address_street: "",
  address_number: "",
  address_district: "",
  address_city: "",
  address_state: "",
  address_country: "",
  address_type: "",
  finantial_info_initial_share_capital: "",
  finantial_info_net_equity: "",
  finantial_info_number_of_employees: "",
  finantial_info_average_monthly_revenue: "",
  finantial_info_average_gross_profit: "",
  finantial_info_exportation_percentage: "",
  finantial_info_suppliers_cost: "",
  finantial_info_tax_cost: "",
  finantial_info_last_12_months_revenue: "",
  finantial_info_leased_or_financed_value: "",
  finantial_info_arrangement: "",
  finantial_info_payroll_value: "",
  finantial_info_headquarters_location: "",
  finantial_info_pmv: "",
  finantial_info_funding_potential: "",
  finantial_info_late_sales_percentage: "",
  profile: "",
};

const EditContactCompany = () => {
  const navigate = useNavigate();
  const [errorCep, setErrorCep] = useState<boolean>(false);
  const { setLoading } = useLoading();
  const [error, setError] = useState<string>("");

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const { setPartner, partner } = usePartner();
  const { uuid } = useParams();

  const canDoSimulations = true;

  const isValidCNPJ = useMemo(() => {
    return (
      values.document_number.length > 13 &&
      documentNumberValidator(values.document_number)
    );
  }, [values.document_number]);

  const EditContactCompany = useCallback(async () => {
    const payload = {
      name: values.name,

      contact: {
        email: values.contact_email,
        phone: onlyNumbers(values.contact_phone),
      },
      foundation_date: values.foundation_date,
      cnae: values.cnae,
      fantasy_name: values.fantasy_name,
      legal_nature: values.legal_nature,
      municipal_registration: values.municipal_registration,
      state_registration: values.state_registration,
      website: values.website,
      business_sector: values.business_sector,
      employer_code: values.employer_code,
      b3_account: values.b3_account,
      address: {
        zip_code: onlyNumbers(values.address_zip_code),
        street: values.address_street,
        number: values.address_number,
        district: values.address_district,
        city: values.address_city,
        state: values.address_state,
        country: values.address_country || "Brasil",
        type: values.address_type,
      },
    };

    setLoading(true);

    try {
      const { data } = await patchPartnerAPI(payload, String(uuid));

      localStorage.setItem("@:partner_uuid", data.payload.uuid);
      toast.success("Dados atualizados com sucesso");
      setLoading(false);
      navigate(`/app/contacts`);
    } catch (e) {
      console.log(e);
      setLoading(false);
      toast.error(
        `Não foi possível atualizar os dados. Tente novamente mais tarde`
      );
    }
  }, [values, setLoading, navigate, uuid]);

  const fetchPartner = useCallback(async () => {
    setPartner(null);
    setLoading(true);
    try {
      if (uuid) {
        const { data } = await getPartnersDetailsAPI(uuid);

        setPartner(data.payload);

        setLoading(false);
      } else {
        console.error("UUID is undefined");
        setLoading(false);
      }
    } catch (err) {
      console.error("Erro ao buscar detalhes da operação:", err);
      setLoading(false);
    }
  }, [uuid, setLoading, setPartner]);

  const fetchCEP = useCallback(
    async (cep: string): Promise<void> => {
      try {
        const { data } = await getCepAPI(cep);

        if (data.erro) {
          setErrorCep(true);
          setFieldValue("address_street", "");
          setFieldValue("address_district", "");
          setFieldValue("address_city", "");
          setFieldValue("address_state", "Selecione");
        } else {
          setErrorCep(false);
          setFieldValue("address_street", data.logradouro);
          setFieldValue("address_district", data.bairro);
          setFieldValue("address_city", data.localidade);
          setFieldValue("address_state", data.uf);
        }
      } catch (e) {
        console.error("Erro ao buscar contatos:", e);
        setErrorCep(false);
      }
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (partner) {
      setFieldValue("name", partner.name);
      setFieldValue("document_number", formatCPFCNPJ(partner.document_number));
      setFieldValue("document_type", partner.document_type);
      setFieldValue("contact_email", partner.contact.email);
      setFieldValue("contact_phone", formatPhoneNumber(partner.contact.phone));
      setFieldValue("foundation_date", partner.foundation_date);
      setFieldValue("cnae", partner.cnae);
      setFieldValue("fantasy_name", partner.fantasy_name);
      setFieldValue("legal_nature", partner.legal_nature);
      setFieldValue("municipal_registration", partner.municipal_registration);
      setFieldValue("state_registration", partner.state_registration);
      setFieldValue("website", partner.website);
      setFieldValue("business_sector", partner.business_sector);
      setFieldValue("employer_code", partner.employer_code);
      setFieldValue("b3_account", partner.b3_account);
      setFieldValue("address_zip_code", formatCEP(partner?.address?.zip_code));
      setFieldValue("address_street", partner.address?.street);
      setFieldValue("address_number", partner.address?.number);
      setFieldValue("address_district", partner.address?.district);
      setFieldValue("address_city", partner.address?.city);
      setFieldValue("address_state", partner.address?.state);
      setFieldValue("address_country", partner.address?.country);
      setFieldValue("address_type", partner.address?.type);
    }
  }, [partner, setFieldValue]);

  useEffect(() => {
    if (values.address_zip_code?.length === 9) {
      fetchCEP(onlyNumbers(values.address_zip_code));
    }
  }, [values.address_zip_code, fetchCEP]);

  useEffect(() => {
    if (values.address_zip_code?.length === 8) {
      setErrorCep(false);
    }
  }, [values.address_zip_code]);

  useEffect(() => {
    localStorage.removeItem("@:partner_uuid");
    fetchPartner();
  }, [fetchPartner]);

  useEffect(() => {
    if (values.document_number?.length <= 17 && !isValidCNPJ) {
      setError("initialState");
    } else if (values.document_number?.length >= 17 && isValidCNPJ) {
      setError("notError");
    } else if (values.document_number?.length > 17 && !isValidCNPJ) {
      setError("CNPJ inválido");
    }
  }, [values.document_number, setError, isValidCNPJ]);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          {
            crumb: "Contatos",
            onClick: () => navigate("/app/contacts"),
          },
          { crumb: "Editar" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        ></div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          {canDoSimulations && (
            <>
              <>
                <Margin mb={30} />
                <div
                  style={{
                    position: "sticky",
                    top: 113,

                    gap: "10px",
                    width: "100%",
                    zIndex: "3",
                    padding: "10px",
                    background: "#fff",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      kind={"ghost"}
                      label="Cancelar"
                      size="large"
                      onClick={() => navigate("/app/contacts")}
                    />
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Button
                        kind="primary"
                        label="Salvar"
                        icon={SaveIcon}
                        disabled={
                          (values.foundation_date?.length > 9 &&
                            !isPast(values.foundation_date)) ||
                          !isValidFullNameCompany(String(values?.name)) ||
                          values.document_number?.length < 14 ||
                          !validateEmail(values.contact_email) ||
                          !isValidCNPJ ||
                          !values.contact_phone ||
                          !values.address_zip_code ||
                          !values.address_type ||
                          !values.address_street ||
                          !values.address_state ||
                          !values.address_number ||
                          !values.address_city ||
                          !values.address_district ||
                          !values.business_sector ||
                          !values.cnae ||
                          !values.legal_nature ||
                          !values.foundation_date
                        }
                        size="large"
                        onClick={() => EditContactCompany()}
                      />
                    </div>
                  </div>

                  <Margin mb={10} />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Margin mb={30} />
                    <Text style={{ fontSize: "25px" }}>
                      Editar contato Pessoa Jurídica{" "}
                    </Text>
                    <Margin mb={20} />
                    <h4>Dados da empresa</h4>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr 1fr",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <Input
                      size="large"
                      name="document_number"
                      label="* CNPJ"
                      placeholder="00.000.000/0000-00"
                      value={values.document_number}
                      maxLength={18}
                      disabled
                      error={values.document_number.length > 17 && !isValidCNPJ}
                      message={
                        values.document_number.length > 17 && !isValidCNPJ
                          ? error
                          : ""
                      }
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/[^\d]/g, "");
                        if (value.length <= 2) {
                          value = `${value}`;
                        } else if (value.length <= 5) {
                          value = `${value.slice(0, 2)}.${value.slice(2)}`;
                        } else if (value.length <= 8) {
                          value = `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5)}`;
                        } else if (value.length <= 12) {
                          value = `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5, 8)}/${value.slice(8)}`;
                        } else if (value.length <= 14) {
                          value = `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5, 8)}/${value.slice(8, 12)}-${value.slice(12)}`;
                        }

                        setFieldValue("document_number", value);
                      }}
                    />
                    <Input
                      size="large"
                      name="name"
                      label="* Razão Social"
                      placeholder="Digite"
                      value={values.name}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                    />
                    <Input
                      size="large"
                      name="fantasy_name"
                      label="Nome Fantasia"
                      placeholder="Digite"
                      required
                      value={values.fantasy_name}
                      onChange={(e) =>
                        setFieldValue("fantasy_name", e.target.value)
                      }
                    />
                  </>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        name="legal_nature"
                        value=""
                        placeholder={
                          values.legal_nature
                            ? getLabelByValue(
                                values.legal_nature,
                                entityOptions
                              )
                            : "Escolha uma opção"
                        }
                        label="* Natureza Jurídica"
                        options={entityOptions}
                        onChange={(e) => setFieldValue("legal_nature", e.value)}
                      />
                    </div>
                    <Input
                      size="large"
                      type="date"
                      name="foundation_date"
                      label="* Data de abertura"
                      placeholder="dd/mm/aaaa"
                      error={
                        values?.foundation_date?.length > 9 &&
                        !isPast(values?.foundation_date)
                      }
                      message={
                        values?.foundation_date?.length > 9 &&
                        !isPast(values?.foundation_date)
                          ? "A data de fundação não pode ser uma data futura"
                          : ""
                      }
                      value={values.foundation_date}
                      onChange={(e) =>
                        setFieldValue("foundation_date", e.target.value)
                      }
                    />
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        name="cnae"
                        label="* CNAE"
                        value=""
                        placeholder={
                          values.business_sector
                            ? getLabelByValue(values.cnae, cnaeOptions)
                            : "Escolha uma opção"
                        }
                        options={cnaeOptions}
                        onChange={(e) => setFieldValue("cnae", e.value)}
                      />
                    </div>
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        name="business_sector"
                        label="* Ramo de atividade"
                        value=""
                        placeholder={
                          values.business_sector
                            ? getLabelByValue(
                                values.business_sector,
                                bussinesSectorOptions
                              )
                            : "Escolha uma opção"
                        }
                        options={bussinesSectorOptions}
                        onChange={(e) =>
                          setFieldValue("business_sector", e.value)
                        }
                      />
                    </div>
                    <Input
                      max={20}
                      maxLength={20}
                      size="large"
                      name="municipal_registration"
                      label="Inscrição Municipal"
                      placeholder="00000000000"
                      value={values.municipal_registration}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        setFieldValue("municipal_registration", value);
                      }}
                    />
                    <Input
                      max={20}
                      maxLength={20}
                      size="large"
                      name="state_registration"
                      label="Inscrição Estadual"
                      placeholder="00000000000"
                      value={values.state_registration}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        setFieldValue("state_registration", value);
                      }}
                    />

                    <Input
                      size="large"
                      name="website"
                      label="Website"
                      placeholder="Digite"
                      value={values.website}
                      onChange={(e) => setFieldValue("website", e.target.value)}
                    />
                    <Input
                      max={25}
                      maxLength={25}
                      size="large"
                      name="b3_account"
                      label="Conta B3"
                      placeholder="Digite"
                      value={values.b3_account}
                      onChange={(e) =>
                        setFieldValue("b3_account", e.target.value)
                      }
                    />
                  </>
                </div>
                <Margin mt={50} />
              </>
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h4>Dados de contato</h4>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <Input
                      size="large"
                      name="contact_email"
                      label="* E-mail"
                      placeholder="contato@mail.com"
                      value={values.contact_email}
                      onChange={(e) =>
                        setFieldValue("contact_email", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      maxLength={15}
                      name="contact_phone"
                      label="* Telefone"
                      placeholder="(00) 00000-0000"
                      value={values.contact_phone}
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^\d]/g, "");
                        if (value.length > 0 && value.length <= 2) {
                          value = `(${value}`;
                        } else if (value.length > 2 && value.length <= 6) {
                          value = `(${value.slice(0, 2)}) ${value.slice(2)}`;
                        } else if (value.length > 6) {
                          value = `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
                        }

                        setFieldValue("contact_phone", value);
                      }}
                    />
                  </>
                </div>
                <Margin mt={50} />
              </>
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h4>Endereço</h4>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <Input
                      size="large"
                      name="address_zip_code"
                      max={9}
                      maxLength={9}
                      label="* CEP"
                      placeholder="Digite"
                      value={values.address_zip_code}
                      error={errorCep && values.address_zip_code.length === 9}
                      message={
                        errorCep && values.address_zip_code.length === 9
                          ? "CEP inválido"
                          : ""
                      }
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^\d]/g, "");

                        if (value.length <= 5) {
                          value = `${value.slice(0, 5)}`;
                        } else if (value.length <= 8) {
                          value = `${value.slice(0, 5)}-${value.slice(5, 8)}`;
                        }

                        setFieldValue("address_zip_code", value);
                      }}
                    />
                    <div style={{ gridColumn: "span 2" }}>
                      <Input
                        size="large"
                        name="address_street"
                        label="* Endereço"
                        disabled={!values.address_zip_code}
                        placeholder="Digite"
                        value={values.address_street}
                        onChange={(e) =>
                          setFieldValue("address_street", e.target.value)
                        }
                      />
                    </div>
                    <Input
                      size="large"
                      name="address_number"
                      label="* Número"
                      placeholder="Digite"
                      disabled={!values.address_zip_code}
                      value={values.address_number}
                      onChange={(e) =>
                        setFieldValue("address_number", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      name="address_district"
                      label="* Bairro"
                      placeholder="Digite"
                      disabled={!values.address_zip_code}
                      value={values.address_district}
                      onChange={(e) =>
                        setFieldValue("address_district", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      name="address_city"
                      label="* Cidade"
                      placeholder="Digite"
                      disabled={!values.address_zip_code}
                      value={values.address_city}
                      onChange={(e) =>
                        setFieldValue("address_city", e.target.value)
                      }
                    />
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        name="address_state"
                        disabled={!values.address_zip_code}
                        label="* Estado"
                        placeholder={
                          values.address_state ? values.address_state : "UF"
                        }
                        options={ufOptions}
                        onChange={(e) =>
                          setFieldValue("address_state", e.value)
                        }
                      />
                    </div>
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        disabled={!values.address_zip_code}
                        name="address_type"
                        label="* Tipo de residência"
                        placeholder={
                          values.address_type
                            ? getLabelByValue(
                                values.address_type,
                                residenceOptions
                              )
                            : "Escolha uma opção"
                        }
                        options={residenceOptions}
                        onChange={(e) => setFieldValue("address_type", e.value)}
                      />
                    </div>
                  </>
                </div>
                <Margin mt={50} />
              </>
            </>
          )}
        </>

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para editar contatos <br /> contate o
                administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default EditContactCompany;
