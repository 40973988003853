import { IDocumentDataPartner } from "~/types";
import ApiService from "../api";

export type IPartner = {
  uuid: string;
  type: string;
  name: string;
  document_number: string;
  document_type: string;
  contact: {
    email: string;
    phone: string;
  };
  address: {
    zip_code: string;
    street: string;
    number: string;
    district: string;
    city: string;
    state: string;
    country: string;
    additional_info: string;
    type: string;
  };
  birth_date: string;
  nationality: string;
  marital_status: string;
  occupation: string;
  social_name: string;
  birth_country: string;
  mother_name: string;
  father_name: string;
  gender: string;
  health_declaration: string;
  identification_document: {
    document_type: string | null;
    document: string | null;
    emission_date: string | null;
    expiration_date: string | null;
    pep: boolean;
    issuing_state: string;
  };
  professional_info: {
    occupation_nature: string | null;
    employment_status: string | null;
    net_salary: number;
    additional_income: number;
    hiring_date: string | null;
    employee_number: string | null;
    department: string | null;
  };
  parents: unknown[];
};

export type IPartnerCompany = {
  uuid: string;
  name: string;
  document_number: string;
  document_type: "company" | "person";
  contact: string | null;
  address: string | null;
  foundation_date: string;
  cnae: string;
  fantasy_name: string;
  legal_nature:
    | "sa"
    | "ltda"
    | "mei"
    | "eireli"
    | "simple_eireli"
    | "slu"
    | "fidc"
    | "scp"
    | "coop"
    | "pa"
    | "ssp"
    | "notary"
    | string;
  municipal_registration: string | null;
  state_registration: string | null;
  website: string | null;
  business_sector: string | null;
  employer_code: string | null;
  b3_account: string | null;
};

export type IPartnerDocumentsBody = {
  type: string;
  description: string;
  file: string;
  partner_uuid: string;
  file_url?: string;
};

export const getPartnersAPI = (params?: {
  document_type?: string;
  label?: string;
  legal_nature?: string;
}) => {
  return ApiService.HttpGet<IResponse<IPartner[]>>({
    route: "register/partner/",
    token: true,
    params,
  });
};

export const getPartnersDetailsAPI = (uuid: string) => {
  return ApiService.HttpGet<IResponse<IPartner & IPartnerCompany>>({
    route: `register/partner/${uuid}/`,
    token: true,
  });
};

export const getPartnersCompanyAPI = (params?: { document_type?: string }) => {
  return ApiService.HttpGet<IResponse<IPartnerCompany[]>>({
    route: "register/partner/",
    token: true,
    params,
  });
};

export const getPartnersListAPI = (params?: { document_type?: string }) => {
  return ApiService.HttpGet<IResponse<IPartner[]>>({
    route: "register/partner/",
    token: true,
    params,
  });
};

export const getPartnerCompanyAPI = (uuid: string) => {
  return ApiService.HttpGet<IResponse<IPartnerCompany>>({
    route: `register/partner/${uuid}/`,
    token: true,
  });
};

export const getPartnerPersonAPI = (uuid: string) => {
  return ApiService.HttpGet<IResponse<unknown>>({
    route: `register/partner/${uuid}/`,
    token: true,
  });
};

export const postPartnerCompanyAPI = (body) => {
  return ApiService.HttpPost<IResponse<IPartnerCompany>>({
    route: `register/partner/`,
    token: true,
    body,
  });
};

export const patchPartnerAPI = (body, uuid: string) => {
  return ApiService.HttpPatch<IResponse<IPartnerCompany>>({
    route: `register/partner/${uuid}/`,
    token: true,
    body,
  });
};

export const getPartnerBankAccountAPI = (uuid: string) => {
  return ApiService.HttpPost<IResponse<unknown[]>>({
    route: `register/bank-account/`,
    token: true,
    params: { partner_uuid: uuid },
  });
};

export const postPartnerBankAccountAPI = (body) => {
  return ApiService.HttpPost<IResponse<unknown>>({
    route: `register/bank-account/`,
    token: true,
    body,
  });
};

export const putPartnerBankAccountAPI = (body, uuid: string) => {
  return ApiService.HttpPatch<IResponse<unknown>>({
    route: `register/bank-account/${uuid}/`,
    token: true,
    body,
  });
};

export const getPartnerDocumentsAPI = (uuid: string) => {
  return ApiService.HttpGet<IResponse<IDocumentDataPartner[]>>({
    route: `register/document/`,
    token: true,
    params: { partner_uuid: uuid },
  });
};

const boundary = "----WebKitFormBoundary7MA4YWxkTrZu0gW";

export const postPartnerDocumentsAPI = (body) => {
  return ApiService.HttpPost<IResponse<unknown>>({
    route: `register/document/`,
    token: true,
    body,
    customHeaders: {
      "Content-Type": `multipart/form-data; boundary=${boundary}`,
    },
  });
};
