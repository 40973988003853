import {
  FunctionComponent,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from "react";
import { useFormik } from "formik";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// import useDebitor from "~/hooks/useDebitor";
import usePartner from "~/hooks/usePartner";
import useOperation from "~/hooks/useOperation";
import { getPartnersAPI, IPartner } from "~/services/api/partner";
import { getBankAccountsAPI, IBankAccount } from "~/services/api/bankAccount";
import {
  // Button,
  Checkbox,
  Input,
  InputSelect,
  // Tooltip,
} from "prosperita-dumbo-react";
// import NewDebitorIcon from "~/assets/new-debitor.png";
// import ArrowIcon from "~/assets/arrow-right-_1_.png";
import { getLabelByValue } from "~/services/utils";
// import ArrowLeft from "~/assets/arrow-lftt-_1_.png";
// import WhiteLeft from "~/assets/white-left.png";

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; // se precisar voltar o adicionar contato só colocar o auto
  gap: 32px;
  row-gap: 30px;
  margin-top: 20px;
`;

type IInitialValues = {
  debtor_uuid: string;
  invoice_barcode: string;
  assignor_uuid: string;
  liquidation_bank_account_uuid: string;
  liquidation_type: string;
  receivables_register: boolean;
  batch_assignment: boolean;
  details: string;
};

const ProductInfoCCB: FunctionComponent = () => {
  // const { setOpenNewDebitorModal } = useDebitor();
  const { setPartners, partners, setBankAccounts, bankAccounts } = usePartner();
  const { setPayloadOperation, setTab, setCanAdvance } = useOperation();
  // const navigate = useNavigate();
  // const [hover, setHover] = useState<boolean>(false);
  const [partnersFidc, setPartnersFidc] = useState<IPartner[]>();
  const initialValues = useMemo(() => {
    const storedOperation = localStorage.getItem("@:operation");
    const defaultValues: IInitialValues = {
      debtor_uuid: "",
      assignor_uuid: "",
      invoice_barcode: "",
      liquidation_bank_account_uuid: "",
      liquidation_type: "",
      receivables_register: false,
      batch_assignment: false,
      details: "",
    };

    if (storedOperation) {
      try {
        const parsedOperation = JSON.parse(storedOperation);
        return {
          debtor_uuid: parsedOperation.debtor_uuid,
          assignor_uuid: parsedOperation.assignor_uuid,
          liquidation_bank_account_uuid:
            parsedOperation.liquidation_bank_account_uuid,
          liquidation_type: parsedOperation.liquidation_type,
          receivables_register: parsedOperation.receivables_register,
          batch_assignment: parsedOperation.batch_assignment,
          details: parsedOperation.details,
          invoice_barcode: parsedOperation.invoice_barcode,
        };
      } catch (error) {
        console.error("Erro ao parsear o localStorage:", error);
      }
    }

    return defaultValues;
  }, []);

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const handleSelect = useCallback(
    (fieldName: keyof IInitialValues, selectedOption) => {
      if (selectedOption) {
        setFieldValue(fieldName, selectedOption.value);
      }
    },
    [setFieldValue]
  );

  const fetchPartnersUY3 = useCallback(async (): Promise<void> => {
    try {
      const { data } = await getPartnersAPI();
      setPartners(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setPartners]);

  const fetchPartnersFIDC = useCallback(async (): Promise<void> => {
    try {
      const { data } = await getPartnersAPI({
        label: "uy3",
        legal_nature: "fidc",
      });
      setPartnersFidc(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setPartnersFidc]);

  const fetchBankAccounts = useCallback(async (): Promise<void> => {
    try {
      const { data } = await getBankAccountsAPI(values.debtor_uuid);
      setBankAccounts(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [values.debtor_uuid, setBankAccounts]);

  const partnersDebtor = useMemo(() => {
    return partners
      .map((partner: IPartner) => ({
        value: partner.uuid,
        label: partner.name,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [partners]);

  const partnersAssignor = useMemo(() => {
    return partnersFidc
      ?.map((partner: IPartner) => ({
        value: partner.uuid,
        label: partner.name,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [partnersFidc]);

  useEffect(() => {
    if (
      (values.liquidation_type === "billet" && values.invoice_barcode === "") ||
      values.assignor_uuid === "" ||
      values.debtor_uuid === "" ||
      values.liquidation_bank_account_uuid === "" ||
      values.liquidation_type === ""
    ) {
      setCanAdvance(false);
    } else {
      setCanAdvance(true);
    }
  }, [setCanAdvance, values]);

  const bankAccountOptions = useMemo(() => {
    return bankAccounts
      .map((bankAccount: IBankAccount) => ({
        value: bankAccount.uuid,
        label: `${bankAccount.bank_name} - Agência: ${bankAccount.agency}${bankAccount.agency_digit ? `-${bankAccount.agency_digit}` : ""} Conta: ${bankAccount.account_number}-${bankAccount.account_number_digit}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [bankAccounts]);

  const liquidationTypeOptions = useMemo(() => {
    return [
      { value: "pix", label: "PIX" },
      { value: "billet", label: "Boleto" },
      { value: "ted", label: "TED" },
    ].sort((a, b) =>
      a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
    );
  }, []);

  useEffect(() => {
    if (values.debtor_uuid !== "") {
      fetchBankAccounts();
    }
  }, [values.debtor_uuid, fetchBankAccounts]);

  useEffect(() => {
    const operationData = {
      debtor_uuid: values.debtor_uuid,
      assignor_uuid: values.assignor_uuid,
      liquidation_bank_account_uuid: values.liquidation_bank_account_uuid,
      liquidation_type: values.liquidation_type,
      receivables_register: values.receivables_register,
      batch_assignment: values.batch_assignment,
      details: values.details,
      invoice_barcode: values.invoice_barcode,
    };

    localStorage.setItem("@:operation", JSON.stringify(operationData));
    setPayloadOperation(operationData);
  }, [values, setPayloadOperation]);

  useEffect(() => {
    if (values.liquidation_type !== "billet") {
      setFieldValue("invoice_barcode", "");
    }
  }, [values.liquidation_type, setFieldValue]);

  useEffect(() => {
    fetchPartnersFIDC();
    fetchPartnersUY3();
    setTab(1);
  }, [fetchPartnersUY3, setTab, fetchPartnersFIDC]);

  return (
    <Container>
      <InputSelect
        name="assignor_uuid"
        kind="default"
        size="small"
        label="Cessionário"
        options={partnersAssignor}
        placeholder={
          values.assignor_uuid
            ? getLabelByValue(values.assignor_uuid, partnersDebtor)
            : "Selecione uma opção"
        }
        onChange={(e) => handleSelect("assignor_uuid", e)}
      />
      <InputSelect
        name="debtor_uuid"
        kind="default"
        size="small"
        label="Tomador"
        options={partnersDebtor}
        placeholder={
          values.debtor_uuid
            ? getLabelByValue(values.debtor_uuid, partnersDebtor)
            : "Selecione uma opção"
        }
        onChange={(e) => handleSelect("debtor_uuid", e)}
      />{" "}
      <InputSelect
        name="liquidation_type"
        kind="default"
        size="small"
        label="Tipo de liquidação"
        options={liquidationTypeOptions}
        placeholder={
          values.liquidation_type
            ? getLabelByValue(values.liquidation_type, liquidationTypeOptions)
            : "Selecione uma opção"
        }
        onChange={(e) => handleSelect("liquidation_type", e)}
      />
      {values.liquidation_type === "billet" && (
        <Input
          name="invoice_barcode"
          id="invoice_barcode"
          size="large"
          value={values.invoice_barcode}
          type="text"
          max={100}
          maxLength={100}
          label="Código de barras do boleto"
          placeholder="Digite"
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, "");

            setFieldValue("invoice_barcode", value);
          }}
        />
      )}
      {/* <div
        style={{
          position: "relative",
          top: "23px",
        }}
      >
        <Tooltip align="top-right" size="medium" label="Adicionar contato">
          {" "}
          <Button
            size="medium"
            kind="tertiary"
            icon={NewDebitorIcon}
            onClick={() => setOpenNewDebitorModal(true)}
          />
        </Tooltip>
      </div> */}
      <div>
        <InputSelect
          name="liquidation_bank_account_uuid"
          kind="default"
          size="small"
          label="Conta de liquidação"
          options={bankAccountOptions}
          onChange={(e) => handleSelect("liquidation_bank_account_uuid", e)}
          placeholder={
            bankAccountOptions.length > 0 && values.debtor_uuid !== ""
              ? getLabelByValue(
                  values.liquidation_bank_account_uuid,
                  bankAccountOptions
                )
              : bankAccountOptions.length <= 0
                ? "Primeiro selecione um tomador"
                : bankAccountOptions.length <= 0 && values.debtor_uuid !== ""
                  ? "Selecione uma opção"
                  : ""
          }
          disabled={bankAccountOptions.length <= 0}
        />
      </div>
      <div
        style={{
          position: "relative",
          top: "32px",
          display: "flex",
          justifyContent: "start",
        }}
      >
        <Checkbox
          label="Registradora de recebiveis"
          checked={values.receivables_register}
          onChange={() =>
            setFieldValue("receivables_register", !values.receivables_register)
          }
        />
        <Checkbox
          label="Cessão em lote"
          checked={values.batch_assignment}
          onChange={() =>
            setFieldValue("batch_assignment", !values.batch_assignment)
          }
        />
      </div>
      <div
        style={{
          position: "relative",
          top: "23px",
        }}
      ></div>
      <div style={{ gridColumn: "1 / -1" }}>
        <Input
          name="details"
          type="textarea"
          rows={5}
          max={500}
          maxLength={500}
          value={values.details}
          onChange={(e) => setFieldValue("details", e.target.value)}
          label="Observações (Opcional)"
          placeholder="Observações adicionais"
        />
      </div>
      <div
        style={{
          gridColumn: "1 / -1",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {/* <div>
          <Button
            kind="ghost"
            size="large"
            label="Cancelar"
            onClick={() => navigate("/app/credit/operations/")}
          />
        </div> */}
        <div style={{ display: "flex", gap: "10px" }}>
          {/* <div>
            <Button
              kind="tertiary"
              size="large"
              label="Voltar"
              icon={ArrowLeftIcon}
            />
          </div> */}
          {/* <div style={{ display: "flex", gap: "10px" }}>
            <div
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <Button
                kind="tertiary"
                size="large"
                icon={hover ? WhiteLeft : ArrowLeft}
                label="Voltar"
                onClick={() => {
                  navigate("/app/credit/operations/new/ccb/operation");
                  setTab(0);
                }}
              />
            </div>
            <Button
              kind="primary"
              size="large"
              label="Avançar"
              disabled={
                (values.liquidation_type === "billet" &&
                  values.invoice_barcode === "") ||
                values.assignor_uuid === "" ||
                values.debtor_uuid === "" ||
                values.liquidation_bank_account_uuid === "" ||
                values.liquidation_type === ""
              }
              icon={ArrowIcon}
              onClick={() => {
                navigate("/app/credit/operations/new/ccb/involveds/");
                setTab(2);
              }}
            />
          </div> */}
        </div>
      </div>
    </Container>
  );
};

export default ProductInfoCCB;
