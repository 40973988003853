import Margin from "~/components/Margin";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Button, Table, Tile } from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";

import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import openOperationIcon from "~/assets/side-panel--open.png";
import {
  getCreditProductDetailsAPI,
  IProductDetails,
} from "~/services/api/creditProduct";
import useOperation from "~/hooks/useOperation";

import {
  getSimulateOperationAPI,
  // getSimulateOperationAPI,
  ISimulateOperationBody,
} from "~/services/api/operations";
import {
  convertStringToNumber,
  formatNumberToCurrencyWithoutSimbol,
} from "~/services/utils";

import useLoading from "~/hooks/useLoading";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
// import MoreIcon from "~/assets/Addd.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

type Values = {
  nickname: string;
  product_uuid: string;
  calculation_type: string;
  requested_amount: string;
  duration: string;
  calculation_base: string;
  operation_date: string;
  release_date: string;
  interest_rate_monthly: string;
  amortization_grace_period: string;
  commission: string;
  indexer: string;
  indexer_rate: string;
  amortization_amount: string | number;
  due_date_installment: string;
  interest: string | number;
  number: number;
  outstanding_amount: string | number;
  pmt: string | number;
  legal_person: boolean | null;
  payment_every: string;
  payment_periodicity: string;
  first_payment_date: string;

  fiduciary_guarantee: string;
  due_date_on_business: boolean;
};

const initialValues: Values = {
  nickname: "",
  product_uuid: "",
  calculation_type: "",
  requested_amount: "10.000,00",
  duration: "2",
  calculation_base: "",
  operation_date: "",
  release_date: "",
  interest_rate_monthly: "",
  amortization_grace_period: "",
  commission: "0",
  indexer: "",
  indexer_rate: "0",
  amortization_amount: 0,
  due_date_installment: "",
  interest: formatNumberToCurrencyWithoutSimbol(1000),
  number: 0,
  outstanding_amount: "",
  pmt: "",
  legal_person: null,
  payment_every: "1",
  payment_periodicity: "",
  first_payment_date: "",

  fiduciary_guarantee: "0",
  due_date_on_business: false,
};

const DetailsSimulationCCB = () => {
  const navigate = useNavigate();

  const { uuid } = useParams();

  const {
    setPayloadSimulateOperation,
    setSimulateResult,
    simulateResult,
    installmentData,
  } = useOperation();

  const { setLoading } = useLoading();

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const titlesColumnInstallments = [
    "Parcela",
    "Data",
    "Valor nominal",
    "Juros + indexador",
    "Amortização",
    "Preço unitário",
    "",
  ];

  const dataTableInstallments = simulateResult?.installments.map(
    (installment, index) => {
      const isFirstInstallment = index === 0;

      return {
        Parcela: installment.number,
        Data: format(parseISO(installment.due_date), "dd/MM/yyyy"),
        "Valor nominal": `R$ ${formatNumberToCurrencyWithoutSimbol(
          installment.outstanding_amount
        )}`,
        "Juros + indexador": `R$ ${formatNumberToCurrencyWithoutSimbol(installment.interest)}`,
        Amortização: `R$ ${formatNumberToCurrencyWithoutSimbol(
          installment.amortization_amount
        )}`,
        "Preço unitário": `R$ ${formatNumberToCurrencyWithoutSimbol(installment.pmt)}`,
        "": isFirstInstallment ? null : (
          <div
            style={{
              display: "flex",
              position: "relative",
              left: "-60px",
              height: "8px",
            }}
          ></div>
        ),
      };
    }
  );

  const canDoSimulations = true;

  const indexesDict: { [key: string]: string } = {
    igpm: "IGPM",
    selic: "SELIC",
    ipca: "IPCA",
    cdi: "CDI",
    no_one: "Nenhum",
  };

  const payloadSimulateOperation: ISimulateOperationBody = useMemo(() => {
    const taxesSettings: ISimulateOperationBody["taxes_settings"] = {
      interest_rate: values.interest_rate_monthly?.includes(",")
        ? convertStringToNumber(values.interest_rate_monthly)
        : Number(values.interest_rate_monthly),
      amortization_grace_period: Number(values.amortization_grace_period),
      fiduciary_guarantee: Number(values.fiduciary_guarantee),
      commission: values.commission?.includes(",")
        ? convertStringToNumber(values.commission)
        : Number(values.commission),
      ...(values.indexer !== "no_one"
        ? {
            indexer: values.indexer,
            indexer_rate: values.indexer_rate?.includes(",")
              ? convertStringToNumber(values.indexer_rate)
              : Number(values.indexer_rate),
          }
        : {}),
    };

    return {
      nickname: values.nickname,
      product_uuid: values.product_uuid,
      calculation_type: values.calculation_type,
      requested_amount: convertStringToNumber(values.requested_amount),
      duration: Number(values.duration),
      calculation_base: values.calculation_base,
      payment_every: Number(values.payment_every),
      due_date_on_business: values.due_date_on_business,
      first_payment_date: values.first_payment_date,
      payment_periodicity: values.payment_periodicity,
      release_date: values.release_date,
      legal_person: Boolean(values.legal_person),
      taxes_settings: taxesSettings,
    };
  }, [values]);

  const fetchGetSimulation = useCallback(async () => {
    setLoading(true);

    try {
      if (uuid) {
        const { data } = await getSimulateOperationAPI(uuid);

        const simulation = data.payload;

        setSimulateResult(simulation);

        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error("Não foi possível recuperar a simulação !");
      setLoading(false);
    }
  }, [setLoading, setSimulateResult, uuid]);

  const fetchGetSimulationToOperation = useCallback(async () => {
    setLoading(true);
    localStorage.removeItem("@:involveds");
    localStorage.removeItem("@:simulate_operation");
    localStorage.removeItem("@:operation");
    localStorage.removeItem("@:simulation_uuid");
    localStorage.removeItem("@:documents");

    try {
      if (simulateResult?.uuid) {
        const { data } = await getSimulateOperationAPI(simulateResult?.uuid);

        const simulation = data.payload;
        setSimulateResult(null);
        localStorage.setItem(
          "@:simulation_uuid",
          JSON.stringify({ simulation_uuid: simulateResult?.uuid })
        );

        const payload = {
          nickname: simulation.nickname,
          product_uuid: simulation.product.uuid,
          calculation_type: simulation.calculation_type,
          requested_amount: String(simulation.requested_amount),
          duration: simulation.duration,
          calculation_base: simulation.calculation_base,
          first_payment_date: simulation.first_payment_date,
          release_date: simulation.release_date,
          legal_person: simulation.legal_person
            ? "legal_person"
            : "not_legal_person",
          payment_every: simulation.payment_every,
          due_date_on_business: simulation.due_date_on_business,
          payment_periodicity: simulation.payment_periodicity,
          taxes_settings: {
            fiduciary_guarantee: simulation.taxes_settings.fiduciary_guarantee,
            interest_rate_monthly:
              simulation.taxes_settings.interest_rate_monthly,
            interest_periodicity:
              simulation.taxes_settings.interest_periodicity,
            amortization_grace_period:
              simulation.taxes_settings.amortization_grace_period,
            interest_grace_period:
              simulation.taxes_settings.interest_grace_period,
            commission: simulation.taxes_settings.commission,
            indexer: simulation.taxes_settings.indexer,
            indexer_rate: simulation.taxes_settings.indexer_rate,
          },
        };

        localStorage.setItem("@:simulate_operation", JSON.stringify(payload));

        navigate("/app/credit/operations/new/ccb/product-info");
        setSimulateResult(null);

        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error("Não foi possível recuperar a simulação !");
      setLoading(false);
    }
  }, [navigate, setLoading, setSimulateResult, simulateResult?.uuid]);

  const printRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchGetSimulation();
  }, [fetchGetSimulation]);

  useEffect(() => {
    if (installmentData) {
      setFieldValue("outstanding_amount", installmentData.outstanding_amount);
      setFieldValue("due_date_installment", installmentData.due_date);
      setFieldValue("pmt", String(installmentData.pmt));
      setFieldValue(
        "interest",
        formatNumberToCurrencyWithoutSimbol(installmentData.interest)
      );
      setFieldValue(
        "amortization_amount",
        formatNumberToCurrencyWithoutSimbol(installmentData.amortization_amount)
      );
    }
  }, [installmentData, setFieldValue]);

  useEffect(() => {
    setPayloadSimulateOperation(payloadSimulateOperation);
    localStorage.setItem(
      "@:simulate_operation",
      JSON.stringify(payloadSimulateOperation)
    );
  }, [payloadSimulateOperation, setPayloadSimulateOperation]);

  const [productDetails, setProductDetails] = useState<IProductDetails>();

  const fetchProductDetails = useCallback(async (uuid: string) => {
    try {
      const { data } = await getCreditProductDetailsAPI(uuid);
      setProductDetails(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (values.product_uuid) {
      fetchProductDetails(values.product_uuid);
    }
  }, [values.product_uuid, fetchProductDetails]);

  useEffect(() => {
    if (values.indexer === "no_one") {
      setFieldValue("indexer_rate", "0");
    } else if (values.indexer !== "no_one" && values.indexer_rate === "0") {
      setFieldValue("indexer_rate", "100");
    }
  }, [values, setFieldValue]);

  useEffect(() => {
    if (productDetails) {
      setFieldValue(
        "interest_rate_monthly",
        productDetails?.min_interest_rate !== undefined &&
          String(productDetails?.min_interest_rate)
      );

      setFieldValue(
        "amortization_grace_period",
        productDetails?.min_amortization_period !== undefined &&
          String(productDetails?.min_amortization_period)
      );

      setFieldValue(
        "interest_grace_period",
        productDetails?.min_amortization_period !== undefined &&
          String(productDetails?.min_amortization_period)
      );
    }
    setFieldValue("duration", "1");
    setFieldValue("commission", "0");
  }, [productDetails, setFieldValue]);

  useEffect(() => {
    setSimulateResult(null);
  }, [setSimulateResult]);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          {
            crumb: "Simulações",
            onClick: () => navigate("/app/credit/simulations"),
          },
          { crumb: "Detalhes" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />
      <Margin mb={30} />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Text style={{ fontSize: "25px" }}>
          Simulação CCB {simulateResult?.uuid && `- ID: ${simulateResult.uuid}`}
        </Text>
        <Button
          kind={"primary"}
          label="Abrir operação"
          size="large"
          icon={openOperationIcon}
          onClick={() => fetchGetSimulationToOperation()}
        />
      </div>
      <Margin mb={20} />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        ></div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          {canDoSimulations && (
            <>
              <Margin mt={50} />

              {simulateResult?.calculation_type && (
                <div ref={printRef}>
                  <h4>Resultados da simulação</h4>
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "32px",
                      rowGap: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Data de emissão
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.operation_date
                            ? format(
                                parseISO(simulateResult?.operation_date),
                                "dd/MM/yyyy"
                              )
                            : "-"}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Data do primeiro pagamento
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.first_payment_date
                            ? format(
                                parseISO(simulateResult?.first_payment_date),
                                "dd/MM/yyyy"
                              )
                            : "-"}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Prazo
                        </h4>
                        <Margin mt={17} />
                        <p>{simulateResult?.duration} meses</p>
                      </div>
                    </Tile>

                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          IOF
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {" "}
                          R${" "}
                          {formatNumberToCurrencyWithoutSimbol(
                            simulateResult?.taxes_values.iof_value ?? 0
                          )}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Valor do contrato
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {" "}
                          R${" "}
                          {formatNumberToCurrencyWithoutSimbol(
                            simulateResult?.requested_amount ?? 0
                          )}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Valor líquido
                        </h4>
                        <Margin mt={17} />
                        <p>
                          R${" "}
                          {formatNumberToCurrencyWithoutSimbol(
                            simulateResult?.net_amount ?? 0
                          )}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Custo da emissão
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {" "}
                          R${" "}
                          {formatNumberToCurrencyWithoutSimbol(
                            simulateResult?.taxes_values?.emission_cost ?? 0
                          )}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Indexador
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.taxes_settings?.indexer
                            ? indexesDict[
                                simulateResult?.taxes_settings?.indexer
                              ]
                            : "-"}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Taxa A.M.
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.taxes_settings.interest_rate_monthly?.toFixed(
                            4
                          )}{" "}
                          %
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Taxa A.A.
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.taxes_settings.interest_rate_annually?.toFixed(
                            4
                          )}{" "}
                          %
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          CET A.M.
                        </h4>
                        <Margin mt={17} />
                        {simulateResult?.taxes_settings.cet_monthly?.toFixed(
                          4
                        )}{" "}
                        %
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          CET A.A.
                        </h4>
                        <Margin mt={17} />
                        {simulateResult?.taxes_settings.cet_annually?.toFixed(
                          4
                        )}{" "}
                        %
                      </div>
                    </Tile>
                  </div>
                </div>
              )}
            </>
          )}

          <Margin mt={50} />
          {simulateResult?.calculation_type && (
            <div ref={printRef}>
              {" "}
              <Table
                pageSizes={[10, 5, 20, 50, 100]}
                columnTitles={titlesColumnInstallments}
                rowData={dataTableInstallments ?? []}
                width="1000px"
                radius
                // buttons={[
                //   {
                //     kind: "primary",
                //     position: "right",
                //     label: "Adicionar parcelas",
                //     icon: MoreIcon,
                //   },
                // ]}
              />
            </div>
          )}
        </>

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para visualizar as simulações <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
      {/* 
      <LateralModal
        toggleModal={() => null}
        open
        title="Novo"
        header
      ></LateralModal> */}
    </Container>
  );
};

export default DetailsSimulationCCB;
