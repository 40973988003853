import ApiService from "../api";

export type IUser = {
  uuid: string;
  username: string;
  first_name: string;
  last_name: string;
  email?: string;
};

export const getUserAPI = () => {
  return ApiService.HttpGet<IResponse<IUser[]>>({
    route: "register/user/",
    token: true,
  });
};
