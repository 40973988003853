import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Breadcrumb,
  Button,
  Tag,
  TableBasic,
  Tooltip,
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
// import filterIcon from "~/assets/Filter.png";
import moneyIcon from "~/assets/Money.png";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import { format } from "date-fns";
import { PropsTag } from "prosperita-dumbo-react/dist/types/Components/Tag";
import useLoading from "~/hooks/useLoading";
import { getTemplatesAPI, ITemplate } from "~/services/api/template";
import useTemplate from "~/hooks/useTemplate";
import MoreIcon from "~/assets/Addd.png";
import EditIcon from "~/assets/Edit.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const ContainerCanViewOperations = styled.div`
  width: 100%;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const statusTemplates: { [key: string]: string } = {
  active: "Ativo",
  inactive: "Inativo",
};

const statusTemplatesTagColor: { [key: string]: PropsTag["type"] } = {
  inactive: "red",
  active: "green",
};

const Templates: FunctionComponent = () => {
  const navigate = useNavigate();
  const { templates, setTemplates } = useTemplate();

  const { setLoading } = useLoading();

  const [render, setRender] = useState<boolean>(false);

  const [currentDate, setCurrentDate] = useState(() =>
    new Date().toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  );

  const [currentTime, setCurrentTime] = useState(() =>
    new Date().toLocaleString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  );

  const canViewOperations: boolean = true;

  const dataTable = useMemo(() => {
    return templates?.map((template: ITemplate) => {
      return {
        "Nome do template": template.name,
        "Produto vinculado": template?.product_name,
        "Ultima atualização": format(
          new Date(template.updated_date),
          "dd/MM/yyyy HH:mm:ss"
        ),
        Status: (
          <div style={{ maxWidth: "150px" }}>
            <Tag
              type={statusTemplatesTagColor[template.status]}
              size="medium"
              position="center"
            >
              <div style={{ padding: "2px" }}>
                {statusTemplates[template.status]}
              </div>
            </Tag>
          </div>
        ),
        "": (
          <div
            style={{
              display: "flex",

              justifyContent: "start",

              height: "10px",
            }}
          >
            <Tooltip align="top" size="small" label="Editar" width="60px">
              {" "}
              <Button
                kind="ghost"
                size="small"
                icon={EditIcon}
                onClick={() =>
                  navigate(`/app/credit/templates/${template.uuid}`)
                }
              />{" "}
            </Tooltip>{" "}
          </div>
        ),
      };
    });
  }, [templates, navigate]);

  const fetchTemplates = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await getTemplatesAPI();
      setTemplates(data.payload.reverse());
      setLoading(false);

      const currentDateTime = new Date();

      setCurrentDate(
        currentDateTime.toLocaleString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      );

      setCurrentTime(
        currentDateTime.toLocaleString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      );
    } catch (e) {
      console.error("Erro ao buscar templates:", e);
      setLoading(false);
    } finally {
      setRender(true);
    }
  }, [setTemplates, setLoading]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  return (
    render && (
      <Container>
        <Breadcrumb
          crumbsOptions={[{ crumb: "" }, { crumb: "Templates" }]}
          endHiddenIndex={5}
          startHiddenIndex={5}
          aria-label="Navegação de operações"
        />

        <ContainerCanViewOperations>
          <>
            {canViewOperations && templates?.length > 0 ? (
              <>
                <Margin mt={30} />
                <div>
                  <TableBasic
                    label="Templates"
                    search
                    sizeSearch="large"
                    rowData={dataTable}
                    width="1000px"
                    columnTitles={[
                      "Nome do template",
                      "Produto vinculado",
                      "Ultima atualização",
                      "Status",
                      "",
                    ]}
                    pageSizes={[10, 5, 20, 50, 100]}
                    buttons={[
                      // {
                      //   kind: "ghost",
                      //   label: "",
                      //   position: "right",
                      //   icon: filterIcon,
                      //   width: "40px",
                      //   dimension: "36",
                      // },
                      {
                        kind: "ghost",
                        label: "",
                        position: "right",
                        icon: renewIcon,
                        size: "large",
                        width: "57px",
                        dimension: "55.5",
                        onClick: () => fetchTemplates(),
                      },
                      {
                        kind: "primary",
                        label: "Novo template",
                        position: "right",
                        size: "large",
                        icon: MoreIcon,
                        onClick: () => {
                          navigate("/app/credit/templates/new");
                        },
                      },
                    ]}
                  />
                </div>
                <div id="lastUpdate">
                  <Margin ml={16} mt={25}>
                    <h5>
                      Atualizado em{" "}
                      <span style={{ fontWeight: "bold" }}>{currentDate}</span>,
                      às{" "}
                      <span style={{ fontWeight: "bold" }}>{currentTime}h</span>
                    </h5>
                  </Margin>
                </div>
              </>
            ) : (
              canViewOperations && (
                <div>
                  <Margin mt={70} />
                  <div>
                    <TagLup
                      src={lupaIcon}
                      aria-label="Ícone indicando que não há operações"
                    />
                    <Text>Sem Templates</Text>
                    <Subtext>
                      Você não tem templates para serem exibidas no momento
                    </Subtext>
                  </div>
                  <Margin mt={50} />
                  <div style={{ width: "320px" }}>
                    <Button
                      kind="primary"
                      size="medium"
                      label={"Novo template"}
                      aria-label="Novo template"
                      onClick={() => navigate("/app/credit/templates/new")}
                      icon={moneyIcon}
                    />
                  </div>
                </div>
              )
            )}
          </>

          {!canViewOperations && (
            <div>
              <Margin mt={70} />
              <TagLup
                src={lockIcon}
                aria-label="Ícone indicando não há permissão para visualizar as operações"
              />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para ver as operações de crédito, <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          )}
        </ContainerCanViewOperations>
      </Container>
    )
  );
};

export default Templates;
